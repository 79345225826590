import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: any
  Id: any
  JSON: any
  Long: any
  Upload: any
}

export type Article = {
  __typename?: 'Article'
  article_comments?: Maybe<ArticleCommentRelationResponseCollection>
  article_type?: Maybe<Enum_Article_Article_Type>
  article_views?: Maybe<ArticleViewRelationResponseCollection>
  broker?: Maybe<BrokerEntityResponse>
  calculator?: Maybe<CalculatorEntityResponse>
  categories?: Maybe<CategoryRelationResponseCollection>
  country: CountryEntityResponse
  createdAt?: Maybe<Scalars['DateTime']>
  creator?: Maybe<Enum_Article_Creator>
  description?: Maybe<Scalars['String']>
  financial_term?: Maybe<FinancialTermEntityResponse>
  image?: Maybe<ComponentUploadImageUploadImage>
  page_url?: Maybe<Enum_Article_Page_Url>
  plugin_comments: Array<Maybe<CommentNested>>
  relatedArticles: Array<Maybe<Article>>
  searches?: Maybe<Scalars['Long']>
  seo_meta?: Maybe<ComponentSeoSeo>
  short_description?: Maybe<Scalars['String']>
  short_query?: Maybe<Scalars['String']>
  slug: Scalars['String']
  software?: Maybe<SoftwareEntityResponse>
  status?: Maybe<Enum_Article_Status>
  tags?: Maybe<Scalars['JSON']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
}

export type ArticleArticle_CommentsArgs = {
  filters?: InputMaybe<ArticleCommentFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ArticleArticle_ViewsArgs = {
  filters?: InputMaybe<ArticleViewFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  publicationState?: InputMaybe<PublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ArticleCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ArticlePlugin_CommentsArgs = {
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ArticleRelatedArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type ArticleComment = {
  __typename?: 'ArticleComment'
  article?: Maybe<ArticleEntityResponse>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  status?: Maybe<Enum_Articlecomment_Status>
  updatedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UsersPermissionsUserEntityResponse>
}

export type ArticleCommentEntity = {
  __typename?: 'ArticleCommentEntity'
  attributes?: Maybe<ArticleComment>
  id?: Maybe<Scalars['Id']>
}

export type ArticleCommentEntityResponse = {
  __typename?: 'ArticleCommentEntityResponse'
  data?: Maybe<ArticleCommentEntity>
}

export type ArticleCommentEntityResponseCollection = {
  __typename?: 'ArticleCommentEntityResponseCollection'
  data: Array<ArticleCommentEntity>
  meta: ResponseCollectionMeta
}

export type ArticleCommentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ArticleCommentFiltersInput>>>
  article?: InputMaybe<ArticleFiltersInput>
  content?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  not?: InputMaybe<ArticleCommentFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ArticleCommentFiltersInput>>>
  status?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  user?: InputMaybe<UsersPermissionsUserFiltersInput>
}

export type ArticleCommentInput = {
  article?: InputMaybe<Scalars['Id']>
  content?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Enum_Articlecomment_Status>
  user?: InputMaybe<Scalars['Id']>
}

export type ArticleCommentRelationResponseCollection = {
  __typename?: 'ArticleCommentRelationResponseCollection'
  data: Array<ArticleCommentEntity>
}

export type ArticleEntity = {
  __typename?: 'ArticleEntity'
  attributes?: Maybe<Article>
  id?: Maybe<Scalars['Id']>
}

export type ArticleEntityResponse = {
  __typename?: 'ArticleEntityResponse'
  data?: Maybe<ArticleEntity>
}

export type ArticleEntityResponseCollection = {
  __typename?: 'ArticleEntityResponseCollection'
  data: Array<ArticleEntity>
  meta: ResponseCollectionMeta
}

export type ArticleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>
  article_comments?: InputMaybe<ArticleCommentFiltersInput>
  article_type?: InputMaybe<StringFilterInput>
  article_views?: InputMaybe<ArticleViewFiltersInput>
  broker?: InputMaybe<BrokerFiltersInput>
  calculator?: InputMaybe<CalculatorFiltersInput>
  categories?: InputMaybe<CategoryFiltersInput>
  country?: InputMaybe<CountryFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  creator?: InputMaybe<StringFilterInput>
  description?: InputMaybe<StringFilterInput>
  financial_term?: InputMaybe<FinancialTermFiltersInput>
  id?: InputMaybe<IdFilterInput>
  image?: InputMaybe<ComponentUploadImageUploadImageFiltersInput>
  not?: InputMaybe<ArticleFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>
  page_url?: InputMaybe<StringFilterInput>
  searches?: InputMaybe<LongFilterInput>
  seo_meta?: InputMaybe<ComponentSeoSeoFiltersInput>
  short_description?: InputMaybe<StringFilterInput>
  short_query?: InputMaybe<StringFilterInput>
  slug?: InputMaybe<StringFilterInput>
  software?: InputMaybe<SoftwareFiltersInput>
  status?: InputMaybe<StringFilterInput>
  tags?: InputMaybe<JsonFilterInput>
  title?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  user?: InputMaybe<UsersPermissionsUserFiltersInput>
}

export type ArticleInput = {
  article_comments?: InputMaybe<Array<InputMaybe<Scalars['Id']>>>
  article_type?: InputMaybe<Enum_Article_Article_Type>
  article_views?: InputMaybe<Array<InputMaybe<Scalars['Id']>>>
  broker?: InputMaybe<Scalars['Id']>
  calculator?: InputMaybe<Scalars['Id']>
  categories?: InputMaybe<Array<InputMaybe<Scalars['Id']>>>
  country?: InputMaybe<Scalars['Id']>
  creator?: InputMaybe<Enum_Article_Creator>
  description?: InputMaybe<Scalars['String']>
  financial_term?: InputMaybe<Scalars['Id']>
  image?: InputMaybe<ComponentUploadImageUploadImageInput>
  page_url?: InputMaybe<Enum_Article_Page_Url>
  searches?: InputMaybe<Scalars['Long']>
  seo_meta?: InputMaybe<ComponentSeoSeoInput>
  short_description?: InputMaybe<Scalars['String']>
  short_query?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  software?: InputMaybe<Scalars['Id']>
  status?: InputMaybe<Enum_Article_Status>
  tags?: InputMaybe<Scalars['JSON']>
  title?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['Id']>
}

export type ArticleRelationResponseCollection = {
  __typename?: 'ArticleRelationResponseCollection'
  data: Array<ArticleEntity>
}

export type ArticleView = {
  __typename?: 'ArticleView'
  article?: Maybe<ArticleEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  date?: Maybe<Scalars['Date']>
  publishedAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  views?: Maybe<Scalars['Long']>
}

export type ArticleViewEntity = {
  __typename?: 'ArticleViewEntity'
  attributes?: Maybe<ArticleView>
  id?: Maybe<Scalars['Id']>
}

export type ArticleViewEntityResponse = {
  __typename?: 'ArticleViewEntityResponse'
  data?: Maybe<ArticleViewEntity>
}

export type ArticleViewEntityResponseCollection = {
  __typename?: 'ArticleViewEntityResponseCollection'
  data: Array<ArticleViewEntity>
  meta: ResponseCollectionMeta
}

export type ArticleViewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ArticleViewFiltersInput>>>
  article?: InputMaybe<ArticleFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  date?: InputMaybe<DateFilterInput>
  id?: InputMaybe<IdFilterInput>
  not?: InputMaybe<ArticleViewFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ArticleViewFiltersInput>>>
  publishedAt?: InputMaybe<DateTimeFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  views?: InputMaybe<LongFilterInput>
}

export type ArticleViewRelationResponseCollection = {
  __typename?: 'ArticleViewRelationResponseCollection'
  data: Array<ArticleViewEntity>
}

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
  contains?: InputMaybe<Scalars['Boolean']>
  containsi?: InputMaybe<Scalars['Boolean']>
  endsWith?: InputMaybe<Scalars['Boolean']>
  eq?: InputMaybe<Scalars['Boolean']>
  eqi?: InputMaybe<Scalars['Boolean']>
  gt?: InputMaybe<Scalars['Boolean']>
  gte?: InputMaybe<Scalars['Boolean']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
  lt?: InputMaybe<Scalars['Boolean']>
  lte?: InputMaybe<Scalars['Boolean']>
  ne?: InputMaybe<Scalars['Boolean']>
  not?: InputMaybe<BooleanFilterInput>
  notContains?: InputMaybe<Scalars['Boolean']>
  notContainsi?: InputMaybe<Scalars['Boolean']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
  startsWith?: InputMaybe<Scalars['Boolean']>
}

export type Broker = {
  __typename?: 'Broker'
  account_opening_charges?: Maybe<ComponentChargesAccountOpeningCharges>
  account_opening_process?: Maybe<Scalars['String']>
  articles?: Maybe<ArticleRelationResponseCollection>
  brokerArticlesCount: Scalars['Int']
  broker_type?: Maybe<Enum_Broker_Broker_Type>
  commodity_futures_brokerage?: Maybe<ComponentChargesBrokerage>
  commodity_options_brokerage?: Maybe<ComponentChargesBrokerage>
  commodity_trading?: Maybe<Scalars['String']>
  consolidated_charges?: Maybe<
    Array<Maybe<ComponentChargesConsolidatedCharges>>
  >
  contact_address?: Maybe<Scalars['String']>
  country: ComponentCountryListCountries
  createdAt?: Maybe<Scalars['DateTime']>
  currency_futures_brokerage?: Maybe<ComponentChargesBrokerage>
  currency_options_brokerage?: Maybe<ComponentChargesBrokerage>
  currency_trading?: Maybe<Scalars['String']>
  demat?: Maybe<Scalars['String']>
  details: Scalars['String']
  equity_delivery_brokerage?: Maybe<ComponentChargesBrokerage>
  equity_intraday_brokerage?: Maybe<ComponentChargesBrokerage>
  exchanges?: Maybe<ComponentExchangelistExchanges>
  exposure?: Maybe<ComponentMarginsExposure>
  exposure_details?: Maybe<Scalars['String']>
  futures_brokerage?: Maybe<ComponentChargesBrokerage>
  image?: Maybe<ComponentUploadImageUploadImage>
  ipo?: Maybe<Scalars['String']>
  listings?: Maybe<ListingRelationResponseCollection>
  logo?: Maybe<UploadFileEntityResponse>
  name?: Maybe<Scalars['String']>
  options_brokerage?: Maybe<ComponentChargesBrokerage>
  order_types?: Maybe<ComponentOrderTypesOrderTypes>
  pros_cons?: Maybe<Array<Maybe<ComponentProsConsContent>>>
  slug?: Maybe<Scalars['String']>
  softwares?: Maybe<SoftwareRelationResponseCollection>
  start_year?: Maybe<Scalars['String']>
  trading_account?: Maybe<Scalars['String']>
  trading_options?: Maybe<ComponentTradingoptionsTradingOptions>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type BrokerArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BrokerBrokerArticlesCountArgs = {
  where?: InputMaybe<Scalars['JSON']>
}

export type BrokerConsolidated_ChargesArgs = {
  filters?: InputMaybe<ComponentChargesConsolidatedChargesFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BrokerListingsArgs = {
  filters?: InputMaybe<ListingFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BrokerPros_ConsArgs = {
  filters?: InputMaybe<ComponentProsConsContentFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BrokerSoftwaresArgs = {
  filters?: InputMaybe<SoftwareFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BrokerEntity = {
  __typename?: 'BrokerEntity'
  attributes?: Maybe<Broker>
  id?: Maybe<Scalars['Id']>
}

export type BrokerEntityResponse = {
  __typename?: 'BrokerEntityResponse'
  data?: Maybe<BrokerEntity>
}

export type BrokerEntityResponseCollection = {
  __typename?: 'BrokerEntityResponseCollection'
  data: Array<BrokerEntity>
  meta: ResponseCollectionMeta
}

export type BrokerFiltersInput = {
  account_opening_charges?: InputMaybe<
    ComponentChargesAccountOpeningChargesFiltersInput
  >
  account_opening_process?: InputMaybe<StringFilterInput>
  and?: InputMaybe<Array<InputMaybe<BrokerFiltersInput>>>
  articles?: InputMaybe<ArticleFiltersInput>
  broker_type?: InputMaybe<StringFilterInput>
  commodity_futures_brokerage?: InputMaybe<
    ComponentChargesBrokerageFiltersInput
  >
  commodity_options_brokerage?: InputMaybe<
    ComponentChargesBrokerageFiltersInput
  >
  commodity_trading?: InputMaybe<StringFilterInput>
  consolidated_charges?: InputMaybe<
    ComponentChargesConsolidatedChargesFiltersInput
  >
  contact_address?: InputMaybe<StringFilterInput>
  country?: InputMaybe<ComponentCountryListCountriesFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  currency_futures_brokerage?: InputMaybe<ComponentChargesBrokerageFiltersInput>
  currency_options_brokerage?: InputMaybe<ComponentChargesBrokerageFiltersInput>
  currency_trading?: InputMaybe<StringFilterInput>
  demat?: InputMaybe<StringFilterInput>
  details?: InputMaybe<StringFilterInput>
  equity_delivery_brokerage?: InputMaybe<ComponentChargesBrokerageFiltersInput>
  equity_intraday_brokerage?: InputMaybe<ComponentChargesBrokerageFiltersInput>
  exchanges?: InputMaybe<ComponentExchangelistExchangesFiltersInput>
  exposure?: InputMaybe<ComponentMarginsExposureFiltersInput>
  exposure_details?: InputMaybe<StringFilterInput>
  futures_brokerage?: InputMaybe<ComponentChargesBrokerageFiltersInput>
  id?: InputMaybe<IdFilterInput>
  image?: InputMaybe<ComponentUploadImageUploadImageFiltersInput>
  ipo?: InputMaybe<StringFilterInput>
  listings?: InputMaybe<ListingFiltersInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<BrokerFiltersInput>
  options_brokerage?: InputMaybe<ComponentChargesBrokerageFiltersInput>
  or?: InputMaybe<Array<InputMaybe<BrokerFiltersInput>>>
  order_types?: InputMaybe<ComponentOrderTypesOrderTypesFiltersInput>
  pros_cons?: InputMaybe<ComponentProsConsContentFiltersInput>
  slug?: InputMaybe<StringFilterInput>
  softwares?: InputMaybe<SoftwareFiltersInput>
  start_year?: InputMaybe<StringFilterInput>
  trading_account?: InputMaybe<StringFilterInput>
  trading_options?: InputMaybe<
    ComponentTradingoptionsTradingOptionsFiltersInput
  >
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type Calculator = {
  __typename?: 'Calculator'
  articles?: Maybe<ArticleRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  description: Scalars['String']
  image?: Maybe<ComponentUploadImageUploadImage>
  name: Scalars['String']
  short_description: Scalars['String']
  slug: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CalculatorArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CalculatorEntity = {
  __typename?: 'CalculatorEntity'
  attributes?: Maybe<Calculator>
  id?: Maybe<Scalars['Id']>
}

export type CalculatorEntityResponse = {
  __typename?: 'CalculatorEntityResponse'
  data?: Maybe<CalculatorEntity>
}

export type CalculatorEntityResponseCollection = {
  __typename?: 'CalculatorEntityResponseCollection'
  data: Array<CalculatorEntity>
  meta: ResponseCollectionMeta
}

export type CalculatorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CalculatorFiltersInput>>>
  articles?: InputMaybe<ArticleFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  description?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  image?: InputMaybe<ComponentUploadImageUploadImageFiltersInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<CalculatorFiltersInput>
  or?: InputMaybe<Array<InputMaybe<CalculatorFiltersInput>>>
  short_description?: InputMaybe<StringFilterInput>
  slug?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type Category = {
  __typename?: 'Category'
  articles?: Maybe<ArticleRelationResponseCollection>
  categoriesArticlesCount: Scalars['Int']
  children?: Maybe<CategoryRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  financial_term?: Maybe<FinancialTermEntityResponse>
  name?: Maybe<Scalars['String']>
  parent?: Maybe<CategoryEntityResponse>
  relatedCategories: Array<Maybe<Category>>
  short_description?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  softwares?: Maybe<SoftwareRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CategoryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CategoryCategoriesArticlesCountArgs = {
  where?: InputMaybe<Scalars['JSON']>
}

export type CategoryChildrenArgs = {
  filters?: InputMaybe<CategoryFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CategorySoftwaresArgs = {
  filters?: InputMaybe<SoftwareFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CategoryEntity = {
  __typename?: 'CategoryEntity'
  attributes?: Maybe<Category>
  id?: Maybe<Scalars['Id']>
}

export type CategoryEntityResponse = {
  __typename?: 'CategoryEntityResponse'
  data?: Maybe<CategoryEntity>
}

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection'
  data: Array<CategoryEntity>
  meta: ResponseCollectionMeta
}

export type CategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>
  articles?: InputMaybe<ArticleFiltersInput>
  children?: InputMaybe<CategoryFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  description?: InputMaybe<StringFilterInput>
  financial_term?: InputMaybe<FinancialTermFiltersInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<CategoryFiltersInput>
  or?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>
  parent?: InputMaybe<CategoryFiltersInput>
  short_description?: InputMaybe<StringFilterInput>
  slug?: InputMaybe<StringFilterInput>
  softwares?: InputMaybe<SoftwareFiltersInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CategoryRelationResponseCollection = {
  __typename?: 'CategoryRelationResponseCollection'
  data: Array<CategoryEntity>
}

export type City = {
  __typename?: 'City'
  createdAt?: Maybe<Scalars['DateTime']>
  listings?: Maybe<ListingRelationResponseCollection>
  name: Scalars['String']
  state: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CityListingsArgs = {
  filters?: InputMaybe<ListingFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CityEntity = {
  __typename?: 'CityEntity'
  attributes?: Maybe<City>
  id?: Maybe<Scalars['Id']>
}

export type CityEntityResponse = {
  __typename?: 'CityEntityResponse'
  data?: Maybe<CityEntity>
}

export type CityEntityResponseCollection = {
  __typename?: 'CityEntityResponseCollection'
  data: Array<CityEntity>
  meta: ResponseCollectionMeta
}

export type CityFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CityFiltersInput>>>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  listings?: InputMaybe<ListingFiltersInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<CityFiltersInput>
  or?: InputMaybe<Array<InputMaybe<CityFiltersInput>>>
  state?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CommentAuthor = {
  __typename?: 'CommentAuthor'
  avatar?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Id']>
  name: Scalars['String']
}

export type CommentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>
  approvalStatus?: InputMaybe<StringFilterInput>
  authorAvatar?: InputMaybe<StringFilterInput>
  authorEmail?: InputMaybe<StringFilterInput>
  authorId?: InputMaybe<StringFilterInput>
  authorName?: InputMaybe<StringFilterInput>
  authorUser?: InputMaybe<UsersPermissionsUserFiltersInput>
  blockReason?: InputMaybe<StringFilterInput>
  blocked?: InputMaybe<BooleanFilterInput>
  blockedThread?: InputMaybe<BooleanFilterInput>
  content?: InputMaybe<StringFilterInput>
  filterBy?: InputMaybe<Scalars['String']>
  filterByValue?: InputMaybe<Scalars['String']>
  isAdminComment?: InputMaybe<BooleanFilterInput>
  not?: InputMaybe<CommentFiltersInput>
  or?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>
  related?: InputMaybe<StringFilterInput>
  removed?: InputMaybe<BooleanFilterInput>
  reports?: InputMaybe<CommentsCommentReportFiltersInput>
  threadOf?: InputMaybe<CommentsCommentFiltersInput>
}

export type CommentNested = {
  __typename?: 'CommentNested'
  approvalStatus?: Maybe<Scalars['String']>
  author?: Maybe<CommentAuthor>
  blocked?: Maybe<Scalars['Boolean']>
  blockedThread?: Maybe<Scalars['Boolean']>
  children?: Maybe<Array<Maybe<CommentNested>>>
  content: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Id']>
  removed?: Maybe<Scalars['Boolean']>
  threadOf?: Maybe<CommentSingle>
  updatedAt?: Maybe<Scalars['String']>
}

export type CommentSingle = {
  __typename?: 'CommentSingle'
  approvalStatus?: Maybe<Scalars['String']>
  articleSlug?: Maybe<Scalars['String']>
  author?: Maybe<CommentAuthor>
  blocked?: Maybe<Scalars['Boolean']>
  blockedThread?: Maybe<Scalars['Boolean']>
  content: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Id']>
  removed?: Maybe<Scalars['Boolean']>
  threadOf?: Maybe<CommentSingle>
  updatedAt?: Maybe<Scalars['String']>
}

export type CommentsComment = {
  __typename?: 'CommentsComment'
  approvalStatus?: Maybe<Scalars['String']>
  authorAvatar?: Maybe<Scalars['String']>
  authorEmail?: Maybe<Scalars['String']>
  authorId?: Maybe<Scalars['String']>
  authorName?: Maybe<Scalars['String']>
  authorUser?: Maybe<UsersPermissionsUserEntityResponse>
  blockReason?: Maybe<Scalars['String']>
  blocked?: Maybe<Scalars['Boolean']>
  blockedThread?: Maybe<Scalars['Boolean']>
  content: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  isAdminComment?: Maybe<Scalars['Boolean']>
  related?: Maybe<Scalars['String']>
  removed?: Maybe<Scalars['Boolean']>
  reports?: Maybe<CommentsCommentReportRelationResponseCollection>
  threadOf?: Maybe<CommentsCommentEntityResponse>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CommentsCommentReportsArgs = {
  filters?: InputMaybe<CommentsCommentReportFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CommentsCommentEntity = {
  __typename?: 'CommentsCommentEntity'
  attributes?: Maybe<CommentsComment>
  id?: Maybe<Scalars['Id']>
}

export type CommentsCommentEntityResponse = {
  __typename?: 'CommentsCommentEntityResponse'
  data?: Maybe<CommentsCommentEntity>
}

export type CommentsCommentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CommentsCommentFiltersInput>>>
  approvalStatus?: InputMaybe<StringFilterInput>
  authorAvatar?: InputMaybe<StringFilterInput>
  authorEmail?: InputMaybe<StringFilterInput>
  authorId?: InputMaybe<StringFilterInput>
  authorName?: InputMaybe<StringFilterInput>
  authorUser?: InputMaybe<UsersPermissionsUserFiltersInput>
  blockReason?: InputMaybe<StringFilterInput>
  blocked?: InputMaybe<BooleanFilterInput>
  blockedThread?: InputMaybe<BooleanFilterInput>
  content?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  isAdminComment?: InputMaybe<BooleanFilterInput>
  not?: InputMaybe<CommentsCommentFiltersInput>
  or?: InputMaybe<Array<InputMaybe<CommentsCommentFiltersInput>>>
  related?: InputMaybe<StringFilterInput>
  removed?: InputMaybe<BooleanFilterInput>
  reports?: InputMaybe<CommentsCommentReportFiltersInput>
  threadOf?: InputMaybe<CommentsCommentFiltersInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CommentsCommentReport = {
  __typename?: 'CommentsCommentReport'
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  reason: Enum_Commentscommentreport_Reason
  related?: Maybe<CommentsCommentEntityResponse>
  resolved?: Maybe<Scalars['Boolean']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CommentsCommentReportEntity = {
  __typename?: 'CommentsCommentReportEntity'
  attributes?: Maybe<CommentsCommentReport>
  id?: Maybe<Scalars['Id']>
}

export type CommentsCommentReportFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CommentsCommentReportFiltersInput>>>
  content?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  not?: InputMaybe<CommentsCommentReportFiltersInput>
  or?: InputMaybe<Array<InputMaybe<CommentsCommentReportFiltersInput>>>
  reason?: InputMaybe<StringFilterInput>
  related?: InputMaybe<CommentsCommentFiltersInput>
  resolved?: InputMaybe<BooleanFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CommentsCommentReportRelationResponseCollection = {
  __typename?: 'CommentsCommentReportRelationResponseCollection'
  data: Array<CommentsCommentReportEntity>
}

export type ComponentAssetdataAssets = {
  __typename?: 'ComponentAssetdataAssets'
  assets?: Maybe<Scalars['String']>
  id: Scalars['Id']
  on_date?: Maybe<Scalars['Date']>
  pat?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
}

export type ComponentAssetdataAssetsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentAssetdataAssetsFiltersInput>>>
  assets?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentAssetdataAssetsFiltersInput>
  on_date?: InputMaybe<DateFilterInput>
  or?: InputMaybe<Array<InputMaybe<ComponentAssetdataAssetsFiltersInput>>>
  pat?: InputMaybe<StringFilterInput>
  revenue?: InputMaybe<StringFilterInput>
}

export type ComponentChargesAccountOpeningCharges = {
  __typename?: 'ComponentChargesAccountOpeningCharges'
  demat_amc?: Maybe<Scalars['String']>
  demat_opening_charges?: Maybe<Scalars['String']>
  id: Scalars['Id']
  opening_charges?: Maybe<Scalars['String']>
  trading_acc_opening_charges?: Maybe<Scalars['String']>
  trading_amc?: Maybe<Scalars['String']>
}

export type ComponentChargesAccountOpeningChargesFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentChargesAccountOpeningChargesFiltersInput>>
  >
  demat_amc?: InputMaybe<StringFilterInput>
  demat_opening_charges?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentChargesAccountOpeningChargesFiltersInput>
  opening_charges?: InputMaybe<StringFilterInput>
  or?: InputMaybe<
    Array<InputMaybe<ComponentChargesAccountOpeningChargesFiltersInput>>
  >
  trading_acc_opening_charges?: InputMaybe<StringFilterInput>
  trading_amc?: InputMaybe<StringFilterInput>
}

export type ComponentChargesBrokerage = {
  __typename?: 'ComponentChargesBrokerage'
  brokerage?: Maybe<Scalars['String']>
  gst?: Maybe<Scalars['String']>
  id: Scalars['Id']
  sebi?: Maybe<Scalars['String']>
  stamp?: Maybe<Scalars['String']>
  stt?: Maybe<Scalars['String']>
  turnover?: Maybe<Scalars['String']>
}

export type ComponentChargesBrokerageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentChargesBrokerageFiltersInput>>>
  brokerage?: InputMaybe<StringFilterInput>
  gst?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentChargesBrokerageFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ComponentChargesBrokerageFiltersInput>>>
  sebi?: InputMaybe<StringFilterInput>
  stamp?: InputMaybe<StringFilterInput>
  stt?: InputMaybe<StringFilterInput>
  turnover?: InputMaybe<StringFilterInput>
}

export type ComponentChargesConsolidatedCharges = {
  __typename?: 'ComponentChargesConsolidatedCharges'
  commodity?: Maybe<Scalars['String']>
  currency_futures?: Maybe<Scalars['String']>
  currency_options?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  equity_delivery?: Maybe<Scalars['String']>
  equity_futures?: Maybe<Scalars['String']>
  equity_intraday?: Maybe<Scalars['String']>
  equity_options?: Maybe<Scalars['String']>
  flagship_plan?: Maybe<Scalars['Boolean']>
  id: Scalars['Id']
  mutual_fund?: Maybe<Scalars['String']>
  plan_name?: Maybe<Scalars['String']>
}

export type ComponentChargesConsolidatedChargesFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentChargesConsolidatedChargesFiltersInput>>
  >
  commodity?: InputMaybe<StringFilterInput>
  currency_futures?: InputMaybe<StringFilterInput>
  currency_options?: InputMaybe<StringFilterInput>
  description?: InputMaybe<StringFilterInput>
  equity_delivery?: InputMaybe<StringFilterInput>
  equity_futures?: InputMaybe<StringFilterInput>
  equity_intraday?: InputMaybe<StringFilterInput>
  equity_options?: InputMaybe<StringFilterInput>
  flagship_plan?: InputMaybe<BooleanFilterInput>
  mutual_fund?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentChargesConsolidatedChargesFiltersInput>
  or?: InputMaybe<
    Array<InputMaybe<ComponentChargesConsolidatedChargesFiltersInput>>
  >
  plan_name?: InputMaybe<StringFilterInput>
}

export type ComponentCountryListCountries = {
  __typename?: 'ComponentCountryListCountries'
  id: Scalars['Id']
  name?: Maybe<Enum_Componentcountrylistcountries_Name>
}

export type ComponentCountryListCountriesFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentCountryListCountriesFiltersInput>>>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentCountryListCountriesFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ComponentCountryListCountriesFiltersInput>>>
}

export type ComponentExcerptFeatureExcerpt = {
  __typename?: 'ComponentExcerptFeatureExcerpt'
  description?: Maybe<Scalars['String']>
  id: Scalars['Id']
}

export type ComponentExchangelistExchanges = {
  __typename?: 'ComponentExchangelistExchanges'
  bse?: Maybe<Scalars['Boolean']>
  id: Scalars['Id']
  mcx?: Maybe<Scalars['Boolean']>
  ncdex?: Maybe<Scalars['Boolean']>
  nse?: Maybe<Scalars['Boolean']>
}

export type ComponentExchangelistExchangesFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentExchangelistExchangesFiltersInput>>
  >
  bse?: InputMaybe<BooleanFilterInput>
  mcx?: InputMaybe<BooleanFilterInput>
  ncdex?: InputMaybe<BooleanFilterInput>
  not?: InputMaybe<ComponentExchangelistExchangesFiltersInput>
  nse?: InputMaybe<BooleanFilterInput>
  or?: InputMaybe<Array<InputMaybe<ComponentExchangelistExchangesFiltersInput>>>
}

export type ComponentIpoDatesTentativeDates = {
  __typename?: 'ComponentIpoDatesTentativeDates'
  basis_finalization?: Maybe<Scalars['Date']>
  bid_close?: Maybe<Scalars['Date']>
  bid_open?: Maybe<Scalars['Date']>
  id: Scalars['Id']
  listing_date?: Maybe<Scalars['Date']>
  refund_date?: Maybe<Scalars['Date']>
  share_credit_date?: Maybe<Scalars['Date']>
}

export type ComponentIpoDatesTentativeDatesFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentIpoDatesTentativeDatesFiltersInput>>
  >
  basis_finalization?: InputMaybe<DateFilterInput>
  bid_close?: InputMaybe<DateFilterInput>
  bid_open?: InputMaybe<DateFilterInput>
  listing_date?: InputMaybe<DateFilterInput>
  not?: InputMaybe<ComponentIpoDatesTentativeDatesFiltersInput>
  or?: InputMaybe<
    Array<InputMaybe<ComponentIpoDatesTentativeDatesFiltersInput>>
  >
  refund_date?: InputMaybe<DateFilterInput>
  share_credit_date?: InputMaybe<DateFilterInput>
}

export type ComponentMarginsExposure = {
  __typename?: 'ComponentMarginsExposure'
  commodity_future?: Maybe<Scalars['String']>
  commodity_options?: Maybe<Scalars['String']>
  currency_future?: Maybe<Scalars['String']>
  currency_options?: Maybe<Scalars['String']>
  equity_delivery?: Maybe<Scalars['String']>
  equity_future?: Maybe<Scalars['String']>
  equity_intraday?: Maybe<Scalars['String']>
  equity_options?: Maybe<Scalars['String']>
  id: Scalars['Id']
}

export type ComponentMarginsExposureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentMarginsExposureFiltersInput>>>
  commodity_future?: InputMaybe<StringFilterInput>
  commodity_options?: InputMaybe<StringFilterInput>
  currency_future?: InputMaybe<StringFilterInput>
  currency_options?: InputMaybe<StringFilterInput>
  equity_delivery?: InputMaybe<StringFilterInput>
  equity_future?: InputMaybe<StringFilterInput>
  equity_intraday?: InputMaybe<StringFilterInput>
  equity_options?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentMarginsExposureFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ComponentMarginsExposureFiltersInput>>>
}

export type ComponentOrderTypesOrderTypes = {
  __typename?: 'ComponentOrderTypesOrderTypes'
  amo?: Maybe<Scalars['Boolean']>
  bracket?: Maybe<Scalars['Boolean']>
  btst?: Maybe<Scalars['Boolean']>
  cover?: Maybe<Scalars['Boolean']>
  gtc?: Maybe<Scalars['Boolean']>
  gtt?: Maybe<Scalars['Boolean']>
  id: Scalars['Id']
  stbt?: Maybe<Scalars['Boolean']>
}

export type ComponentOrderTypesOrderTypesFiltersInput = {
  amo?: InputMaybe<BooleanFilterInput>
  and?: InputMaybe<Array<InputMaybe<ComponentOrderTypesOrderTypesFiltersInput>>>
  bracket?: InputMaybe<BooleanFilterInput>
  btst?: InputMaybe<BooleanFilterInput>
  cover?: InputMaybe<BooleanFilterInput>
  gtc?: InputMaybe<BooleanFilterInput>
  gtt?: InputMaybe<BooleanFilterInput>
  not?: InputMaybe<ComponentOrderTypesOrderTypesFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ComponentOrderTypesOrderTypesFiltersInput>>>
  stbt?: InputMaybe<BooleanFilterInput>
}

export type ComponentProsConsContent = {
  __typename?: 'ComponentProsConsContent'
  display_text: Scalars['String']
  id: Scalars['Id']
  value_type: Enum_Componentprosconscontent_Value_Type
}

export type ComponentProsConsContentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentProsConsContentFiltersInput>>>
  display_text?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentProsConsContentFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ComponentProsConsContentFiltersInput>>>
  value_type?: InputMaybe<StringFilterInput>
}

export type ComponentSeoSeo = {
  __typename?: 'ComponentSeoSeo'
  description?: Maybe<Scalars['String']>
  id: Scalars['Id']
  title?: Maybe<Scalars['String']>
}

export type ComponentSeoSeoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSeoSeoFiltersInput>>>
  description?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentSeoSeoFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ComponentSeoSeoFiltersInput>>>
  title?: InputMaybe<StringFilterInput>
}

export type ComponentSeoSeoInput = {
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Id']>
  title?: InputMaybe<Scalars['String']>
}

export type ComponentTradingoptionsTradingOptions = {
  __typename?: 'ComponentTradingoptionsTradingOptions'
  bonds?: Maybe<Scalars['Boolean']>
  commodity?: Maybe<Scalars['Boolean']>
  currency?: Maybe<Scalars['Boolean']>
  debt?: Maybe<Scalars['Boolean']>
  equity?: Maybe<Scalars['Boolean']>
  fno?: Maybe<Scalars['Boolean']>
  id: Scalars['Id']
  ipo?: Maybe<Scalars['Boolean']>
  mutual_funds?: Maybe<Scalars['Boolean']>
  other?: Maybe<Scalars['String']>
}

export type ComponentTradingoptionsTradingOptionsFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentTradingoptionsTradingOptionsFiltersInput>>
  >
  bonds?: InputMaybe<BooleanFilterInput>
  commodity?: InputMaybe<BooleanFilterInput>
  currency?: InputMaybe<BooleanFilterInput>
  debt?: InputMaybe<BooleanFilterInput>
  equity?: InputMaybe<BooleanFilterInput>
  fno?: InputMaybe<BooleanFilterInput>
  ipo?: InputMaybe<BooleanFilterInput>
  mutual_funds?: InputMaybe<BooleanFilterInput>
  not?: InputMaybe<ComponentTradingoptionsTradingOptionsFiltersInput>
  or?: InputMaybe<
    Array<InputMaybe<ComponentTradingoptionsTradingOptionsFiltersInput>>
  >
  other?: InputMaybe<StringFilterInput>
}

export type ComponentUploadImageUploadImage = {
  __typename?: 'ComponentUploadImageUploadImage'
  alt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  file?: Maybe<UploadFileRelationResponseCollection>
  id: Scalars['Id']
}

export type ComponentUploadImageUploadImageFileArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentUploadImageUploadImageFiltersInput = {
  alt?: InputMaybe<StringFilterInput>
  and?: InputMaybe<
    Array<InputMaybe<ComponentUploadImageUploadImageFiltersInput>>
  >
  description?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ComponentUploadImageUploadImageFiltersInput>
  or?: InputMaybe<
    Array<InputMaybe<ComponentUploadImageUploadImageFiltersInput>>
  >
}

export type ComponentUploadImageUploadImageInput = {
  alt?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Array<InputMaybe<Scalars['Id']>>>
  id?: InputMaybe<Scalars['Id']>
}

export type Country = {
  __typename?: 'Country'
  articles?: Maybe<ArticleRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  exchanges?: Maybe<ExchangeRelationResponseCollection>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  softwares?: Maybe<SoftwareRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CountryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CountryExchangesArgs = {
  filters?: InputMaybe<ExchangeFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CountrySoftwaresArgs = {
  filters?: InputMaybe<SoftwareFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CountryEntity = {
  __typename?: 'CountryEntity'
  attributes?: Maybe<Country>
  id?: Maybe<Scalars['Id']>
}

export type CountryEntityResponse = {
  __typename?: 'CountryEntityResponse'
  data?: Maybe<CountryEntity>
}

export type CountryEntityResponseCollection = {
  __typename?: 'CountryEntityResponseCollection'
  data: Array<CountryEntity>
  meta: ResponseCollectionMeta
}

export type CountryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CountryFiltersInput>>>
  articles?: InputMaybe<ArticleFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  exchanges?: InputMaybe<ExchangeFiltersInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<CountryFiltersInput>
  or?: InputMaybe<Array<InputMaybe<CountryFiltersInput>>>
  slug?: InputMaybe<StringFilterInput>
  softwares?: InputMaybe<SoftwareFiltersInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CountryRelationResponseCollection = {
  __typename?: 'CountryRelationResponseCollection'
  data: Array<CountryEntity>
}

export type CreateComment = {
  author?: InputMaybe<CreateCommentAuthor>
  content: Scalars['String']
  relation: Scalars['String']
  threadOf?: InputMaybe<Scalars['Id']>
}

export type CreateCommentAuthor = {
  avatar?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  id: Scalars['Id']
  name: Scalars['String']
}

export type CreateReport = {
  commentId?: InputMaybe<Scalars['Id']>
  content: Scalars['String']
  reason?: InputMaybe<ReportReason>
  relation: Scalars['String']
}

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
  contains?: InputMaybe<Scalars['Date']>
  containsi?: InputMaybe<Scalars['Date']>
  endsWith?: InputMaybe<Scalars['Date']>
  eq?: InputMaybe<Scalars['Date']>
  eqi?: InputMaybe<Scalars['Date']>
  gt?: InputMaybe<Scalars['Date']>
  gte?: InputMaybe<Scalars['Date']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
  lt?: InputMaybe<Scalars['Date']>
  lte?: InputMaybe<Scalars['Date']>
  ne?: InputMaybe<Scalars['Date']>
  not?: InputMaybe<DateFilterInput>
  notContains?: InputMaybe<Scalars['Date']>
  notContainsi?: InputMaybe<Scalars['Date']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
  startsWith?: InputMaybe<Scalars['Date']>
}

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  contains?: InputMaybe<Scalars['DateTime']>
  containsi?: InputMaybe<Scalars['DateTime']>
  endsWith?: InputMaybe<Scalars['DateTime']>
  eq?: InputMaybe<Scalars['DateTime']>
  eqi?: InputMaybe<Scalars['DateTime']>
  gt?: InputMaybe<Scalars['DateTime']>
  gte?: InputMaybe<Scalars['DateTime']>
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  lt?: InputMaybe<Scalars['DateTime']>
  lte?: InputMaybe<Scalars['DateTime']>
  ne?: InputMaybe<Scalars['DateTime']>
  not?: InputMaybe<DateTimeFilterInput>
  notContains?: InputMaybe<Scalars['DateTime']>
  notContainsi?: InputMaybe<Scalars['DateTime']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startsWith?: InputMaybe<Scalars['DateTime']>
}

export enum Enum_Articlecomment_Status {
  Deleted = 'deleted',
  Pending = 'pending',
  Published = 'published'
}

export enum Enum_Article_Article_Type {
  Blog = 'blog',
  Faq = 'faq'
}

export enum Enum_Article_Creator {
  System = 'system',
  User = 'user'
}

export enum Enum_Article_Page_Url {
  AccountOpening = 'account_opening',
  AliceblueVsAnandRathi = 'aliceblue_vs_anand_rathi',
  AliceblueVsAngelBroking = 'aliceblue_vs_angel_broking',
  AliceblueVsAxisdirect = 'aliceblue_vs_axisdirect',
  AliceblueVsEdelweiss = 'aliceblue_vs_edelweiss',
  AliceblueVsFinvasia = 'aliceblue_vs_finvasia',
  AliceblueVsFyers = 'aliceblue_vs_fyers',
  AliceblueVsHdfcSecurities = 'aliceblue_vs_hdfc_securities',
  AliceblueVsIciciDirect = 'aliceblue_vs_icici_direct',
  AliceblueVsIdbiDirectCapital = 'aliceblue_vs_idbi_direct_capital',
  AliceblueVsIiflSecurities = 'aliceblue_vs_iifl_securities',
  AliceblueVsIndiabullsVenturesShubh = 'aliceblue_vs_indiabulls_ventures_shubh',
  AliceblueVsInteractiveBrokers = 'aliceblue_vs_interactive_brokers',
  AliceblueVsKarvyStockBroking = 'aliceblue_vs_karvy_stock_broking',
  AliceblueVsKotakSecurities = 'aliceblue_vs_kotak_securities',
  AliceblueVsMarwadiShares = 'aliceblue_vs_marwadi_shares',
  AliceblueVsMasterTrust = 'aliceblue_vs_master_trust',
  AliceblueVsMotilalOswal = 'aliceblue_vs_motilal_oswal',
  AliceblueVsNirmalBang = 'aliceblue_vs_nirmal_bang',
  AliceblueVsPaytmMoney = 'aliceblue_vs_paytm_money',
  AliceblueVsProstocks = 'aliceblue_vs_prostocks',
  AliceblueVsRelianceSecurities = 'aliceblue_vs_reliance_securities',
  AliceblueVsSamco = 'aliceblue_vs_samco',
  AliceblueVsSbicapSecurities = 'aliceblue_vs_sbicap_securities',
  AliceblueVsSharekhan = 'aliceblue_vs_sharekhan',
  AliceblueVsStoxkart = 'aliceblue_vs_stoxkart',
  AliceblueVsTradeSmartOnline = 'aliceblue_vs_trade_smart_online',
  AliceblueVsTradingbells = 'aliceblue_vs_tradingbells',
  AliceblueVsUpstox = 'aliceblue_vs_upstox',
  AliceblueVsVenturaSecurities = 'aliceblue_vs_ventura_securities',
  AliceblueVsWisdomCapital = 'aliceblue_vs_wisdom_capital',
  AliceblueVsYesSecurities = 'aliceblue_vs_yes_securities',
  AliceblueVsZerodha = 'aliceblue_vs_zerodha',
  AnandRathiVsAngelBroking = 'anand_rathi_vs_angel_broking',
  AnandRathiVsAxisdirect = 'anand_rathi_vs_axisdirect',
  AnandRathiVsEdelweiss = 'anand_rathi_vs_edelweiss',
  AnandRathiVsFinvasia = 'anand_rathi_vs_finvasia',
  AnandRathiVsFyers = 'anand_rathi_vs_fyers',
  AnandRathiVsHdfcSecurities = 'anand_rathi_vs_hdfc_securities',
  AnandRathiVsIciciDirect = 'anand_rathi_vs_icici_direct',
  AnandRathiVsIdbiDirectCapital = 'anand_rathi_vs_idbi_direct_capital',
  AnandRathiVsIiflSecurities = 'anand_rathi_vs_iifl_securities',
  AnandRathiVsIndiabullsVenturesShubh = 'anand_rathi_vs_indiabulls_ventures_shubh',
  AnandRathiVsInteractiveBrokers = 'anand_rathi_vs_interactive_brokers',
  AnandRathiVsKarvyStockBroking = 'anand_rathi_vs_karvy_stock_broking',
  AnandRathiVsKotakSecurities = 'anand_rathi_vs_kotak_securities',
  AnandRathiVsMarwadiShares = 'anand_rathi_vs_marwadi_shares',
  AnandRathiVsMasterTrust = 'anand_rathi_vs_master_trust',
  AnandRathiVsMotilalOswal = 'anand_rathi_vs_motilal_oswal',
  AnandRathiVsNirmalBang = 'anand_rathi_vs_nirmal_bang',
  AnandRathiVsPaytmMoney = 'anand_rathi_vs_paytm_money',
  AnandRathiVsProstocks = 'anand_rathi_vs_prostocks',
  AnandRathiVsRelianceSecurities = 'anand_rathi_vs_reliance_securities',
  AnandRathiVsSamco = 'anand_rathi_vs_samco',
  AnandRathiVsSbicapSecurities = 'anand_rathi_vs_sbicap_securities',
  AnandRathiVsSharekhan = 'anand_rathi_vs_sharekhan',
  AnandRathiVsStoxkart = 'anand_rathi_vs_stoxkart',
  AnandRathiVsTradeSmartOnline = 'anand_rathi_vs_trade_smart_online',
  AnandRathiVsTradingbells = 'anand_rathi_vs_tradingbells',
  AnandRathiVsUpstox = 'anand_rathi_vs_upstox',
  AnandRathiVsVenturaSecurities = 'anand_rathi_vs_ventura_securities',
  AnandRathiVsWisdomCapital = 'anand_rathi_vs_wisdom_capital',
  AnandRathiVsYesSecurities = 'anand_rathi_vs_yes_securities',
  AnandRathiVsZerodha = 'anand_rathi_vs_zerodha',
  AngelBrokingVsAxisdirect = 'angel_broking_vs_axisdirect',
  AngelBrokingVsEdelweiss = 'angel_broking_vs_edelweiss',
  AngelBrokingVsFinvasia = 'angel_broking_vs_finvasia',
  AngelBrokingVsFyers = 'angel_broking_vs_fyers',
  AngelBrokingVsHdfcSecurities = 'angel_broking_vs_hdfc_securities',
  AngelBrokingVsIciciDirect = 'angel_broking_vs_icici_direct',
  AngelBrokingVsIdbiDirectCapital = 'angel_broking_vs_idbi_direct_capital',
  AngelBrokingVsIiflSecurities = 'angel_broking_vs_iifl_securities',
  AngelBrokingVsIndiabullsVenturesShubh = 'angel_broking_vs_indiabulls_ventures_shubh',
  AngelBrokingVsInteractiveBrokers = 'angel_broking_vs_interactive_brokers',
  AngelBrokingVsKarvyStockBroking = 'angel_broking_vs_karvy_stock_broking',
  AngelBrokingVsKotakSecurities = 'angel_broking_vs_kotak_securities',
  AngelBrokingVsMarwadiShares = 'angel_broking_vs_marwadi_shares',
  AngelBrokingVsMasterTrust = 'angel_broking_vs_master_trust',
  AngelBrokingVsMotilalOswal = 'angel_broking_vs_motilal_oswal',
  AngelBrokingVsNirmalBang = 'angel_broking_vs_nirmal_bang',
  AngelBrokingVsPaytmMoney = 'angel_broking_vs_paytm_money',
  AngelBrokingVsProstocks = 'angel_broking_vs_prostocks',
  AngelBrokingVsRelianceSecurities = 'angel_broking_vs_reliance_securities',
  AngelBrokingVsSamco = 'angel_broking_vs_samco',
  AngelBrokingVsSbicapSecurities = 'angel_broking_vs_sbicap_securities',
  AngelBrokingVsSharekhan = 'angel_broking_vs_sharekhan',
  AngelBrokingVsStoxkart = 'angel_broking_vs_stoxkart',
  AngelBrokingVsTradeSmartOnline = 'angel_broking_vs_trade_smart_online',
  AngelBrokingVsTradingbells = 'angel_broking_vs_tradingbells',
  AngelBrokingVsUpstox = 'angel_broking_vs_upstox',
  AngelBrokingVsVenturaSecurities = 'angel_broking_vs_ventura_securities',
  AngelBrokingVsWisdomCapital = 'angel_broking_vs_wisdom_capital',
  AngelBrokingVsYesSecurities = 'angel_broking_vs_yes_securities',
  AngelBrokingVsZerodha = 'angel_broking_vs_zerodha',
  AxisdirectVsEdelweiss = 'axisdirect_vs_edelweiss',
  AxisdirectVsFinvasia = 'axisdirect_vs_finvasia',
  AxisdirectVsFyers = 'axisdirect_vs_fyers',
  AxisdirectVsHdfcSecurities = 'axisdirect_vs_hdfc_securities',
  AxisdirectVsIciciDirect = 'axisdirect_vs_icici_direct',
  AxisdirectVsIdbiDirectCapital = 'axisdirect_vs_idbi_direct_capital',
  AxisdirectVsIiflSecurities = 'axisdirect_vs_iifl_securities',
  AxisdirectVsIndiabullsVenturesShubh = 'axisdirect_vs_indiabulls_ventures_shubh',
  AxisdirectVsInteractiveBrokers = 'axisdirect_vs_interactive_brokers',
  AxisdirectVsKarvyStockBroking = 'axisdirect_vs_karvy_stock_broking',
  AxisdirectVsKotakSecurities = 'axisdirect_vs_kotak_securities',
  AxisdirectVsMarwadiShares = 'axisdirect_vs_marwadi_shares',
  AxisdirectVsMasterTrust = 'axisdirect_vs_master_trust',
  AxisdirectVsMotilalOswal = 'axisdirect_vs_motilal_oswal',
  AxisdirectVsNirmalBang = 'axisdirect_vs_nirmal_bang',
  AxisdirectVsPaytmMoney = 'axisdirect_vs_paytm_money',
  AxisdirectVsProstocks = 'axisdirect_vs_prostocks',
  AxisdirectVsRelianceSecurities = 'axisdirect_vs_reliance_securities',
  AxisdirectVsSamco = 'axisdirect_vs_samco',
  AxisdirectVsSbicapSecurities = 'axisdirect_vs_sbicap_securities',
  AxisdirectVsSharekhan = 'axisdirect_vs_sharekhan',
  AxisdirectVsStoxkart = 'axisdirect_vs_stoxkart',
  AxisdirectVsTradeSmartOnline = 'axisdirect_vs_trade_smart_online',
  AxisdirectVsTradingbells = 'axisdirect_vs_tradingbells',
  AxisdirectVsUpstox = 'axisdirect_vs_upstox',
  AxisdirectVsVenturaSecurities = 'axisdirect_vs_ventura_securities',
  AxisdirectVsWisdomCapital = 'axisdirect_vs_wisdom_capital',
  AxisdirectVsYesSecurities = 'axisdirect_vs_yes_securities',
  AxisdirectVsZerodha = 'axisdirect_vs_zerodha',
  CommodityTrading = 'commodity_trading',
  CurrencyTrading = 'currency_trading',
  Demat = 'demat',
  EdelweissVsFinvasia = 'edelweiss_vs_finvasia',
  EdelweissVsFyers = 'edelweiss_vs_fyers',
  EdelweissVsHdfcSecurities = 'edelweiss_vs_hdfc_securities',
  EdelweissVsIciciDirect = 'edelweiss_vs_icici_direct',
  EdelweissVsIdbiDirectCapital = 'edelweiss_vs_idbi_direct_capital',
  EdelweissVsIiflSecurities = 'edelweiss_vs_iifl_securities',
  EdelweissVsIndiabullsVenturesShubh = 'edelweiss_vs_indiabulls_ventures_shubh',
  EdelweissVsInteractiveBrokers = 'edelweiss_vs_interactive_brokers',
  EdelweissVsKarvyStockBroking = 'edelweiss_vs_karvy_stock_broking',
  EdelweissVsKotakSecurities = 'edelweiss_vs_kotak_securities',
  EdelweissVsMarwadiShares = 'edelweiss_vs_marwadi_shares',
  EdelweissVsMasterTrust = 'edelweiss_vs_master_trust',
  EdelweissVsMotilalOswal = 'edelweiss_vs_motilal_oswal',
  EdelweissVsNirmalBang = 'edelweiss_vs_nirmal_bang',
  EdelweissVsPaytmMoney = 'edelweiss_vs_paytm_money',
  EdelweissVsProstocks = 'edelweiss_vs_prostocks',
  EdelweissVsRelianceSecurities = 'edelweiss_vs_reliance_securities',
  EdelweissVsSamco = 'edelweiss_vs_samco',
  EdelweissVsSbicapSecurities = 'edelweiss_vs_sbicap_securities',
  EdelweissVsSharekhan = 'edelweiss_vs_sharekhan',
  EdelweissVsStoxkart = 'edelweiss_vs_stoxkart',
  EdelweissVsTradeSmartOnline = 'edelweiss_vs_trade_smart_online',
  EdelweissVsTradingbells = 'edelweiss_vs_tradingbells',
  EdelweissVsUpstox = 'edelweiss_vs_upstox',
  EdelweissVsVenturaSecurities = 'edelweiss_vs_ventura_securities',
  EdelweissVsWisdomCapital = 'edelweiss_vs_wisdom_capital',
  EdelweissVsYesSecurities = 'edelweiss_vs_yes_securities',
  EdelweissVsZerodha = 'edelweiss_vs_zerodha',
  Exposure = 'exposure',
  FinvasiaVsFyers = 'finvasia_vs_fyers',
  FinvasiaVsHdfcSecurities = 'finvasia_vs_hdfc_securities',
  FinvasiaVsIciciDirect = 'finvasia_vs_icici_direct',
  FinvasiaVsIdbiDirectCapital = 'finvasia_vs_idbi_direct_capital',
  FinvasiaVsIiflSecurities = 'finvasia_vs_iifl_securities',
  FinvasiaVsIndiabullsVenturesShubh = 'finvasia_vs_indiabulls_ventures_shubh',
  FinvasiaVsInteractiveBrokers = 'finvasia_vs_interactive_brokers',
  FinvasiaVsKarvyStockBroking = 'finvasia_vs_karvy_stock_broking',
  FinvasiaVsKotakSecurities = 'finvasia_vs_kotak_securities',
  FinvasiaVsMarwadiShares = 'finvasia_vs_marwadi_shares',
  FinvasiaVsMasterTrust = 'finvasia_vs_master_trust',
  FinvasiaVsMotilalOswal = 'finvasia_vs_motilal_oswal',
  FinvasiaVsNirmalBang = 'finvasia_vs_nirmal_bang',
  FinvasiaVsPaytmMoney = 'finvasia_vs_paytm_money',
  FinvasiaVsProstocks = 'finvasia_vs_prostocks',
  FinvasiaVsRelianceSecurities = 'finvasia_vs_reliance_securities',
  FinvasiaVsSamco = 'finvasia_vs_samco',
  FinvasiaVsSbicapSecurities = 'finvasia_vs_sbicap_securities',
  FinvasiaVsSharekhan = 'finvasia_vs_sharekhan',
  FinvasiaVsStoxkart = 'finvasia_vs_stoxkart',
  FinvasiaVsTradeSmartOnline = 'finvasia_vs_trade_smart_online',
  FinvasiaVsTradingbells = 'finvasia_vs_tradingbells',
  FinvasiaVsUpstox = 'finvasia_vs_upstox',
  FinvasiaVsVenturaSecurities = 'finvasia_vs_ventura_securities',
  FinvasiaVsWisdomCapital = 'finvasia_vs_wisdom_capital',
  FinvasiaVsYesSecurities = 'finvasia_vs_yes_securities',
  FinvasiaVsZerodha = 'finvasia_vs_zerodha',
  FyersVsHdfcSecurities = 'fyers_vs_hdfc_securities',
  FyersVsIciciDirect = 'fyers_vs_icici_direct',
  FyersVsIdbiDirectCapital = 'fyers_vs_idbi_direct_capital',
  FyersVsIiflSecurities = 'fyers_vs_iifl_securities',
  FyersVsIndiabullsVenturesShubh = 'fyers_vs_indiabulls_ventures_shubh',
  FyersVsInteractiveBrokers = 'fyers_vs_interactive_brokers',
  FyersVsKarvyStockBroking = 'fyers_vs_karvy_stock_broking',
  FyersVsKotakSecurities = 'fyers_vs_kotak_securities',
  FyersVsMarwadiShares = 'fyers_vs_marwadi_shares',
  FyersVsMasterTrust = 'fyers_vs_master_trust',
  FyersVsMotilalOswal = 'fyers_vs_motilal_oswal',
  FyersVsNirmalBang = 'fyers_vs_nirmal_bang',
  FyersVsPaytmMoney = 'fyers_vs_paytm_money',
  FyersVsProstocks = 'fyers_vs_prostocks',
  FyersVsRelianceSecurities = 'fyers_vs_reliance_securities',
  FyersVsSamco = 'fyers_vs_samco',
  FyersVsSbicapSecurities = 'fyers_vs_sbicap_securities',
  FyersVsSharekhan = 'fyers_vs_sharekhan',
  FyersVsStoxkart = 'fyers_vs_stoxkart',
  FyersVsTradeSmartOnline = 'fyers_vs_trade_smart_online',
  FyersVsTradingbells = 'fyers_vs_tradingbells',
  FyersVsUpstox = 'fyers_vs_upstox',
  FyersVsVenturaSecurities = 'fyers_vs_ventura_securities',
  FyersVsWisdomCapital = 'fyers_vs_wisdom_capital',
  FyersVsYesSecurities = 'fyers_vs_yes_securities',
  FyersVsZerodha = 'fyers_vs_zerodha',
  GrowwVsAliceblue = 'groww_vs_aliceblue',
  GrowwVsAnandRathi = 'groww_vs_anand_rathi',
  GrowwVsAngelBroking = 'groww_vs_angel_broking',
  GrowwVsAxisdirect = 'groww_vs_axisdirect',
  GrowwVsEdelweiss = 'groww_vs_edelweiss',
  GrowwVsFinvasia = 'groww_vs_finvasia',
  GrowwVsFyers = 'groww_vs_fyers',
  GrowwVsHdfcSecurities = 'groww_vs_hdfc_securities',
  GrowwVsIciciDirect = 'groww_vs_icici_direct',
  GrowwVsIdbiDirectCapital = 'groww_vs_idbi_direct_capital',
  GrowwVsIiflSecurities = 'groww_vs_iifl_securities',
  GrowwVsIndiabullsVenturesShubh = 'groww_vs_indiabulls_ventures_shubh',
  GrowwVsInteractiveBrokers = 'groww_vs_interactive_brokers',
  GrowwVsKarvyStockBroking = 'groww_vs_karvy_stock_broking',
  GrowwVsKotakSecurities = 'groww_vs_kotak_securities',
  GrowwVsMarwadiShares = 'groww_vs_marwadi_shares',
  GrowwVsMasterTrust = 'groww_vs_master_trust',
  GrowwVsMotilalOswal = 'groww_vs_motilal_oswal',
  GrowwVsNirmalBang = 'groww_vs_nirmal_bang',
  GrowwVsPaytmMoney = 'groww_vs_paytm_money',
  GrowwVsProstocks = 'groww_vs_prostocks',
  GrowwVsRelianceSecurities = 'groww_vs_reliance_securities',
  GrowwVsSamco = 'groww_vs_samco',
  GrowwVsSbicapSecurities = 'groww_vs_sbicap_securities',
  GrowwVsSharekhan = 'groww_vs_sharekhan',
  GrowwVsStoxkart = 'groww_vs_stoxkart',
  GrowwVsTradeSmartOnline = 'groww_vs_trade_smart_online',
  GrowwVsTradingbells = 'groww_vs_tradingbells',
  GrowwVsUpstox = 'groww_vs_upstox',
  GrowwVsVenturaSecurities = 'groww_vs_ventura_securities',
  GrowwVsWisdomCapital = 'groww_vs_wisdom_capital',
  GrowwVsYesSecurities = 'groww_vs_yes_securities',
  GrowwVsZerodha = 'groww_vs_zerodha',
  HdfcSecuritiesVsIciciDirect = 'hdfc_securities_vs_icici_direct',
  HdfcSecuritiesVsIdbiDirectCapital = 'hdfc_securities_vs_idbi_direct_capital',
  HdfcSecuritiesVsIiflSecurities = 'hdfc_securities_vs_iifl_securities',
  HdfcSecuritiesVsIndiabullsVenturesShubh = 'hdfc_securities_vs_indiabulls_ventures_shubh',
  HdfcSecuritiesVsInteractiveBrokers = 'hdfc_securities_vs_interactive_brokers',
  HdfcSecuritiesVsKarvyStockBroking = 'hdfc_securities_vs_karvy_stock_broking',
  HdfcSecuritiesVsKotakSecurities = 'hdfc_securities_vs_kotak_securities',
  HdfcSecuritiesVsMarwadiShares = 'hdfc_securities_vs_marwadi_shares',
  HdfcSecuritiesVsMasterTrust = 'hdfc_securities_vs_master_trust',
  HdfcSecuritiesVsMotilalOswal = 'hdfc_securities_vs_motilal_oswal',
  HdfcSecuritiesVsNirmalBang = 'hdfc_securities_vs_nirmal_bang',
  HdfcSecuritiesVsPaytmMoney = 'hdfc_securities_vs_paytm_money',
  HdfcSecuritiesVsProstocks = 'hdfc_securities_vs_prostocks',
  HdfcSecuritiesVsRelianceSecurities = 'hdfc_securities_vs_reliance_securities',
  HdfcSecuritiesVsSamco = 'hdfc_securities_vs_samco',
  HdfcSecuritiesVsSbicapSecurities = 'hdfc_securities_vs_sbicap_securities',
  HdfcSecuritiesVsSharekhan = 'hdfc_securities_vs_sharekhan',
  HdfcSecuritiesVsStoxkart = 'hdfc_securities_vs_stoxkart',
  HdfcSecuritiesVsTradeSmartOnline = 'hdfc_securities_vs_trade_smart_online',
  HdfcSecuritiesVsTradingbells = 'hdfc_securities_vs_tradingbells',
  HdfcSecuritiesVsUpstox = 'hdfc_securities_vs_upstox',
  HdfcSecuritiesVsVenturaSecurities = 'hdfc_securities_vs_ventura_securities',
  HdfcSecuritiesVsWisdomCapital = 'hdfc_securities_vs_wisdom_capital',
  HdfcSecuritiesVsYesSecurities = 'hdfc_securities_vs_yes_securities',
  HdfcSecuritiesVsZerodha = 'hdfc_securities_vs_zerodha',
  IciciDirectVsIdbiDirectCapital = 'icici_direct_vs_idbi_direct_capital',
  IciciDirectVsIiflSecurities = 'icici_direct_vs_iifl_securities',
  IciciDirectVsIndiabullsVenturesShubh = 'icici_direct_vs_indiabulls_ventures_shubh',
  IciciDirectVsInteractiveBrokers = 'icici_direct_vs_interactive_brokers',
  IciciDirectVsKarvyStockBroking = 'icici_direct_vs_karvy_stock_broking',
  IciciDirectVsKotakSecurities = 'icici_direct_vs_kotak_securities',
  IciciDirectVsMarwadiShares = 'icici_direct_vs_marwadi_shares',
  IciciDirectVsMasterTrust = 'icici_direct_vs_master_trust',
  IciciDirectVsMotilalOswal = 'icici_direct_vs_motilal_oswal',
  IciciDirectVsNirmalBang = 'icici_direct_vs_nirmal_bang',
  IciciDirectVsPaytmMoney = 'icici_direct_vs_paytm_money',
  IciciDirectVsProstocks = 'icici_direct_vs_prostocks',
  IciciDirectVsRelianceSecurities = 'icici_direct_vs_reliance_securities',
  IciciDirectVsSamco = 'icici_direct_vs_samco',
  IciciDirectVsSbicapSecurities = 'icici_direct_vs_sbicap_securities',
  IciciDirectVsSharekhan = 'icici_direct_vs_sharekhan',
  IciciDirectVsStoxkart = 'icici_direct_vs_stoxkart',
  IciciDirectVsTradeSmartOnline = 'icici_direct_vs_trade_smart_online',
  IciciDirectVsTradingbells = 'icici_direct_vs_tradingbells',
  IciciDirectVsUpstox = 'icici_direct_vs_upstox',
  IciciDirectVsVenturaSecurities = 'icici_direct_vs_ventura_securities',
  IciciDirectVsWisdomCapital = 'icici_direct_vs_wisdom_capital',
  IciciDirectVsYesSecurities = 'icici_direct_vs_yes_securities',
  IciciDirectVsZerodha = 'icici_direct_vs_zerodha',
  IdbiDirectCapitalVsIiflSecurities = 'idbi_direct_capital_vs_iifl_securities',
  IdbiDirectCapitalVsIndiabullsVenturesShubh = 'idbi_direct_capital_vs_indiabulls_ventures_shubh',
  IdbiDirectCapitalVsInteractiveBrokers = 'idbi_direct_capital_vs_interactive_brokers',
  IdbiDirectCapitalVsKarvyStockBroking = 'idbi_direct_capital_vs_karvy_stock_broking',
  IdbiDirectCapitalVsKotakSecurities = 'idbi_direct_capital_vs_kotak_securities',
  IdbiDirectCapitalVsMarwadiShares = 'idbi_direct_capital_vs_marwadi_shares',
  IdbiDirectCapitalVsMasterTrust = 'idbi_direct_capital_vs_master_trust',
  IdbiDirectCapitalVsMotilalOswal = 'idbi_direct_capital_vs_motilal_oswal',
  IdbiDirectCapitalVsNirmalBang = 'idbi_direct_capital_vs_nirmal_bang',
  IdbiDirectCapitalVsPaytmMoney = 'idbi_direct_capital_vs_paytm_money',
  IdbiDirectCapitalVsProstocks = 'idbi_direct_capital_vs_prostocks',
  IdbiDirectCapitalVsRelianceSecurities = 'idbi_direct_capital_vs_reliance_securities',
  IdbiDirectCapitalVsSamco = 'idbi_direct_capital_vs_samco',
  IdbiDirectCapitalVsSbicapSecurities = 'idbi_direct_capital_vs_sbicap_securities',
  IdbiDirectCapitalVsSharekhan = 'idbi_direct_capital_vs_sharekhan',
  IdbiDirectCapitalVsStoxkart = 'idbi_direct_capital_vs_stoxkart',
  IdbiDirectCapitalVsTradeSmartOnline = 'idbi_direct_capital_vs_trade_smart_online',
  IdbiDirectCapitalVsTradingbells = 'idbi_direct_capital_vs_tradingbells',
  IdbiDirectCapitalVsUpstox = 'idbi_direct_capital_vs_upstox',
  IdbiDirectCapitalVsVenturaSecurities = 'idbi_direct_capital_vs_ventura_securities',
  IdbiDirectCapitalVsWisdomCapital = 'idbi_direct_capital_vs_wisdom_capital',
  IdbiDirectCapitalVsYesSecurities = 'idbi_direct_capital_vs_yes_securities',
  IdbiDirectCapitalVsZerodha = 'idbi_direct_capital_vs_zerodha',
  IiflSecuritiesVsIndiabullsVenturesShubh = 'iifl_securities_vs_indiabulls_ventures_shubh',
  IiflSecuritiesVsInteractiveBrokers = 'iifl_securities_vs_interactive_brokers',
  IiflSecuritiesVsKarvyStockBroking = 'iifl_securities_vs_karvy_stock_broking',
  IiflSecuritiesVsKotakSecurities = 'iifl_securities_vs_kotak_securities',
  IiflSecuritiesVsMarwadiShares = 'iifl_securities_vs_marwadi_shares',
  IiflSecuritiesVsMasterTrust = 'iifl_securities_vs_master_trust',
  IiflSecuritiesVsMotilalOswal = 'iifl_securities_vs_motilal_oswal',
  IiflSecuritiesVsNirmalBang = 'iifl_securities_vs_nirmal_bang',
  IiflSecuritiesVsPaytmMoney = 'iifl_securities_vs_paytm_money',
  IiflSecuritiesVsProstocks = 'iifl_securities_vs_prostocks',
  IiflSecuritiesVsRelianceSecurities = 'iifl_securities_vs_reliance_securities',
  IiflSecuritiesVsSamco = 'iifl_securities_vs_samco',
  IiflSecuritiesVsSbicapSecurities = 'iifl_securities_vs_sbicap_securities',
  IiflSecuritiesVsSharekhan = 'iifl_securities_vs_sharekhan',
  IiflSecuritiesVsStoxkart = 'iifl_securities_vs_stoxkart',
  IiflSecuritiesVsTradeSmartOnline = 'iifl_securities_vs_trade_smart_online',
  IiflSecuritiesVsTradingbells = 'iifl_securities_vs_tradingbells',
  IiflSecuritiesVsUpstox = 'iifl_securities_vs_upstox',
  IiflSecuritiesVsVenturaSecurities = 'iifl_securities_vs_ventura_securities',
  IiflSecuritiesVsWisdomCapital = 'iifl_securities_vs_wisdom_capital',
  IiflSecuritiesVsYesSecurities = 'iifl_securities_vs_yes_securities',
  IiflSecuritiesVsZerodha = 'iifl_securities_vs_zerodha',
  IndiabullsVenturesShubhVsInteractiveBrokers = 'indiabulls_ventures_shubh_vs_interactive_brokers',
  IndiabullsVenturesShubhVsKarvyStockBroking = 'indiabulls_ventures_shubh_vs_karvy_stock_broking',
  IndiabullsVenturesShubhVsKotakSecurities = 'indiabulls_ventures_shubh_vs_kotak_securities',
  IndiabullsVenturesShubhVsMarwadiShares = 'indiabulls_ventures_shubh_vs_marwadi_shares',
  IndiabullsVenturesShubhVsMasterTrust = 'indiabulls_ventures_shubh_vs_master_trust',
  IndiabullsVenturesShubhVsMotilalOswal = 'indiabulls_ventures_shubh_vs_motilal_oswal',
  IndiabullsVenturesShubhVsNirmalBang = 'indiabulls_ventures_shubh_vs_nirmal_bang',
  IndiabullsVenturesShubhVsPaytmMoney = 'indiabulls_ventures_shubh_vs_paytm_money',
  IndiabullsVenturesShubhVsProstocks = 'indiabulls_ventures_shubh_vs_prostocks',
  IndiabullsVenturesShubhVsRelianceSecurities = 'indiabulls_ventures_shubh_vs_reliance_securities',
  IndiabullsVenturesShubhVsSamco = 'indiabulls_ventures_shubh_vs_samco',
  IndiabullsVenturesShubhVsSbicapSecurities = 'indiabulls_ventures_shubh_vs_sbicap_securities',
  IndiabullsVenturesShubhVsSharekhan = 'indiabulls_ventures_shubh_vs_sharekhan',
  IndiabullsVenturesShubhVsStoxkart = 'indiabulls_ventures_shubh_vs_stoxkart',
  IndiabullsVenturesShubhVsTradeSmartOnline = 'indiabulls_ventures_shubh_vs_trade_smart_online',
  IndiabullsVenturesShubhVsTradingbells = 'indiabulls_ventures_shubh_vs_tradingbells',
  IndiabullsVenturesShubhVsUpstox = 'indiabulls_ventures_shubh_vs_upstox',
  IndiabullsVenturesShubhVsVenturaSecurities = 'indiabulls_ventures_shubh_vs_ventura_securities',
  IndiabullsVenturesShubhVsWisdomCapital = 'indiabulls_ventures_shubh_vs_wisdom_capital',
  IndiabullsVenturesShubhVsYesSecurities = 'indiabulls_ventures_shubh_vs_yes_securities',
  IndiabullsVenturesShubhVsZerodha = 'indiabulls_ventures_shubh_vs_zerodha',
  InteractiveBrokersVsKarvyStockBroking = 'interactive_brokers_vs_karvy_stock_broking',
  InteractiveBrokersVsKotakSecurities = 'interactive_brokers_vs_kotak_securities',
  InteractiveBrokersVsMarwadiShares = 'interactive_brokers_vs_marwadi_shares',
  InteractiveBrokersVsMasterTrust = 'interactive_brokers_vs_master_trust',
  InteractiveBrokersVsMotilalOswal = 'interactive_brokers_vs_motilal_oswal',
  InteractiveBrokersVsNirmalBang = 'interactive_brokers_vs_nirmal_bang',
  InteractiveBrokersVsPaytmMoney = 'interactive_brokers_vs_paytm_money',
  InteractiveBrokersVsProstocks = 'interactive_brokers_vs_prostocks',
  InteractiveBrokersVsRelianceSecurities = 'interactive_brokers_vs_reliance_securities',
  InteractiveBrokersVsSamco = 'interactive_brokers_vs_samco',
  InteractiveBrokersVsSbicapSecurities = 'interactive_brokers_vs_sbicap_securities',
  InteractiveBrokersVsSharekhan = 'interactive_brokers_vs_sharekhan',
  InteractiveBrokersVsStoxkart = 'interactive_brokers_vs_stoxkart',
  InteractiveBrokersVsTradeSmartOnline = 'interactive_brokers_vs_trade_smart_online',
  InteractiveBrokersVsTradingbells = 'interactive_brokers_vs_tradingbells',
  InteractiveBrokersVsUpstox = 'interactive_brokers_vs_upstox',
  InteractiveBrokersVsVenturaSecurities = 'interactive_brokers_vs_ventura_securities',
  InteractiveBrokersVsWisdomCapital = 'interactive_brokers_vs_wisdom_capital',
  InteractiveBrokersVsYesSecurities = 'interactive_brokers_vs_yes_securities',
  InteractiveBrokersVsZerodha = 'interactive_brokers_vs_zerodha',
  KarvyStockBrokingVsKotakSecurities = 'karvy_stock_broking_vs_kotak_securities',
  KarvyStockBrokingVsMarwadiShares = 'karvy_stock_broking_vs_marwadi_shares',
  KarvyStockBrokingVsMasterTrust = 'karvy_stock_broking_vs_master_trust',
  KarvyStockBrokingVsMotilalOswal = 'karvy_stock_broking_vs_motilal_oswal',
  KarvyStockBrokingVsNirmalBang = 'karvy_stock_broking_vs_nirmal_bang',
  KarvyStockBrokingVsPaytmMoney = 'karvy_stock_broking_vs_paytm_money',
  KarvyStockBrokingVsProstocks = 'karvy_stock_broking_vs_prostocks',
  KarvyStockBrokingVsRelianceSecurities = 'karvy_stock_broking_vs_reliance_securities',
  KarvyStockBrokingVsSamco = 'karvy_stock_broking_vs_samco',
  KarvyStockBrokingVsSbicapSecurities = 'karvy_stock_broking_vs_sbicap_securities',
  KarvyStockBrokingVsSharekhan = 'karvy_stock_broking_vs_sharekhan',
  KarvyStockBrokingVsStoxkart = 'karvy_stock_broking_vs_stoxkart',
  KarvyStockBrokingVsTradeSmartOnline = 'karvy_stock_broking_vs_trade_smart_online',
  KarvyStockBrokingVsTradingbells = 'karvy_stock_broking_vs_tradingbells',
  KarvyStockBrokingVsUpstox = 'karvy_stock_broking_vs_upstox',
  KarvyStockBrokingVsVenturaSecurities = 'karvy_stock_broking_vs_ventura_securities',
  KarvyStockBrokingVsWisdomCapital = 'karvy_stock_broking_vs_wisdom_capital',
  KarvyStockBrokingVsYesSecurities = 'karvy_stock_broking_vs_yes_securities',
  KarvyStockBrokingVsZerodha = 'karvy_stock_broking_vs_zerodha',
  KotakSecuritiesVsMarwadiShares = 'kotak_securities_vs_marwadi_shares',
  KotakSecuritiesVsMasterTrust = 'kotak_securities_vs_master_trust',
  KotakSecuritiesVsMotilalOswal = 'kotak_securities_vs_motilal_oswal',
  KotakSecuritiesVsNirmalBang = 'kotak_securities_vs_nirmal_bang',
  KotakSecuritiesVsPaytmMoney = 'kotak_securities_vs_paytm_money',
  KotakSecuritiesVsProstocks = 'kotak_securities_vs_prostocks',
  KotakSecuritiesVsRelianceSecurities = 'kotak_securities_vs_reliance_securities',
  KotakSecuritiesVsSamco = 'kotak_securities_vs_samco',
  KotakSecuritiesVsSbicapSecurities = 'kotak_securities_vs_sbicap_securities',
  KotakSecuritiesVsSharekhan = 'kotak_securities_vs_sharekhan',
  KotakSecuritiesVsStoxkart = 'kotak_securities_vs_stoxkart',
  KotakSecuritiesVsTradeSmartOnline = 'kotak_securities_vs_trade_smart_online',
  KotakSecuritiesVsTradingbells = 'kotak_securities_vs_tradingbells',
  KotakSecuritiesVsUpstox = 'kotak_securities_vs_upstox',
  KotakSecuritiesVsVenturaSecurities = 'kotak_securities_vs_ventura_securities',
  KotakSecuritiesVsWisdomCapital = 'kotak_securities_vs_wisdom_capital',
  KotakSecuritiesVsYesSecurities = 'kotak_securities_vs_yes_securities',
  KotakSecuritiesVsZerodha = 'kotak_securities_vs_zerodha',
  MarwadiSharesVsMasterTrust = 'marwadi_shares_vs_master_trust',
  MarwadiSharesVsMotilalOswal = 'marwadi_shares_vs_motilal_oswal',
  MarwadiSharesVsNirmalBang = 'marwadi_shares_vs_nirmal_bang',
  MarwadiSharesVsPaytmMoney = 'marwadi_shares_vs_paytm_money',
  MarwadiSharesVsProstocks = 'marwadi_shares_vs_prostocks',
  MarwadiSharesVsRelianceSecurities = 'marwadi_shares_vs_reliance_securities',
  MarwadiSharesVsSamco = 'marwadi_shares_vs_samco',
  MarwadiSharesVsSbicapSecurities = 'marwadi_shares_vs_sbicap_securities',
  MarwadiSharesVsSharekhan = 'marwadi_shares_vs_sharekhan',
  MarwadiSharesVsStoxkart = 'marwadi_shares_vs_stoxkart',
  MarwadiSharesVsTradeSmartOnline = 'marwadi_shares_vs_trade_smart_online',
  MarwadiSharesVsTradingbells = 'marwadi_shares_vs_tradingbells',
  MarwadiSharesVsUpstox = 'marwadi_shares_vs_upstox',
  MarwadiSharesVsVenturaSecurities = 'marwadi_shares_vs_ventura_securities',
  MarwadiSharesVsWisdomCapital = 'marwadi_shares_vs_wisdom_capital',
  MarwadiSharesVsYesSecurities = 'marwadi_shares_vs_yes_securities',
  MarwadiSharesVsZerodha = 'marwadi_shares_vs_zerodha',
  MasterTrustVsMotilalOswal = 'master_trust_vs_motilal_oswal',
  MasterTrustVsNirmalBang = 'master_trust_vs_nirmal_bang',
  MasterTrustVsPaytmMoney = 'master_trust_vs_paytm_money',
  MasterTrustVsProstocks = 'master_trust_vs_prostocks',
  MasterTrustVsRelianceSecurities = 'master_trust_vs_reliance_securities',
  MasterTrustVsSamco = 'master_trust_vs_samco',
  MasterTrustVsSbicapSecurities = 'master_trust_vs_sbicap_securities',
  MasterTrustVsSharekhan = 'master_trust_vs_sharekhan',
  MasterTrustVsStoxkart = 'master_trust_vs_stoxkart',
  MasterTrustVsTradeSmartOnline = 'master_trust_vs_trade_smart_online',
  MasterTrustVsTradingbells = 'master_trust_vs_tradingbells',
  MasterTrustVsUpstox = 'master_trust_vs_upstox',
  MasterTrustVsVenturaSecurities = 'master_trust_vs_ventura_securities',
  MasterTrustVsWisdomCapital = 'master_trust_vs_wisdom_capital',
  MasterTrustVsYesSecurities = 'master_trust_vs_yes_securities',
  MasterTrustVsZerodha = 'master_trust_vs_zerodha',
  MotilalOswalVsNirmalBang = 'motilal_oswal_vs_nirmal_bang',
  MotilalOswalVsPaytmMoney = 'motilal_oswal_vs_paytm_money',
  MotilalOswalVsProstocks = 'motilal_oswal_vs_prostocks',
  MotilalOswalVsRelianceSecurities = 'motilal_oswal_vs_reliance_securities',
  MotilalOswalVsSamco = 'motilal_oswal_vs_samco',
  MotilalOswalVsSbicapSecurities = 'motilal_oswal_vs_sbicap_securities',
  MotilalOswalVsSharekhan = 'motilal_oswal_vs_sharekhan',
  MotilalOswalVsStoxkart = 'motilal_oswal_vs_stoxkart',
  MotilalOswalVsTradeSmartOnline = 'motilal_oswal_vs_trade_smart_online',
  MotilalOswalVsTradingbells = 'motilal_oswal_vs_tradingbells',
  MotilalOswalVsUpstox = 'motilal_oswal_vs_upstox',
  MotilalOswalVsVenturaSecurities = 'motilal_oswal_vs_ventura_securities',
  MotilalOswalVsWisdomCapital = 'motilal_oswal_vs_wisdom_capital',
  MotilalOswalVsYesSecurities = 'motilal_oswal_vs_yes_securities',
  MotilalOswalVsZerodha = 'motilal_oswal_vs_zerodha',
  NirmalBangVsPaytmMoney = 'nirmal_bang_vs_paytm_money',
  NirmalBangVsProstocks = 'nirmal_bang_vs_prostocks',
  NirmalBangVsRelianceSecurities = 'nirmal_bang_vs_reliance_securities',
  NirmalBangVsSamco = 'nirmal_bang_vs_samco',
  NirmalBangVsSbicapSecurities = 'nirmal_bang_vs_sbicap_securities',
  NirmalBangVsSharekhan = 'nirmal_bang_vs_sharekhan',
  NirmalBangVsStoxkart = 'nirmal_bang_vs_stoxkart',
  NirmalBangVsTradeSmartOnline = 'nirmal_bang_vs_trade_smart_online',
  NirmalBangVsTradingbells = 'nirmal_bang_vs_tradingbells',
  NirmalBangVsUpstox = 'nirmal_bang_vs_upstox',
  NirmalBangVsVenturaSecurities = 'nirmal_bang_vs_ventura_securities',
  NirmalBangVsWisdomCapital = 'nirmal_bang_vs_wisdom_capital',
  NirmalBangVsYesSecurities = 'nirmal_bang_vs_yes_securities',
  NirmalBangVsZerodha = 'nirmal_bang_vs_zerodha',
  PaisaVsAliceblue = 'paisa_vs_aliceblue',
  PaisaVsAnandRathi = 'paisa_vs_anand_rathi',
  PaisaVsAngelBroking = 'paisa_vs_angel_broking',
  PaisaVsAxisdirect = 'paisa_vs_axisdirect',
  PaisaVsEdelweiss = 'paisa_vs_edelweiss',
  PaisaVsFinvasia = 'paisa_vs_finvasia',
  PaisaVsFyers = 'paisa_vs_fyers',
  PaisaVsGroww = 'paisa_vs_groww',
  PaisaVsHdfcSecurities = 'paisa_vs_hdfc_securities',
  PaisaVsIciciDirect = 'paisa_vs_icici_direct',
  PaisaVsIdbiDirectCapital = 'paisa_vs_idbi_direct_capital',
  PaisaVsIiflSecurities = 'paisa_vs_iifl_securities',
  PaisaVsIndiabullsVenturesShubh = 'paisa_vs_indiabulls_ventures_shubh',
  PaisaVsInteractiveBrokers = 'paisa_vs_interactive_brokers',
  PaisaVsKarvyStockBroking = 'paisa_vs_karvy_stock_broking',
  PaisaVsKotakSecurities = 'paisa_vs_kotak_securities',
  PaisaVsMarwadiShares = 'paisa_vs_marwadi_shares',
  PaisaVsMasterTrust = 'paisa_vs_master_trust',
  PaisaVsMotilalOswal = 'paisa_vs_motilal_oswal',
  PaisaVsNirmalBang = 'paisa_vs_nirmal_bang',
  PaisaVsPaytmMoney = 'paisa_vs_paytm_money',
  PaisaVsProstocks = 'paisa_vs_prostocks',
  PaisaVsRelianceSecurities = 'paisa_vs_reliance_securities',
  PaisaVsSamco = 'paisa_vs_samco',
  PaisaVsSbicapSecurities = 'paisa_vs_sbicap_securities',
  PaisaVsSharekhan = 'paisa_vs_sharekhan',
  PaisaVsStoxkart = 'paisa_vs_stoxkart',
  PaisaVsTradeSmartOnline = 'paisa_vs_trade_smart_online',
  PaisaVsTradingbells = 'paisa_vs_tradingbells',
  PaisaVsUpstox = 'paisa_vs_upstox',
  PaisaVsVenturaSecurities = 'paisa_vs_ventura_securities',
  PaisaVsWisdomCapital = 'paisa_vs_wisdom_capital',
  PaisaVsYesSecurities = 'paisa_vs_yes_securities',
  PaisaVsZerodha = 'paisa_vs_zerodha',
  PaytmMoneyVsProstocks = 'paytm_money_vs_prostocks',
  PaytmMoneyVsRelianceSecurities = 'paytm_money_vs_reliance_securities',
  PaytmMoneyVsSamco = 'paytm_money_vs_samco',
  PaytmMoneyVsSbicapSecurities = 'paytm_money_vs_sbicap_securities',
  PaytmMoneyVsSharekhan = 'paytm_money_vs_sharekhan',
  PaytmMoneyVsStoxkart = 'paytm_money_vs_stoxkart',
  PaytmMoneyVsTradeSmartOnline = 'paytm_money_vs_trade_smart_online',
  PaytmMoneyVsTradingbells = 'paytm_money_vs_tradingbells',
  PaytmMoneyVsUpstox = 'paytm_money_vs_upstox',
  PaytmMoneyVsVenturaSecurities = 'paytm_money_vs_ventura_securities',
  PaytmMoneyVsWisdomCapital = 'paytm_money_vs_wisdom_capital',
  PaytmMoneyVsYesSecurities = 'paytm_money_vs_yes_securities',
  PaytmMoneyVsZerodha = 'paytm_money_vs_zerodha',
  ProstocksVsRelianceSecurities = 'prostocks_vs_reliance_securities',
  ProstocksVsSamco = 'prostocks_vs_samco',
  ProstocksVsSbicapSecurities = 'prostocks_vs_sbicap_securities',
  ProstocksVsSharekhan = 'prostocks_vs_sharekhan',
  ProstocksVsStoxkart = 'prostocks_vs_stoxkart',
  ProstocksVsTradeSmartOnline = 'prostocks_vs_trade_smart_online',
  ProstocksVsTradingbells = 'prostocks_vs_tradingbells',
  ProstocksVsUpstox = 'prostocks_vs_upstox',
  ProstocksVsVenturaSecurities = 'prostocks_vs_ventura_securities',
  ProstocksVsWisdomCapital = 'prostocks_vs_wisdom_capital',
  ProstocksVsYesSecurities = 'prostocks_vs_yes_securities',
  ProstocksVsZerodha = 'prostocks_vs_zerodha',
  RelianceSecuritiesVsSamco = 'reliance_securities_vs_samco',
  RelianceSecuritiesVsSbicapSecurities = 'reliance_securities_vs_sbicap_securities',
  RelianceSecuritiesVsSharekhan = 'reliance_securities_vs_sharekhan',
  RelianceSecuritiesVsStoxkart = 'reliance_securities_vs_stoxkart',
  RelianceSecuritiesVsTradeSmartOnline = 'reliance_securities_vs_trade_smart_online',
  RelianceSecuritiesVsTradingbells = 'reliance_securities_vs_tradingbells',
  RelianceSecuritiesVsUpstox = 'reliance_securities_vs_upstox',
  RelianceSecuritiesVsVenturaSecurities = 'reliance_securities_vs_ventura_securities',
  RelianceSecuritiesVsWisdomCapital = 'reliance_securities_vs_wisdom_capital',
  RelianceSecuritiesVsYesSecurities = 'reliance_securities_vs_yes_securities',
  RelianceSecuritiesVsZerodha = 'reliance_securities_vs_zerodha',
  SamcoVsSbicapSecurities = 'samco_vs_sbicap_securities',
  SamcoVsSharekhan = 'samco_vs_sharekhan',
  SamcoVsStoxkart = 'samco_vs_stoxkart',
  SamcoVsTradeSmartOnline = 'samco_vs_trade_smart_online',
  SamcoVsTradingbells = 'samco_vs_tradingbells',
  SamcoVsUpstox = 'samco_vs_upstox',
  SamcoVsVenturaSecurities = 'samco_vs_ventura_securities',
  SamcoVsWisdomCapital = 'samco_vs_wisdom_capital',
  SamcoVsYesSecurities = 'samco_vs_yes_securities',
  SamcoVsZerodha = 'samco_vs_zerodha',
  SbicapSecuritiesVsSharekhan = 'sbicap_securities_vs_sharekhan',
  SbicapSecuritiesVsStoxkart = 'sbicap_securities_vs_stoxkart',
  SbicapSecuritiesVsTradeSmartOnline = 'sbicap_securities_vs_trade_smart_online',
  SbicapSecuritiesVsTradingbells = 'sbicap_securities_vs_tradingbells',
  SbicapSecuritiesVsUpstox = 'sbicap_securities_vs_upstox',
  SbicapSecuritiesVsVenturaSecurities = 'sbicap_securities_vs_ventura_securities',
  SbicapSecuritiesVsWisdomCapital = 'sbicap_securities_vs_wisdom_capital',
  SbicapSecuritiesVsYesSecurities = 'sbicap_securities_vs_yes_securities',
  SbicapSecuritiesVsZerodha = 'sbicap_securities_vs_zerodha',
  SharekhanVsStoxkart = 'sharekhan_vs_stoxkart',
  SharekhanVsTradeSmartOnline = 'sharekhan_vs_trade_smart_online',
  SharekhanVsTradingbells = 'sharekhan_vs_tradingbells',
  SharekhanVsUpstox = 'sharekhan_vs_upstox',
  SharekhanVsVenturaSecurities = 'sharekhan_vs_ventura_securities',
  SharekhanVsWisdomCapital = 'sharekhan_vs_wisdom_capital',
  SharekhanVsYesSecurities = 'sharekhan_vs_yes_securities',
  SharekhanVsZerodha = 'sharekhan_vs_zerodha',
  StoxkartVsTradeSmartOnline = 'stoxkart_vs_trade_smart_online',
  StoxkartVsTradingbells = 'stoxkart_vs_tradingbells',
  StoxkartVsUpstox = 'stoxkart_vs_upstox',
  StoxkartVsVenturaSecurities = 'stoxkart_vs_ventura_securities',
  StoxkartVsWisdomCapital = 'stoxkart_vs_wisdom_capital',
  StoxkartVsYesSecurities = 'stoxkart_vs_yes_securities',
  StoxkartVsZerodha = 'stoxkart_vs_zerodha',
  TradeSmartOnlineVsTradingbells = 'trade_smart_online_vs_tradingbells',
  TradeSmartOnlineVsUpstox = 'trade_smart_online_vs_upstox',
  TradeSmartOnlineVsVenturaSecurities = 'trade_smart_online_vs_ventura_securities',
  TradeSmartOnlineVsWisdomCapital = 'trade_smart_online_vs_wisdom_capital',
  TradeSmartOnlineVsYesSecurities = 'trade_smart_online_vs_yes_securities',
  TradeSmartOnlineVsZerodha = 'trade_smart_online_vs_zerodha',
  TradingbellsVsUpstox = 'tradingbells_vs_upstox',
  TradingbellsVsVenturaSecurities = 'tradingbells_vs_ventura_securities',
  TradingbellsVsWisdomCapital = 'tradingbells_vs_wisdom_capital',
  TradingbellsVsYesSecurities = 'tradingbells_vs_yes_securities',
  TradingbellsVsZerodha = 'tradingbells_vs_zerodha',
  UpstoxVsVenturaSecurities = 'upstox_vs_ventura_securities',
  UpstoxVsWisdomCapital = 'upstox_vs_wisdom_capital',
  UpstoxVsYesSecurities = 'upstox_vs_yes_securities',
  UpstoxVsZerodha = 'upstox_vs_zerodha',
  VenturaSecuritiesVsWisdomCapital = 'ventura_securities_vs_wisdom_capital',
  VenturaSecuritiesVsYesSecurities = 'ventura_securities_vs_yes_securities',
  VenturaSecuritiesVsZerodha = 'ventura_securities_vs_zerodha',
  WisdomCapitalVsYesSecurities = 'wisdom_capital_vs_yes_securities',
  WisdomCapitalVsZerodha = 'wisdom_capital_vs_zerodha',
  YesSecuritiesVsZerodha = 'yes_securities_vs_zerodha'
}

export enum Enum_Article_Status {
  Deleted = 'deleted',
  Draft = 'draft',
  Published = 'published'
}

export enum Enum_Broker_Broker_Type {
  Discount = 'discount',
  FullService = 'full_service'
}

export enum Enum_Commentscommentreport_Reason {
  BadLanguage = 'BAD_LANGUAGE',
  Discrimination = 'DISCRIMINATION',
  Other = 'OTHER'
}

export enum Enum_Componentcountrylistcountries_Name {
  Australia = 'Australia',
  Canada = 'Canada',
  France = 'France',
  Germany = 'Germany',
  India = 'India',
  Switzerland = 'Switzerland',
  Uk = 'UK',
  Us = 'US'
}

export enum Enum_Componentprosconscontent_Value_Type {
  Con = 'con',
  Pro = 'pro'
}

export enum Enum_Ipo_Exchange {
  FinraBb = 'FINRA_BB',
  NasdaqCapital = 'NASDAQ_Capital',
  NasdaqGlobal = 'NASDAQ_Global',
  NasdaqGlobalSelect = 'NASDAQ_Global_Select',
  Nyse = 'NYSE',
  NyseMkt = 'NYSE_MKT',
  NA = 'N_A'
}

export enum Enum_Ipo_Status {
  Filed = 'filed',
  Priced = 'priced',
  Withdrawn = 'withdrawn'
}

export enum Enum_Userspermissionsuser_Country {
  Ca = 'ca',
  In = 'in',
  Us = 'us'
}

export type Exchange = {
  __typename?: 'Exchange'
  country?: Maybe<CountryEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  ipo?: Maybe<IpoEntityResponse>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  softwares?: Maybe<SoftwareRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ExchangeSoftwaresArgs = {
  filters?: InputMaybe<SoftwareFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ExchangeEntity = {
  __typename?: 'ExchangeEntity'
  attributes?: Maybe<Exchange>
  id?: Maybe<Scalars['Id']>
}

export type ExchangeEntityResponse = {
  __typename?: 'ExchangeEntityResponse'
  data?: Maybe<ExchangeEntity>
}

export type ExchangeEntityResponseCollection = {
  __typename?: 'ExchangeEntityResponseCollection'
  data: Array<ExchangeEntity>
  meta: ResponseCollectionMeta
}

export type ExchangeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ExchangeFiltersInput>>>
  country?: InputMaybe<CountryFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  ipo?: InputMaybe<IpoFiltersInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ExchangeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ExchangeFiltersInput>>>
  slug?: InputMaybe<StringFilterInput>
  softwares?: InputMaybe<SoftwareFiltersInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type ExchangeRelationResponseCollection = {
  __typename?: 'ExchangeRelationResponseCollection'
  data: Array<ExchangeEntity>
}

export type Feature = {
  __typename?: 'Feature'
  createdAt?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  parent?: Maybe<FeatureEntityResponse>
  short_description?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type FeatureEntity = {
  __typename?: 'FeatureEntity'
  attributes?: Maybe<Feature>
  id?: Maybe<Scalars['Id']>
}

export type FeatureEntityResponse = {
  __typename?: 'FeatureEntityResponse'
  data?: Maybe<FeatureEntity>
}

export type FeatureEntityResponseCollection = {
  __typename?: 'FeatureEntityResponseCollection'
  data: Array<FeatureEntity>
  meta: ResponseCollectionMeta
}

export type FeatureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FeatureFiltersInput>>>
  createdAt?: InputMaybe<DateTimeFilterInput>
  description?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<FeatureFiltersInput>
  or?: InputMaybe<Array<InputMaybe<FeatureFiltersInput>>>
  parent?: InputMaybe<FeatureFiltersInput>
  short_description?: InputMaybe<StringFilterInput>
  slug?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>
  caption?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type FinancialTerm = {
  __typename?: 'FinancialTerm'
  articles?: Maybe<ArticleRelationResponseCollection>
  categories?: Maybe<CategoryRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  image?: Maybe<ComponentUploadImageUploadImage>
  name?: Maybe<Scalars['String']>
  seo_meta?: Maybe<ComponentSeoSeo>
  slug?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type FinancialTermArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FinancialTermCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FinancialTermEntity = {
  __typename?: 'FinancialTermEntity'
  attributes?: Maybe<FinancialTerm>
  id?: Maybe<Scalars['Id']>
}

export type FinancialTermEntityResponse = {
  __typename?: 'FinancialTermEntityResponse'
  data?: Maybe<FinancialTermEntity>
}

export type FinancialTermEntityResponseCollection = {
  __typename?: 'FinancialTermEntityResponseCollection'
  data: Array<FinancialTermEntity>
  meta: ResponseCollectionMeta
}

export type FinancialTermFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FinancialTermFiltersInput>>>
  articles?: InputMaybe<ArticleFiltersInput>
  categories?: InputMaybe<CategoryFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  description?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  image?: InputMaybe<ComponentUploadImageUploadImageFiltersInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<FinancialTermFiltersInput>
  or?: InputMaybe<Array<InputMaybe<FinancialTermFiltersInput>>>
  seo_meta?: InputMaybe<ComponentSeoSeoFiltersInput>
  slug?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  contains?: InputMaybe<Scalars['Float']>
  containsi?: InputMaybe<Scalars['Float']>
  endsWith?: InputMaybe<Scalars['Float']>
  eq?: InputMaybe<Scalars['Float']>
  eqi?: InputMaybe<Scalars['Float']>
  gt?: InputMaybe<Scalars['Float']>
  gte?: InputMaybe<Scalars['Float']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  lt?: InputMaybe<Scalars['Float']>
  lte?: InputMaybe<Scalars['Float']>
  ne?: InputMaybe<Scalars['Float']>
  not?: InputMaybe<FloatFilterInput>
  notContains?: InputMaybe<Scalars['Float']>
  notContainsi?: InputMaybe<Scalars['Float']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  startsWith?: InputMaybe<Scalars['Float']>
}

export type GenericMorph =
  | Article
  | ArticleComment
  | ArticleView
  | Broker
  | Calculator
  | Category
  | City
  | CommentsComment
  | CommentsCommentReport
  | ComponentAssetdataAssets
  | ComponentChargesAccountOpeningCharges
  | ComponentChargesBrokerage
  | ComponentChargesConsolidatedCharges
  | ComponentCountryListCountries
  | ComponentExcerptFeatureExcerpt
  | ComponentExchangelistExchanges
  | ComponentIpoDatesTentativeDates
  | ComponentMarginsExposure
  | ComponentOrderTypesOrderTypes
  | ComponentProsConsContent
  | ComponentSeoSeo
  | ComponentTradingoptionsTradingOptions
  | ComponentUploadImageUploadImage
  | Country
  | Exchange
  | Feature
  | FinancialTerm
  | I18NLocale
  | Ipo
  | Listing
  | Page
  | Software
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser

export type I18NLocale = {
  __typename?: 'I18NLocale'
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity'
  attributes?: Maybe<I18NLocale>
  id?: Maybe<Scalars['Id']>
}

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse'
  data?: Maybe<I18NLocaleEntity>
}

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection'
  data: Array<I18NLocaleEntity>
  meta: ResponseCollectionMeta
}

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>
  code?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<I18NLocaleFiltersInput>
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  contains?: InputMaybe<Scalars['ID']>
  containsi?: InputMaybe<Scalars['ID']>
  endsWith?: InputMaybe<Scalars['ID']>
  eq?: InputMaybe<Scalars['ID']>
  eqi?: InputMaybe<Scalars['ID']>
  gt?: InputMaybe<Scalars['ID']>
  gte?: InputMaybe<Scalars['ID']>
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  lt?: InputMaybe<Scalars['ID']>
  lte?: InputMaybe<Scalars['ID']>
  ne?: InputMaybe<Scalars['ID']>
  not?: InputMaybe<IdFilterInput>
  notContains?: InputMaybe<Scalars['ID']>
  notContainsi?: InputMaybe<Scalars['ID']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  startsWith?: InputMaybe<Scalars['ID']>
}

export type IdentifyCommentAuthor = {
  id: Scalars['Id']
}

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  contains?: InputMaybe<Scalars['Int']>
  containsi?: InputMaybe<Scalars['Int']>
  endsWith?: InputMaybe<Scalars['Int']>
  eq?: InputMaybe<Scalars['Int']>
  eqi?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  gte?: InputMaybe<Scalars['Int']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  lt?: InputMaybe<Scalars['Int']>
  lte?: InputMaybe<Scalars['Int']>
  ne?: InputMaybe<Scalars['Int']>
  not?: InputMaybe<IntFilterInput>
  notContains?: InputMaybe<Scalars['Int']>
  notContainsi?: InputMaybe<Scalars['Int']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  startsWith?: InputMaybe<Scalars['Int']>
}

export type Ipo = {
  __typename?: 'Ipo'
  address?: Maybe<Scalars['String']>
  ceo?: Maybe<Scalars['String']>
  cik?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  date_received?: Maybe<Scalars['String']>
  deal_id: Scalars['String']
  description?: Maybe<Scalars['String']>
  employees?: Maybe<Scalars['String']>
  exchange?: Maybe<Enum_Ipo_Exchange>
  exchanges?: Maybe<ExchangeRelationResponseCollection>
  face_value?: Maybe<Scalars['String']>
  filed_date?: Maybe<Scalars['Date']>
  filing_link?: Maybe<Scalars['String']>
  fiscal_year_end?: Maybe<Scalars['String']>
  form_type?: Maybe<Scalars['String']>
  ipo_assets?: Maybe<Array<Maybe<ComponentAssetdataAssets>>>
  ipo_end_date?: Maybe<Scalars['Date']>
  ipo_price?: Maybe<Scalars['String']>
  ipo_start_date?: Maybe<Scalars['Date']>
  issue_size?: Maybe<Scalars['String']>
  issue_type?: Maybe<Scalars['String']>
  lockup_days?: Maybe<Scalars['String']>
  lockup_expiration?: Maybe<Scalars['String']>
  market_lot?: Maybe<Scalars['String']>
  min_order_size?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  net_income?: Maybe<Scalars['String']>
  objective?: Maybe<Scalars['String']>
  offer_amount?: Maybe<Scalars['String']>
  offering_expense?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  plugin_comments: Array<Maybe<CommentNested>>
  priced_date?: Maybe<Scalars['Date']>
  promoter_post_holding?: Maybe<Scalars['String']>
  promoter_pre_holding?: Maybe<Scalars['String']>
  promoters?: Maybe<Scalars['String']>
  quiet_period_expiration?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
  share_price?: Maybe<Scalars['String']>
  shareholder_offered?: Maybe<Scalars['String']>
  shares_offered?: Maybe<Scalars['String']>
  shares_outstanding?: Maybe<Scalars['String']>
  shares_over_alloted?: Maybe<Scalars['String']>
  short_description?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  state_inc?: Maybe<Scalars['String']>
  status?: Maybe<Enum_Ipo_Status>
  stock_holders_equity?: Maybe<Scalars['String']>
  strengths?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  tentative_dates?: Maybe<ComponentIpoDatesTentativeDates>
  total_assets?: Maybe<Scalars['String']>
  total_liabilities?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  website?: Maybe<Scalars['String']>
  withdrawn_date?: Maybe<Scalars['Date']>
}

export type IpoExchangesArgs = {
  filters?: InputMaybe<ExchangeFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type IpoIpo_AssetsArgs = {
  filters?: InputMaybe<ComponentAssetdataAssetsFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type IpoPlugin_CommentsArgs = {
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type IpoEntity = {
  __typename?: 'IpoEntity'
  attributes?: Maybe<Ipo>
  id?: Maybe<Scalars['Id']>
}

export type IpoEntityResponse = {
  __typename?: 'IpoEntityResponse'
  data?: Maybe<IpoEntity>
}

export type IpoEntityResponseCollection = {
  __typename?: 'IpoEntityResponseCollection'
  data: Array<IpoEntity>
  meta: ResponseCollectionMeta
}

export type IpoFiltersInput = {
  address?: InputMaybe<StringFilterInput>
  and?: InputMaybe<Array<InputMaybe<IpoFiltersInput>>>
  ceo?: InputMaybe<StringFilterInput>
  cik?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  date_received?: InputMaybe<StringFilterInput>
  deal_id?: InputMaybe<StringFilterInput>
  description?: InputMaybe<StringFilterInput>
  employees?: InputMaybe<StringFilterInput>
  exchange?: InputMaybe<StringFilterInput>
  exchanges?: InputMaybe<ExchangeFiltersInput>
  face_value?: InputMaybe<StringFilterInput>
  filed_date?: InputMaybe<DateFilterInput>
  filing_link?: InputMaybe<StringFilterInput>
  fiscal_year_end?: InputMaybe<StringFilterInput>
  form_type?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  ipo_assets?: InputMaybe<ComponentAssetdataAssetsFiltersInput>
  ipo_end_date?: InputMaybe<DateFilterInput>
  ipo_price?: InputMaybe<StringFilterInput>
  ipo_start_date?: InputMaybe<DateFilterInput>
  issue_size?: InputMaybe<StringFilterInput>
  issue_type?: InputMaybe<StringFilterInput>
  lockup_days?: InputMaybe<StringFilterInput>
  lockup_expiration?: InputMaybe<StringFilterInput>
  market_lot?: InputMaybe<StringFilterInput>
  min_order_size?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  net_income?: InputMaybe<StringFilterInput>
  not?: InputMaybe<IpoFiltersInput>
  objective?: InputMaybe<StringFilterInput>
  offer_amount?: InputMaybe<StringFilterInput>
  offering_expense?: InputMaybe<StringFilterInput>
  or?: InputMaybe<Array<InputMaybe<IpoFiltersInput>>>
  phone?: InputMaybe<StringFilterInput>
  priced_date?: InputMaybe<DateFilterInput>
  promoter_post_holding?: InputMaybe<StringFilterInput>
  promoter_pre_holding?: InputMaybe<StringFilterInput>
  promoters?: InputMaybe<StringFilterInput>
  quiet_period_expiration?: InputMaybe<StringFilterInput>
  revenue?: InputMaybe<StringFilterInput>
  share_price?: InputMaybe<StringFilterInput>
  shareholder_offered?: InputMaybe<StringFilterInput>
  shares_offered?: InputMaybe<StringFilterInput>
  shares_outstanding?: InputMaybe<StringFilterInput>
  shares_over_alloted?: InputMaybe<StringFilterInput>
  short_description?: InputMaybe<StringFilterInput>
  slug?: InputMaybe<StringFilterInput>
  state_inc?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  stock_holders_equity?: InputMaybe<StringFilterInput>
  strengths?: InputMaybe<StringFilterInput>
  symbol?: InputMaybe<StringFilterInput>
  tentative_dates?: InputMaybe<ComponentIpoDatesTentativeDatesFiltersInput>
  total_assets?: InputMaybe<StringFilterInput>
  total_liabilities?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  website?: InputMaybe<StringFilterInput>
  withdrawn_date?: InputMaybe<DateFilterInput>
}

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  contains?: InputMaybe<Scalars['JSON']>
  containsi?: InputMaybe<Scalars['JSON']>
  endsWith?: InputMaybe<Scalars['JSON']>
  eq?: InputMaybe<Scalars['JSON']>
  eqi?: InputMaybe<Scalars['JSON']>
  gt?: InputMaybe<Scalars['JSON']>
  gte?: InputMaybe<Scalars['JSON']>
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  lt?: InputMaybe<Scalars['JSON']>
  lte?: InputMaybe<Scalars['JSON']>
  ne?: InputMaybe<Scalars['JSON']>
  not?: InputMaybe<JsonFilterInput>
  notContains?: InputMaybe<Scalars['JSON']>
  notContainsi?: InputMaybe<Scalars['JSON']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
  startsWith?: InputMaybe<Scalars['JSON']>
}

export type Listing = {
  __typename?: 'Listing'
  address?: Maybe<Scalars['String']>
  associated_broker?: Maybe<Scalars['String']>
  broker?: Maybe<BrokerEntityResponse>
  city?: Maybe<CityEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  tags?: Maybe<Scalars['JSON']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ListingEntity = {
  __typename?: 'ListingEntity'
  attributes?: Maybe<Listing>
  id?: Maybe<Scalars['Id']>
}

export type ListingEntityResponse = {
  __typename?: 'ListingEntityResponse'
  data?: Maybe<ListingEntity>
}

export type ListingEntityResponseCollection = {
  __typename?: 'ListingEntityResponseCollection'
  data: Array<ListingEntity>
  meta: ResponseCollectionMeta
}

export type ListingFiltersInput = {
  address?: InputMaybe<StringFilterInput>
  and?: InputMaybe<Array<InputMaybe<ListingFiltersInput>>>
  associated_broker?: InputMaybe<StringFilterInput>
  broker?: InputMaybe<BrokerFiltersInput>
  city?: InputMaybe<CityFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<ListingFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ListingFiltersInput>>>
  tags?: InputMaybe<JsonFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type ListingRelationResponseCollection = {
  __typename?: 'ListingRelationResponseCollection'
  data: Array<ListingEntity>
}

export type LongFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>
  contains?: InputMaybe<Scalars['Long']>
  containsi?: InputMaybe<Scalars['Long']>
  endsWith?: InputMaybe<Scalars['Long']>
  eq?: InputMaybe<Scalars['Long']>
  eqi?: InputMaybe<Scalars['Long']>
  gt?: InputMaybe<Scalars['Long']>
  gte?: InputMaybe<Scalars['Long']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>
  lt?: InputMaybe<Scalars['Long']>
  lte?: InputMaybe<Scalars['Long']>
  ne?: InputMaybe<Scalars['Long']>
  not?: InputMaybe<LongFilterInput>
  notContains?: InputMaybe<Scalars['Long']>
  notContainsi?: InputMaybe<Scalars['Long']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>
  startsWith?: InputMaybe<Scalars['Long']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>
  createAbuseReport: Report
  createArticle?: Maybe<ArticleEntityResponse>
  createArticleComment?: Maybe<ArticleCommentEntityResponse>
  createArticleViews: Scalars['Boolean']
  createComment: CommentSingle
  createPage?: Maybe<PageEntityResponse>
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse
  deleteArticle?: Maybe<ArticleEntityResponse>
  deleteArticleComment?: Maybe<ArticleCommentEntityResponse>
  deletePage?: Maybe<PageEntityResponse>
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>
  login: UsersPermissionsLoginPayload
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>
  oneTapLogin: Scalars['JSON']
  /** Register a user */
  register: UsersPermissionsLoginPayload
  removeComment: CommentSingle
  removeFile?: Maybe<UploadFileEntityResponse>
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>
  updateArticle?: Maybe<ArticleEntityResponse>
  updateArticleComment?: Maybe<ArticleCommentEntityResponse>
  updateComment: CommentSingle
  updateFileInfo: UploadFileEntityResponse
  updatePage?: Maybe<PageEntityResponse>
  updateUser: Scalars['Boolean']
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse
  upload: UploadFileEntityResponse
}

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}

export type MutationCreateAbuseReportArgs = {
  input: CreateReport
}

export type MutationCreateArticleArgs = {
  data: ArticleInput
}

export type MutationCreateArticleCommentArgs = {
  data: ArticleCommentInput
}

export type MutationCreateArticleViewsArgs = {
  slug?: InputMaybe<Scalars['String']>
}

export type MutationCreateCommentArgs = {
  input: CreateComment
}

export type MutationCreatePageArgs = {
  data: PageInput
}

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput
}

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput
}

export type MutationDeleteArticleArgs = {
  id: Scalars['ID']
}

export type MutationDeleteArticleCommentArgs = {
  id: Scalars['ID']
}

export type MutationDeletePageArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']
}

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']
}

export type MutationForgotPasswordArgs = {
  email: Scalars['String']
}

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput
}

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>
  files: Array<InputMaybe<Scalars['Upload']>>
  ref?: InputMaybe<Scalars['String']>
  refId?: InputMaybe<Scalars['ID']>
}

export type MutationOneTapLoginArgs = {
  country?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
}

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput
}

export type MutationRemoveCommentArgs = {
  input: RemoveComment
}

export type MutationRemoveFileArgs = {
  id: Scalars['ID']
}

export type MutationResetPasswordArgs = {
  code: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}

export type MutationUpdateArticleArgs = {
  data: ArticleInput
  id: Scalars['ID']
}

export type MutationUpdateArticleCommentArgs = {
  data: ArticleCommentInput
  id: Scalars['ID']
}

export type MutationUpdateCommentArgs = {
  input: UpdateComment
}

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']
  info?: InputMaybe<FileInfoInput>
}

export type MutationUpdatePageArgs = {
  data: PageInput
  id: Scalars['ID']
}

export type MutationUpdateUserArgs = {
  country?: InputMaybe<Scalars['String']>
}

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput
  id: Scalars['ID']
}

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput
  id: Scalars['ID']
}

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>
  file: Scalars['Upload']
  info?: InputMaybe<FileInfoInput>
  ref?: InputMaybe<Scalars['String']>
  refId?: InputMaybe<Scalars['ID']>
}

export type Page = {
  __typename?: 'Page'
  createdAt?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['DateTime']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type PageEntity = {
  __typename?: 'PageEntity'
  attributes?: Maybe<Page>
  id?: Maybe<Scalars['Id']>
}

export type PageEntityResponse = {
  __typename?: 'PageEntityResponse'
  data?: Maybe<PageEntity>
}

export type PageEntityResponseCollection = {
  __typename?: 'PageEntityResponseCollection'
  data: Array<PageEntity>
  meta: ResponseCollectionMeta
}

export type PageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>
  createdAt?: InputMaybe<DateTimeFilterInput>
  description?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  not?: InputMaybe<PageFiltersInput>
  or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>
  publishedAt?: InputMaybe<DateTimeFilterInput>
  slug?: InputMaybe<StringFilterInput>
  title?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type PageInput = {
  description?: InputMaybe<Scalars['String']>
  publishedAt?: InputMaybe<Scalars['DateTime']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type Pagination = {
  __typename?: 'Pagination'
  page: Scalars['Int']
  pageCount: Scalars['Int']
  pageSize: Scalars['Int']
  total: Scalars['Int']
}

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  start?: InputMaybe<Scalars['Int']>
}

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query'
  article?: Maybe<ArticleEntityResponse>
  articleComment?: Maybe<ArticleCommentEntityResponse>
  articleComments?: Maybe<ArticleCommentEntityResponseCollection>
  articleSearchByKeyword: Array<Maybe<Article>>
  articleView?: Maybe<ArticleViewEntityResponse>
  articleViews?: Maybe<ArticleViewEntityResponseCollection>
  articles?: Maybe<ArticleEntityResponseCollection>
  articlesCount: Scalars['Int']
  broker?: Maybe<BrokerEntityResponse>
  brokers?: Maybe<BrokerEntityResponseCollection>
  calculator?: Maybe<CalculatorEntityResponse>
  calculators?: Maybe<CalculatorEntityResponseCollection>
  categories?: Maybe<CategoryEntityResponseCollection>
  category?: Maybe<CategoryEntityResponse>
  cities?: Maybe<CityEntityResponseCollection>
  city?: Maybe<CityEntityResponse>
  countries?: Maybe<CountryEntityResponseCollection>
  country?: Maybe<CountryEntityResponse>
  exchange?: Maybe<ExchangeEntityResponse>
  exchanges?: Maybe<ExchangeEntityResponseCollection>
  feature?: Maybe<FeatureEntityResponse>
  features?: Maybe<FeatureEntityResponseCollection>
  financialTerm?: Maybe<FinancialTermEntityResponse>
  financialTerms?: Maybe<FinancialTermEntityResponseCollection>
  findAllFlat?: Maybe<ResponseFindAll>
  findAllInHierarchy: Array<Maybe<CommentNested>>
  i18NLocale?: Maybe<I18NLocaleEntityResponse>
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>
  ipo?: Maybe<IpoEntityResponse>
  ipos?: Maybe<IpoEntityResponseCollection>
  listing?: Maybe<ListingEntityResponse>
  listings?: Maybe<ListingEntityResponseCollection>
  me?: Maybe<UsersPermissionsMe>
  page?: Maybe<PageEntityResponse>
  pages?: Maybe<PageEntityResponseCollection>
  pluginComments: Array<Maybe<CommentSingle>>
  popularQuestions: Array<Maybe<Article>>
  software?: Maybe<SoftwareEntityResponse>
  softwares?: Maybe<SoftwareEntityResponseCollection>
  uploadFile?: Maybe<UploadFileEntityResponse>
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>
  uploadFolder?: Maybe<UploadFolderEntityResponse>
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>
}

export type QueryArticleArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryArticleCommentArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryArticleCommentsArgs = {
  filters?: InputMaybe<ArticleCommentFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryArticleSearchByKeywordArgs = {
  limit?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Scalars['JSON']>
}

export type QueryArticleViewArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryArticleViewsArgs = {
  filters?: InputMaybe<ArticleViewFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  publicationState?: InputMaybe<PublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryArticlesCountArgs = {
  where?: InputMaybe<Scalars['JSON']>
}

export type QueryBrokerArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryBrokersArgs = {
  filters?: InputMaybe<BrokerFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryCalculatorArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryCalculatorsArgs = {
  filters?: InputMaybe<CalculatorFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryCitiesArgs = {
  filters?: InputMaybe<CityFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryCityArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryCountriesArgs = {
  filters?: InputMaybe<CountryFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryCountryArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryExchangeArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryExchangesArgs = {
  filters?: InputMaybe<ExchangeFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryFeatureArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryFeaturesArgs = {
  filters?: InputMaybe<FeatureFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryFinancialTermArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryFinancialTermsArgs = {
  filters?: InputMaybe<FinancialTermFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryFindAllFlatArgs = {
  filters?: InputMaybe<CommentFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  relation: Scalars['String']
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryFindAllInHierarchyArgs = {
  filters?: InputMaybe<CommentFiltersInput>
  relation: Scalars['String']
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryIpoArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryIposArgs = {
  filters?: InputMaybe<IpoFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryListingArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryListingsArgs = {
  filters?: InputMaybe<ListingFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryPageArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryPagesArgs = {
  filters?: InputMaybe<PageFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  publicationState?: InputMaybe<PublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryPluginCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Scalars['JSON']>
}

export type QueryPopularQuestionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Scalars['JSON']>
}

export type QuerySoftwareArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QuerySoftwaresArgs = {
  filters?: InputMaybe<SoftwareFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type RemoveComment = {
  author?: InputMaybe<IdentifyCommentAuthor>
  id?: InputMaybe<Scalars['Id']>
  relation: Scalars['String']
}

export type Report = {
  __typename?: 'Report'
  content: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Id']>
  reason?: Maybe<ReportReason>
  related?: Maybe<CommentSingle>
  updatedAt?: Maybe<Scalars['String']>
}

/** Reason of abuse report */
export enum ReportReason {
  BadLanguage = 'BAD_LANGUAGE',
  Discrimination = 'DISCRIMINATION',
  MyCustomReason = 'MY_CUSTOM_REASON',
  Other = 'OTHER'
}

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta'
  pagination: Pagination
}

export type ResponseFindAll = {
  __typename?: 'ResponseFindAll'
  data?: Maybe<Array<Maybe<CommentSingle>>>
  meta?: Maybe<ResponseMeta>
}

export type ResponseMeta = {
  __typename?: 'ResponseMeta'
  pagination?: Maybe<ResponsePagination>
}

export type ResponsePagination = {
  __typename?: 'ResponsePagination'
  limit?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  pageCount?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
}

export type Software = {
  __typename?: 'Software'
  android_url?: Maybe<Scalars['String']>
  app_text?: Maybe<Scalars['String']>
  articles?: Maybe<ArticleRelationResponseCollection>
  broker?: Maybe<BrokerEntityResponse>
  categories?: Maybe<CategoryRelationResponseCollection>
  countries?: Maybe<CountryRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  details: Scalars['String']
  exchanges?: Maybe<ExchangeRelationResponseCollection>
  image?: Maybe<ComponentUploadImageUploadImage>
  ios_url?: Maybe<Scalars['String']>
  is_app?: Maybe<Scalars['Boolean']>
  logo: UploadFileEntityResponse
  mac_url?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  relatedSoftwares: Array<Maybe<Software>>
  seo_meta?: Maybe<ComponentSeoSeo>
  slug: Scalars['String']
  softwareRelatedArticles: Array<Maybe<Article>>
  tags?: Maybe<Scalars['JSON']>
  updatedAt?: Maybe<Scalars['DateTime']>
  windows_url?: Maybe<Scalars['String']>
}

export type SoftwareArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SoftwareCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SoftwareCountriesArgs = {
  filters?: InputMaybe<CountryFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SoftwareExchangesArgs = {
  filters?: InputMaybe<ExchangeFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SoftwareRelatedSoftwaresArgs = {
  slug?: InputMaybe<Scalars['String']>
}

export type SoftwareSoftwareRelatedArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type SoftwareEntity = {
  __typename?: 'SoftwareEntity'
  attributes?: Maybe<Software>
  id?: Maybe<Scalars['Id']>
}

export type SoftwareEntityResponse = {
  __typename?: 'SoftwareEntityResponse'
  data?: Maybe<SoftwareEntity>
}

export type SoftwareEntityResponseCollection = {
  __typename?: 'SoftwareEntityResponseCollection'
  data: Array<SoftwareEntity>
  meta: ResponseCollectionMeta
}

export type SoftwareFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SoftwareFiltersInput>>>
  android_url?: InputMaybe<StringFilterInput>
  app_text?: InputMaybe<StringFilterInput>
  articles?: InputMaybe<ArticleFiltersInput>
  broker?: InputMaybe<BrokerFiltersInput>
  categories?: InputMaybe<CategoryFiltersInput>
  countries?: InputMaybe<CountryFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  details?: InputMaybe<StringFilterInput>
  exchanges?: InputMaybe<ExchangeFiltersInput>
  id?: InputMaybe<IdFilterInput>
  image?: InputMaybe<ComponentUploadImageUploadImageFiltersInput>
  ios_url?: InputMaybe<StringFilterInput>
  is_app?: InputMaybe<BooleanFilterInput>
  mac_url?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<SoftwareFiltersInput>
  or?: InputMaybe<Array<InputMaybe<SoftwareFiltersInput>>>
  seo_meta?: InputMaybe<ComponentSeoSeoFiltersInput>
  slug?: InputMaybe<StringFilterInput>
  tags?: InputMaybe<JsonFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  windows_url?: InputMaybe<StringFilterInput>
}

export type SoftwareRelationResponseCollection = {
  __typename?: 'SoftwareRelationResponseCollection'
  data: Array<SoftwareEntity>
}

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contains?: InputMaybe<Scalars['String']>
  containsi?: InputMaybe<Scalars['String']>
  endsWith?: InputMaybe<Scalars['String']>
  eq?: InputMaybe<Scalars['String']>
  eqi?: InputMaybe<Scalars['String']>
  gt?: InputMaybe<Scalars['String']>
  gte?: InputMaybe<Scalars['String']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  lt?: InputMaybe<Scalars['String']>
  lte?: InputMaybe<Scalars['String']>
  ne?: InputMaybe<Scalars['String']>
  not?: InputMaybe<StringFilterInput>
  notContains?: InputMaybe<Scalars['String']>
  notContainsi?: InputMaybe<Scalars['String']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  notNull?: InputMaybe<Scalars['Boolean']>
  null?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  startsWith?: InputMaybe<Scalars['String']>
}

export type UpdateComment = {
  author?: InputMaybe<IdentifyCommentAuthor>
  content: Scalars['String']
  id?: InputMaybe<Scalars['Id']>
  relation: Scalars['String']
}

export type UploadFile = {
  __typename?: 'UploadFile'
  alternativeText?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  ext?: Maybe<Scalars['String']>
  formats?: Maybe<Scalars['JSON']>
  hash: Scalars['String']
  height?: Maybe<Scalars['Int']>
  mime: Scalars['String']
  name: Scalars['String']
  previewUrl?: Maybe<Scalars['String']>
  provider: Scalars['String']
  provider_metadata?: Maybe<Scalars['JSON']>
  related?: Maybe<Array<Maybe<GenericMorph>>>
  size: Scalars['Float']
  updatedAt?: Maybe<Scalars['DateTime']>
  url: Scalars['String']
  width?: Maybe<Scalars['Int']>
}

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity'
  attributes?: Maybe<UploadFile>
  id?: Maybe<Scalars['Id']>
}

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse'
  data?: Maybe<UploadFileEntity>
}

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection'
  data: Array<UploadFileEntity>
  meta: ResponseCollectionMeta
}

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>
  caption?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  ext?: InputMaybe<StringFilterInput>
  folder?: InputMaybe<UploadFolderFiltersInput>
  folderPath?: InputMaybe<StringFilterInput>
  formats?: InputMaybe<JsonFilterInput>
  hash?: InputMaybe<StringFilterInput>
  height?: InputMaybe<IntFilterInput>
  id?: InputMaybe<IdFilterInput>
  mime?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<UploadFileFiltersInput>
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>
  previewUrl?: InputMaybe<StringFilterInput>
  provider?: InputMaybe<StringFilterInput>
  provider_metadata?: InputMaybe<JsonFilterInput>
  size?: InputMaybe<FloatFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  url?: InputMaybe<StringFilterInput>
  width?: InputMaybe<IntFilterInput>
}

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection'
  data: Array<UploadFileEntity>
}

export type UploadFolder = {
  __typename?: 'UploadFolder'
  children?: Maybe<UploadFolderRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']>
  files?: Maybe<UploadFileRelationResponseCollection>
  name: Scalars['String']
  parent?: Maybe<UploadFolderEntityResponse>
  path: Scalars['String']
  pathId: Scalars['Int']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity'
  attributes?: Maybe<UploadFolder>
  id?: Maybe<Scalars['Id']>
}

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse'
  data?: Maybe<UploadFolderEntity>
}

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection'
  data: Array<UploadFolderEntity>
  meta: ResponseCollectionMeta
}

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>
  children?: InputMaybe<UploadFolderFiltersInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  files?: InputMaybe<UploadFileFiltersInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<UploadFolderFiltersInput>
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>
  parent?: InputMaybe<UploadFolderFiltersInput>
  path?: InputMaybe<StringFilterInput>
  pathId?: InputMaybe<IntFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection'
  data: Array<UploadFolderEntity>
}

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload'
  ok: Scalars['Boolean']
}

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload'
  ok: Scalars['Boolean']
}

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']
  password: Scalars['String']
  provider?: Scalars['String']
}

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload'
  jwt?: Maybe<Scalars['String']>
  user: UsersPermissionsMe
}

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe'
  blocked?: Maybe<Scalars['Boolean']>
  confirmed?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  id: Scalars['Id']
  role?: Maybe<UsersPermissionsMeRole>
  username: Scalars['String']
}

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole'
  description?: Maybe<Scalars['String']>
  id: Scalars['Id']
  name: Scalars['String']
  type?: Maybe<Scalars['String']>
}

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload'
  ok: Scalars['Boolean']
}

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission'
  action: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  role?: Maybe<UsersPermissionsRoleEntityResponse>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity'
  attributes?: Maybe<UsersPermissionsPermission>
  id?: Maybe<Scalars['Id']>
}

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>
  createdAt?: InputMaybe<DateTimeFilterInput>
  id?: InputMaybe<IdFilterInput>
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection'
  data: Array<UsersPermissionsPermissionEntity>
}

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']
  password: Scalars['String']
  username: Scalars['String']
}

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole'
  createdAt?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  name: Scalars['String']
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>
}

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity'
  attributes?: Maybe<UsersPermissionsRole>
  id?: Maybe<Scalars['Id']>
}

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse'
  data?: Maybe<UsersPermissionsRoleEntity>
}

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection'
  data: Array<UsersPermissionsRoleEntity>
  meta: ResponseCollectionMeta
}

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>
  createdAt?: InputMaybe<DateTimeFilterInput>
  description?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>
  type?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  users?: InputMaybe<UsersPermissionsUserFiltersInput>
}

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  permissions?: InputMaybe<Array<InputMaybe<Scalars['Id']>>>
  type?: InputMaybe<Scalars['String']>
  users?: InputMaybe<Array<InputMaybe<Scalars['Id']>>>
}

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload'
  ok: Scalars['Boolean']
}

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser'
  article?: Maybe<ArticleEntityResponse>
  article_comments?: Maybe<ArticleCommentRelationResponseCollection>
  avatar?: Maybe<Scalars['String']>
  blocked?: Maybe<Scalars['Boolean']>
  confirmed?: Maybe<Scalars['Boolean']>
  country?: Maybe<Enum_Userspermissionsuser_Country>
  createdAt?: Maybe<Scalars['DateTime']>
  email: Scalars['String']
  full_name?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  role?: Maybe<UsersPermissionsRoleEntityResponse>
  short_description?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  user_ip?: Maybe<Scalars['String']>
  username: Scalars['String']
  website?: Maybe<Scalars['String']>
}

export type UsersPermissionsUserArticle_CommentsArgs = {
  filters?: InputMaybe<ArticleCommentFiltersInput>
  pagination?: InputMaybe<PaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity'
  attributes?: Maybe<UsersPermissionsUser>
  id?: Maybe<Scalars['Id']>
}

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse'
  data?: Maybe<UsersPermissionsUserEntity>
}

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection'
  data: Array<UsersPermissionsUserEntity>
  meta: ResponseCollectionMeta
}

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>
  article?: InputMaybe<ArticleFiltersInput>
  article_comments?: InputMaybe<ArticleCommentFiltersInput>
  avatar?: InputMaybe<StringFilterInput>
  blocked?: InputMaybe<BooleanFilterInput>
  confirmationToken?: InputMaybe<StringFilterInput>
  confirmed?: InputMaybe<BooleanFilterInput>
  country?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<DateTimeFilterInput>
  email?: InputMaybe<StringFilterInput>
  full_name?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  not?: InputMaybe<UsersPermissionsUserFiltersInput>
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>
  password?: InputMaybe<StringFilterInput>
  provider?: InputMaybe<StringFilterInput>
  resetPasswordToken?: InputMaybe<StringFilterInput>
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>
  short_description?: InputMaybe<StringFilterInput>
  twitter?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<DateTimeFilterInput>
  user_ip?: InputMaybe<StringFilterInput>
  username?: InputMaybe<StringFilterInput>
  website?: InputMaybe<StringFilterInput>
}

export type UsersPermissionsUserInput = {
  article?: InputMaybe<Scalars['Id']>
  article_comments?: InputMaybe<Array<InputMaybe<Scalars['Id']>>>
  avatar?: InputMaybe<Scalars['String']>
  blocked?: InputMaybe<Scalars['Boolean']>
  confirmationToken?: InputMaybe<Scalars['String']>
  confirmed?: InputMaybe<Scalars['Boolean']>
  country?: InputMaybe<Enum_Userspermissionsuser_Country>
  email?: InputMaybe<Scalars['String']>
  full_name?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  provider?: InputMaybe<Scalars['String']>
  resetPasswordToken?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['Id']>
  short_description?: InputMaybe<Scalars['String']>
  twitter?: InputMaybe<Scalars['String']>
  user_ip?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection'
  data: Array<UsersPermissionsUserEntity>
}

export type ArticleCommentsFragment = {
  __typename?: 'CommentNested'
  id?: any | null
  content: string
  createdAt?: string | null
  updatedAt?: string | null
  approvalStatus?: string | null
  children?: Array<{
    __typename?: 'CommentNested'
    id?: any | null
    content: string
    createdAt?: string | null
    updatedAt?: string | null
    approvalStatus?: string | null
    children?: Array<{
      __typename?: 'CommentNested'
      id?: any | null
      content: string
      approvalStatus?: string | null
      author?: {
        __typename?: 'CommentAuthor'
        name: string
        avatar?: string | null
      } | null
      children?: Array<{
        __typename?: 'CommentNested'
        id?: any | null
        content: string
        approvalStatus?: string | null
        author?: {
          __typename?: 'CommentAuthor'
          name: string
          avatar?: string | null
        } | null
        children?: Array<{
          __typename?: 'CommentNested'
          id?: any | null
          content: string
          approvalStatus?: string | null
          author?: {
            __typename?: 'CommentAuthor'
            name: string
            avatar?: string | null
          } | null
        } | null> | null
      } | null> | null
    } | null> | null
    author?: {
      __typename?: 'CommentAuthor'
      id?: any | null
      name: string
      avatar?: string | null
    } | null
  } | null> | null
  author?: {
    __typename?: 'CommentAuthor'
    id?: any | null
    name: string
    avatar?: string | null
  } | null
}

export type AllBrokersFragment = {
  __typename?: 'BrokerEntityResponseCollection'
  data: Array<{
    __typename?: 'BrokerEntity'
    attributes?: {
      __typename?: 'Broker'
      name?: string | null
      slug?: string | null
      broker_type?: Enum_Broker_Broker_Type | null
      country: {
        __typename?: 'ComponentCountryListCountries'
        name?: Enum_Componentcountrylistcountries_Name | null
      }
    } | null
  }>
}

export type BaseBrokerInfoFragment = {
  __typename?: 'BrokerEntityResponseCollection'
  data: Array<{
    __typename?: 'BrokerEntity'
    attributes?: {
      __typename?: 'Broker'
      name?: string | null
      slug?: string | null
      broker_type?: Enum_Broker_Broker_Type | null
    } | null
  }>
}

export type BrokerInfoFragment = {
  __typename?: 'Broker'
  name?: string | null
  slug?: string | null
  details: string
  broker_type?: Enum_Broker_Broker_Type | null
  account_opening_process?: string | null
  demat?: string | null
  trading_account?: string | null
  commodity_trading?: string | null
  currency_trading?: string | null
  exposure_details?: string | null
  exchanges?: {
    __typename?: 'ComponentExchangelistExchanges'
    bse?: boolean | null
    nse?: boolean | null
    mcx?: boolean | null
    ncdex?: boolean | null
  } | null
  trading_options?: {
    __typename?: 'ComponentTradingoptionsTradingOptions'
    equity?: boolean | null
    fno?: boolean | null
    currency?: boolean | null
    commodity?: boolean | null
  } | null
  account_opening_charges?: {
    __typename?: 'ComponentChargesAccountOpeningCharges'
    demat_opening_charges?: string | null
    trading_acc_opening_charges?: string | null
    trading_amc?: string | null
    demat_amc?: string | null
  } | null
  consolidated_charges?: Array<{
    __typename?: 'ComponentChargesConsolidatedCharges'
    plan_name?: string | null
    equity_delivery?: string | null
    equity_intraday?: string | null
    equity_futures?: string | null
    equity_options?: string | null
    currency_futures?: string | null
    currency_options?: string | null
    commodity?: string | null
    mutual_fund?: string | null
  } | null> | null
  equity_delivery_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  equity_intraday_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  futures_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  options_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  currency_futures_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  currency_options_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  commodity_futures_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  commodity_options_brokerage?: {
    __typename?: 'ComponentChargesBrokerage'
    brokerage?: string | null
    stt?: string | null
    turnover?: string | null
    gst?: string | null
    sebi?: string | null
    stamp?: string | null
  } | null
  exposure?: {
    __typename?: 'ComponentMarginsExposure'
    equity_delivery?: string | null
    equity_intraday?: string | null
    equity_future?: string | null
    equity_options?: string | null
    currency_future?: string | null
    currency_options?: string | null
    commodity_future?: string | null
    commodity_options?: string | null
  } | null
  country: {
    __typename?: 'ComponentCountryListCountries'
    name?: Enum_Componentcountrylistcountries_Name | null
  }
}

export type NavCalculatorsFragment = {
  __typename?: 'CalculatorEntityResponseCollection'
  data: Array<{
    __typename?: 'CalculatorEntity'
    attributes?: {
      __typename?: 'Calculator'
      name: string
      slug: string
    } | null
  }>
}

export type FinancialTermBasicFragment = {
  __typename?: 'FinancialTermEntityResponseCollection'
  data: Array<{
    __typename?: 'FinancialTermEntity'
    id?: any | null
    attributes?: {
      __typename?: 'FinancialTerm'
      name?: string | null
      slug?: string | null
      description?: string | null
    } | null
  }>
}

export type FullArticleInfoFragment = {
  __typename?: 'Article'
  title?: string | null
  slug: string
  short_description?: string | null
  description?: string | null
  tags?: any | null
  article_type?: Enum_Article_Article_Type | null
  short_query?: string | null
  seo_meta?: {
    __typename?: 'ComponentSeoSeo'
    title?: string | null
    description?: string | null
  } | null
  image?: {
    __typename?: 'ComponentUploadImageUploadImage'
    alt?: string | null
    description?: string | null
    file?: {
      __typename?: 'UploadFileRelationResponseCollection'
      data: Array<{
        __typename?: 'UploadFileEntity'
        attributes?: {
          __typename?: 'UploadFile'
          formats?: any | null
          url: string
        } | null
      }>
    } | null
  } | null
  categories?: {
    __typename?: 'CategoryRelationResponseCollection'
    data: Array<{
      __typename?: 'CategoryEntity'
      attributes?: {
        __typename?: 'Category'
        name?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  software?: {
    __typename?: 'SoftwareEntityResponse'
    data?: { __typename?: 'SoftwareEntity'; id?: any | null } | null
  } | null
  broker?: {
    __typename?: 'BrokerEntityResponse'
    data?: {
      __typename?: 'BrokerEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        details: string
        broker_type?: Enum_Broker_Broker_Type | null
        account_opening_process?: string | null
        demat?: string | null
        trading_account?: string | null
        commodity_trading?: string | null
        currency_trading?: string | null
      } | null
    } | null
  } | null
}

export type FullCalInfoFragment = {
  __typename?: 'CalculatorEntityResponseCollection'
  data: Array<{
    __typename?: 'CalculatorEntity'
    id?: any | null
    attributes?: {
      __typename?: 'Calculator'
      slug: string
      name: string
      description: string
      short_description: string
    } | null
  }>
}

export type FullSoftwareInfoFragment = {
  __typename?: 'Software'
  name?: string | null
  slug: string
  details: string
  tags?: any | null
  logo: {
    __typename?: 'UploadFileEntityResponse'
    data?: {
      __typename?: 'UploadFileEntity'
      attributes?: { __typename?: 'UploadFile'; url: string } | null
    } | null
  }
  countries?: {
    __typename?: 'CountryRelationResponseCollection'
    data: Array<{
      __typename?: 'CountryEntity'
      attributes?: {
        __typename?: 'Country'
        slug?: string | null
        name?: string | null
      } | null
    }>
  } | null
  exchanges?: {
    __typename?: 'ExchangeRelationResponseCollection'
    data: Array<{
      __typename?: 'ExchangeEntity'
      attributes?: {
        __typename?: 'Exchange'
        slug?: string | null
        name?: string | null
      } | null
    }>
  } | null
  broker?: {
    __typename?: 'BrokerEntityResponse'
    data?: {
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        slug?: string | null
        name?: string | null
      } | null
    } | null
  } | null
}

export type AllPageSlugsFragment = {
  __typename?: 'PageEntityResponseCollection'
  data: Array<{
    __typename?: 'PageEntity'
    attributes?: {
      __typename?: 'Page'
      title?: string | null
      slug?: string | null
    } | null
  }>
}

export type BaseCatInfoFragment = {
  __typename?: 'CategoryRelationResponseCollection'
  data: Array<{
    __typename?: 'CategoryEntity'
    attributes?: {
      __typename?: 'Category'
      name?: string | null
      slug?: string | null
    } | null
  }>
}

export type ShortArticleInfoFragment = {
  __typename?: 'ArticleRelationResponseCollection'
  data: Array<{
    __typename?: 'ArticleEntity'
    attributes?: {
      __typename?: 'Article'
      slug: string
      title?: string | null
      short_description?: string | null
      description?: string | null
    } | null
  }>
}

export type ShortSoftwareInfoFragment = {
  __typename?: 'SoftwareEntityResponseCollection'
  data: Array<{
    __typename?: 'SoftwareEntity'
    attributes?: {
      __typename?: 'Software'
      name?: string | null
      slug: string
      details: string
    } | null
  }>
}

export type BaseSoftwareInfoFragment = {
  __typename?: 'SoftwareEntityResponseCollection'
  data: Array<{
    __typename?: 'SoftwareEntity'
    attributes?: {
      __typename?: 'Software'
      name?: string | null
      slug: string
    } | null
  }>
}

export type Create_CommentMutationVariables = Exact<{
  data: ArticleCommentInput
}>

export type Create_CommentMutation = {
  __typename?: 'Mutation'
  createArticleComment?: {
    __typename?: 'ArticleCommentEntityResponse'
    data?: {
      __typename?: 'ArticleCommentEntity'
      attributes?: {
        __typename?: 'ArticleComment'
        article?: {
          __typename?: 'ArticleEntityResponse'
          data?: {
            __typename?: 'ArticleEntity'
            attributes?: {
              __typename?: 'Article'
              article_comments?: {
                __typename?: 'ArticleCommentRelationResponseCollection'
                data: Array<{
                  __typename?: 'ArticleCommentEntity'
                  attributes?: {
                    __typename?: 'ArticleComment'
                    content?: string | null
                    createdAt?: any | null
                    user?: {
                      __typename?: 'UsersPermissionsUserEntityResponse'
                      data?: {
                        __typename?: 'UsersPermissionsUserEntity'
                        attributes?: {
                          __typename?: 'UsersPermissionsUser'
                          username: string
                        } | null
                      } | null
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type CreateCommentMutationVariables = Exact<{
  input: CreateComment
}>

export type CreateCommentMutation = {
  __typename?: 'Mutation'
  createComment: {
    __typename?: 'CommentSingle'
    id?: any | null
    content: string
    createdAt?: string | null
    approvalStatus?: string | null
    author?: {
      __typename?: 'CommentAuthor'
      id?: any | null
      name: string
      avatar?: string | null
    } | null
  }
}

export type CreateBlogMutationVariables = Exact<{
  data: ArticleInput
}>

export type CreateBlogMutation = {
  __typename?: 'Mutation'
  createArticle?: {
    __typename?: 'ArticleEntityResponse'
    data?: {
      __typename?: 'ArticleEntity'
      attributes?: {
        __typename?: 'Article'
        slug: string
        user?: {
          __typename?: 'UsersPermissionsUserEntityResponse'
          data?: {
            __typename?: 'UsersPermissionsUserEntity'
            attributes?: {
              __typename?: 'UsersPermissionsUser'
              username: string
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']
  data: UsersPermissionsUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUsersPermissionsUser: {
    __typename?: 'UsersPermissionsUserEntityResponse'
    data?: {
      __typename?: 'UsersPermissionsUserEntity'
      attributes?: {
        __typename?: 'UsersPermissionsUser'
        username: string
      } | null
    } | null
  }
}

export type Signup_MutationMutationVariables = Exact<{
  input: UsersPermissionsRegisterInput
}>

export type Signup_MutationMutation = {
  __typename?: 'Mutation'
  register: {
    __typename?: 'UsersPermissionsLoginPayload'
    jwt?: string | null
    user: { __typename?: 'UsersPermissionsMe'; id: any; email?: string | null }
  }
}

export type Update_UserMutationVariables = Exact<{
  country?: InputMaybe<Scalars['String']>
}>

export type Update_UserMutation = {
  __typename?: 'Mutation'
  updateUser: boolean
}

export type Login_MutationMutationVariables = Exact<{
  input: UsersPermissionsLoginInput
}>

export type Login_MutationMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'UsersPermissionsLoginPayload'
    jwt?: string | null
    user: { __typename?: 'UsersPermissionsMe'; id: any; email?: string | null }
  }
}

export type All_Cities_With_Broker_And_NavQueryVariables = Exact<{
  [key: string]: never
}>

export type All_Cities_With_Broker_And_NavQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  all_cities?: {
    __typename?: 'CityEntityResponseCollection'
    data: Array<{
      __typename?: 'CityEntity'
      attributes?: { __typename?: 'City'; name: string; state: string } | null
    }>
  } | null
}

export type Article_With_BrokersQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>
}>

export type Article_With_BrokersQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  article?: {
    __typename?: 'ArticleEntityResponseCollection'
    data: Array<{
      __typename?: 'ArticleEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Article'
        createdAt?: any | null
        updatedAt?: any | null
        title?: string | null
        slug: string
        short_description?: string | null
        description?: string | null
        tags?: any | null
        article_type?: Enum_Article_Article_Type | null
        short_query?: string | null
        user?: {
          __typename?: 'UsersPermissionsUserEntityResponse'
          data?: {
            __typename?: 'UsersPermissionsUserEntity'
            attributes?: {
              __typename?: 'UsersPermissionsUser'
              username: string
              avatar?: string | null
            } | null
          } | null
        } | null
        plugin_comments: Array<{
          __typename?: 'CommentNested'
          id?: any | null
          content: string
          createdAt?: string | null
          updatedAt?: string | null
          approvalStatus?: string | null
          children?: Array<{
            __typename?: 'CommentNested'
            id?: any | null
            content: string
            createdAt?: string | null
            updatedAt?: string | null
            approvalStatus?: string | null
            children?: Array<{
              __typename?: 'CommentNested'
              id?: any | null
              content: string
              approvalStatus?: string | null
              author?: {
                __typename?: 'CommentAuthor'
                name: string
                avatar?: string | null
              } | null
              children?: Array<{
                __typename?: 'CommentNested'
                id?: any | null
                content: string
                approvalStatus?: string | null
                author?: {
                  __typename?: 'CommentAuthor'
                  name: string
                  avatar?: string | null
                } | null
                children?: Array<{
                  __typename?: 'CommentNested'
                  id?: any | null
                  content: string
                  approvalStatus?: string | null
                  author?: {
                    __typename?: 'CommentAuthor'
                    name: string
                    avatar?: string | null
                  } | null
                } | null> | null
              } | null> | null
            } | null> | null
            author?: {
              __typename?: 'CommentAuthor'
              id?: any | null
              name: string
              avatar?: string | null
            } | null
          } | null> | null
          author?: {
            __typename?: 'CommentAuthor'
            id?: any | null
            name: string
            avatar?: string | null
          } | null
        } | null>
        relatedArticles: Array<{
          __typename?: 'Article'
          title?: string | null
          tags?: any | null
          slug: string
          description?: string | null
          short_description?: string | null
        } | null>
        country: {
          __typename?: 'CountryEntityResponse'
          data?: {
            __typename?: 'CountryEntity'
            attributes?: { __typename?: 'Country'; name?: string | null } | null
          } | null
        }
        seo_meta?: {
          __typename?: 'ComponentSeoSeo'
          title?: string | null
          description?: string | null
        } | null
        image?: {
          __typename?: 'ComponentUploadImageUploadImage'
          alt?: string | null
          description?: string | null
          file?: {
            __typename?: 'UploadFileRelationResponseCollection'
            data: Array<{
              __typename?: 'UploadFileEntity'
              attributes?: {
                __typename?: 'UploadFile'
                formats?: any | null
                url: string
              } | null
            }>
          } | null
        } | null
        categories?: {
          __typename?: 'CategoryRelationResponseCollection'
          data: Array<{
            __typename?: 'CategoryEntity'
            attributes?: {
              __typename?: 'Category'
              name?: string | null
              slug?: string | null
            } | null
          }>
        } | null
        software?: {
          __typename?: 'SoftwareEntityResponse'
          data?: { __typename?: 'SoftwareEntity'; id?: any | null } | null
        } | null
        broker?: {
          __typename?: 'BrokerEntityResponse'
          data?: {
            __typename?: 'BrokerEntity'
            id?: any | null
            attributes?: {
              __typename?: 'Broker'
              name?: string | null
              slug?: string | null
              details: string
              broker_type?: Enum_Broker_Broker_Type | null
              account_opening_process?: string | null
              demat?: string | null
              trading_account?: string | null
              commodity_trading?: string | null
              currency_trading?: string | null
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type ArticleSearchByKeywordQueryVariables = Exact<{
  where?: InputMaybe<Scalars['JSON']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type ArticleSearchByKeywordQuery = {
  __typename?: 'Query'
  articleSearchByKeyword: Array<{
    __typename?: 'Article'
    slug: string
    title?: string | null
  } | null>
}

export type ArticleCommentsQueryVariables = Exact<{
  relation: Scalars['String']
  sort?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
}>

export type ArticleCommentsQuery = {
  __typename?: 'Query'
  plugin_comments: Array<{
    __typename?: 'CommentNested'
    id?: any | null
    content: string
    createdAt?: string | null
    updatedAt?: string | null
    approvalStatus?: string | null
    children?: Array<{
      __typename?: 'CommentNested'
      id?: any | null
      content: string
      createdAt?: string | null
      updatedAt?: string | null
      approvalStatus?: string | null
      children?: Array<{
        __typename?: 'CommentNested'
        id?: any | null
        content: string
        approvalStatus?: string | null
        author?: {
          __typename?: 'CommentAuthor'
          name: string
          avatar?: string | null
        } | null
        children?: Array<{
          __typename?: 'CommentNested'
          id?: any | null
          content: string
          approvalStatus?: string | null
          author?: {
            __typename?: 'CommentAuthor'
            name: string
            avatar?: string | null
          } | null
          children?: Array<{
            __typename?: 'CommentNested'
            id?: any | null
            content: string
            approvalStatus?: string | null
            author?: {
              __typename?: 'CommentAuthor'
              name: string
              avatar?: string | null
            } | null
          } | null> | null
        } | null> | null
      } | null> | null
      author?: {
        __typename?: 'CommentAuthor'
        id?: any | null
        name: string
        avatar?: string | null
      } | null
    } | null> | null
    author?: {
      __typename?: 'CommentAuthor'
      id?: any | null
      name: string
      avatar?: string | null
    } | null
  } | null>
}

export type Base_QueryQueryVariables = Exact<{ [key: string]: never }>

export type Base_QueryQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type BrokersQueryVariables = Exact<{
  slug: Scalars['String']
  article_type: Scalars['String']
  page_url?: InputMaybe<Scalars['String']>
}>

export type BrokersQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        broker_type?: Enum_Broker_Broker_Type | null
        name?: string | null
        slug?: string | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        details: string
        broker_type?: Enum_Broker_Broker_Type | null
        account_opening_process?: string | null
        demat?: string | null
        trading_account?: string | null
        commodity_trading?: string | null
        currency_trading?: string | null
        exposure_details?: string | null
        articles?: {
          __typename?: 'ArticleRelationResponseCollection'
          data: Array<{
            __typename?: 'ArticleEntity'
            attributes?: {
              __typename?: 'Article'
              slug: string
              title?: string | null
              short_description?: string | null
              description?: string | null
            } | null
          }>
        } | null
        image?: {
          __typename?: 'ComponentUploadImageUploadImage'
          alt?: string | null
          description?: string | null
          file?: {
            __typename?: 'UploadFileRelationResponseCollection'
            data: Array<{
              __typename?: 'UploadFileEntity'
              attributes?: {
                __typename?: 'UploadFile'
                formats?: any | null
                url: string
              } | null
            }>
          } | null
        } | null
        softwares?: {
          __typename?: 'SoftwareRelationResponseCollection'
          data: Array<{
            __typename?: 'SoftwareEntity'
            attributes?: {
              __typename?: 'Software'
              details: string
              name?: string | null
              slug: string
            } | null
          }>
        } | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
        pros_cons?: Array<{
          __typename?: 'ComponentProsConsContent'
          id: any
          display_text: string
          value_type: Enum_Componentprosconscontent_Value_Type
        } | null> | null
        exchanges?: {
          __typename?: 'ComponentExchangelistExchanges'
          bse?: boolean | null
          nse?: boolean | null
          mcx?: boolean | null
          ncdex?: boolean | null
        } | null
        trading_options?: {
          __typename?: 'ComponentTradingoptionsTradingOptions'
          equity?: boolean | null
          fno?: boolean | null
          currency?: boolean | null
          commodity?: boolean | null
        } | null
        account_opening_charges?: {
          __typename?: 'ComponentChargesAccountOpeningCharges'
          demat_opening_charges?: string | null
          trading_acc_opening_charges?: string | null
          trading_amc?: string | null
          demat_amc?: string | null
        } | null
        consolidated_charges?: Array<{
          __typename?: 'ComponentChargesConsolidatedCharges'
          plan_name?: string | null
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_futures?: string | null
          equity_options?: string | null
          currency_futures?: string | null
          currency_options?: string | null
          commodity?: string | null
          mutual_fund?: string | null
        } | null> | null
        equity_delivery_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        equity_intraday_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        exposure?: {
          __typename?: 'ComponentMarginsExposure'
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_future?: string | null
          equity_options?: string | null
          currency_future?: string | null
          currency_options?: string | null
          commodity_future?: string | null
          commodity_options?: string | null
        } | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Broker_FaqQueryVariables = Exact<{
  slug: Scalars['String']
  start?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type Broker_FaqQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        brokerArticlesCount: number
        name?: string | null
        slug?: string | null
        details: string
        broker_type?: Enum_Broker_Broker_Type | null
        account_opening_process?: string | null
        demat?: string | null
        trading_account?: string | null
        commodity_trading?: string | null
        currency_trading?: string | null
        exposure_details?: string | null
        articles?: {
          __typename?: 'ArticleRelationResponseCollection'
          data: Array<{
            __typename?: 'ArticleEntity'
            attributes?: {
              __typename?: 'Article'
              title?: string | null
              description?: string | null
              short_description?: string | null
              slug: string
              categories?: {
                __typename?: 'CategoryRelationResponseCollection'
                data: Array<{
                  __typename?: 'CategoryEntity'
                  attributes?: {
                    __typename?: 'Category'
                    name?: string | null
                    slug?: string | null
                  } | null
                }>
              } | null
              broker?: {
                __typename?: 'BrokerEntityResponse'
                data?: {
                  __typename?: 'BrokerEntity'
                  attributes?: {
                    __typename?: 'Broker'
                    name?: string | null
                    slug?: string | null
                  } | null
                } | null
              } | null
              software?: {
                __typename?: 'SoftwareEntityResponse'
                data?: {
                  __typename?: 'SoftwareEntity'
                  attributes?: {
                    __typename?: 'Software'
                    name?: string | null
                    slug: string
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
        softwares?: {
          __typename?: 'SoftwareRelationResponseCollection'
          data: Array<{
            __typename?: 'SoftwareEntity'
            attributes?: {
              __typename?: 'Software'
              name?: string | null
              slug: string
            } | null
          }>
        } | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
        exchanges?: {
          __typename?: 'ComponentExchangelistExchanges'
          bse?: boolean | null
          nse?: boolean | null
          mcx?: boolean | null
          ncdex?: boolean | null
        } | null
        trading_options?: {
          __typename?: 'ComponentTradingoptionsTradingOptions'
          equity?: boolean | null
          fno?: boolean | null
          currency?: boolean | null
          commodity?: boolean | null
        } | null
        account_opening_charges?: {
          __typename?: 'ComponentChargesAccountOpeningCharges'
          demat_opening_charges?: string | null
          trading_acc_opening_charges?: string | null
          trading_amc?: string | null
          demat_amc?: string | null
        } | null
        consolidated_charges?: Array<{
          __typename?: 'ComponentChargesConsolidatedCharges'
          plan_name?: string | null
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_futures?: string | null
          equity_options?: string | null
          currency_futures?: string | null
          currency_options?: string | null
          commodity?: string | null
          mutual_fund?: string | null
        } | null> | null
        equity_delivery_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        equity_intraday_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        exposure?: {
          __typename?: 'ComponentMarginsExposure'
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_future?: string | null
          equity_options?: string | null
          currency_future?: string | null
          currency_options?: string | null
          commodity_future?: string | null
          commodity_options?: string | null
        } | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Brokers_Linked_PageQueryVariables = Exact<{
  slug: Scalars['String']
  article_type: Scalars['String']
  page_url?: InputMaybe<Scalars['String']>
}>

export type Brokers_Linked_PageQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        details: string
        broker_type?: Enum_Broker_Broker_Type | null
        account_opening_process?: string | null
        demat?: string | null
        trading_account?: string | null
        commodity_trading?: string | null
        currency_trading?: string | null
        exposure_details?: string | null
        articles?: {
          __typename?: 'ArticleRelationResponseCollection'
          data: Array<{
            __typename?: 'ArticleEntity'
            attributes?: {
              __typename?: 'Article'
              slug: string
              title?: string | null
              short_description?: string | null
              description?: string | null
            } | null
          }>
        } | null
        exchanges?: {
          __typename?: 'ComponentExchangelistExchanges'
          bse?: boolean | null
          nse?: boolean | null
          mcx?: boolean | null
          ncdex?: boolean | null
        } | null
        trading_options?: {
          __typename?: 'ComponentTradingoptionsTradingOptions'
          equity?: boolean | null
          fno?: boolean | null
          currency?: boolean | null
          commodity?: boolean | null
        } | null
        account_opening_charges?: {
          __typename?: 'ComponentChargesAccountOpeningCharges'
          demat_opening_charges?: string | null
          trading_acc_opening_charges?: string | null
          trading_amc?: string | null
          demat_amc?: string | null
        } | null
        consolidated_charges?: Array<{
          __typename?: 'ComponentChargesConsolidatedCharges'
          plan_name?: string | null
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_futures?: string | null
          equity_options?: string | null
          currency_futures?: string | null
          currency_options?: string | null
          commodity?: string | null
          mutual_fund?: string | null
        } | null> | null
        equity_delivery_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        equity_intraday_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        exposure?: {
          __typename?: 'ComponentMarginsExposure'
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_future?: string | null
          equity_options?: string | null
          currency_future?: string | null
          currency_options?: string | null
          commodity_future?: string | null
          commodity_options?: string | null
        } | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Brokers_QueryQueryVariables = Exact<{ [key: string]: never }>

export type Brokers_QueryQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        details: string
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Calculators_With_BrokersQueryVariables = Exact<{
  [key: string]: never
}>

export type Calculators_With_BrokersQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  all_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Calculator'
        slug: string
        name: string
        description: string
        short_description: string
      } | null
    }>
  } | null
}

export type Calculator_With_NavQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type Calculator_With_NavQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  slugCalculator?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Calculator'
        slug: string
        name: string
        description: string
        short_description: string
        image?: {
          __typename?: 'ComponentUploadImageUploadImage'
          alt?: string | null
          description?: string | null
        } | null
        articles?: {
          __typename?: 'ArticleRelationResponseCollection'
          data: Array<{
            __typename?: 'ArticleEntity'
            attributes?: {
              __typename?: 'Article'
              title?: string | null
              slug: string
              short_description?: string | null
              description?: string | null
              tags?: any | null
              article_type?: Enum_Article_Article_Type | null
              short_query?: string | null
              seo_meta?: {
                __typename?: 'ComponentSeoSeo'
                title?: string | null
                description?: string | null
              } | null
              image?: {
                __typename?: 'ComponentUploadImageUploadImage'
                alt?: string | null
                description?: string | null
                file?: {
                  __typename?: 'UploadFileRelationResponseCollection'
                  data: Array<{
                    __typename?: 'UploadFileEntity'
                    attributes?: {
                      __typename?: 'UploadFile'
                      formats?: any | null
                      url: string
                    } | null
                  }>
                } | null
              } | null
              categories?: {
                __typename?: 'CategoryRelationResponseCollection'
                data: Array<{
                  __typename?: 'CategoryEntity'
                  attributes?: {
                    __typename?: 'Category'
                    name?: string | null
                    slug?: string | null
                  } | null
                }>
              } | null
              software?: {
                __typename?: 'SoftwareEntityResponse'
                data?: { __typename?: 'SoftwareEntity'; id?: any | null } | null
              } | null
              broker?: {
                __typename?: 'BrokerEntityResponse'
                data?: {
                  __typename?: 'BrokerEntity'
                  id?: any | null
                  attributes?: {
                    __typename?: 'Broker'
                    name?: string | null
                    slug?: string | null
                    details: string
                    broker_type?: Enum_Broker_Broker_Type | null
                    account_opening_process?: string | null
                    demat?: string | null
                    trading_account?: string | null
                    commodity_trading?: string | null
                    currency_trading?: string | null
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Create_Faq_PageQueryVariables = Exact<{ [key: string]: never }>

export type Create_Faq_PageQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  all_categories?: {
    __typename?: 'CategoryEntityResponseCollection'
    data: Array<{
      __typename?: 'CategoryEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Category'
        name?: string | null
        slug?: string | null
      } | null
    }>
  } | null
}

export type Financial_TermQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type Financial_TermQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  selected_term?: {
    __typename?: 'FinancialTermEntityResponseCollection'
    data: Array<{
      __typename?: 'FinancialTermEntity'
      id?: any | null
      attributes?: {
        __typename?: 'FinancialTerm'
        name?: string | null
        slug?: string | null
        description?: string | null
        articles?: {
          __typename?: 'ArticleRelationResponseCollection'
          data: Array<{
            __typename?: 'ArticleEntity'
            attributes?: {
              __typename?: 'Article'
              slug: string
              title?: string | null
              short_description?: string | null
              description?: string | null
            } | null
          }>
        } | null
        seo_meta?: {
          __typename?: 'ComponentSeoSeo'
          title?: string | null
          description?: string | null
        } | null
        image?: {
          __typename?: 'ComponentUploadImageUploadImage'
          alt?: string | null
          description?: string | null
          file?: {
            __typename?: 'UploadFileRelationResponseCollection'
            data: Array<{
              __typename?: 'UploadFileEntity'
              attributes?: {
                __typename?: 'UploadFile'
                formats?: any | null
                url: string
              } | null
            }>
          } | null
        } | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Get_Financial_TermsQueryVariables = Exact<{ [key: string]: never }>

export type Get_Financial_TermsQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  all_terms?: {
    __typename?: 'FinancialTermEntityResponseCollection'
    data: Array<{
      __typename?: 'FinancialTermEntity'
      id?: any | null
      attributes?: {
        __typename?: 'FinancialTerm'
        name?: string | null
        slug?: string | null
        description?: string | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type FindPageBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type FindPageBySlugQuery = {
  __typename?: 'Query'
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
  pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        slug?: string | null
        title?: string | null
        description?: string | null
      } | null
    }>
  } | null
}

export type FindAllPagesQueryVariables = Exact<{ [key: string]: never }>

export type FindAllPagesQuery = {
  __typename?: 'Query'
  pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: { __typename?: 'Page'; slug?: string | null } | null
    }>
  } | null
}

export type Get_All_BrokersQueryVariables = Exact<{
  slug?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  page_url?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}>

export type Get_All_BrokersQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        broker_type?: Enum_Broker_Broker_Type | null
        name?: string | null
        slug?: string | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        details: string
        broker_type?: Enum_Broker_Broker_Type | null
        account_opening_process?: string | null
        demat?: string | null
        trading_account?: string | null
        commodity_trading?: string | null
        currency_trading?: string | null
        exposure_details?: string | null
        softwares?: {
          __typename?: 'SoftwareRelationResponseCollection'
          data: Array<{
            __typename?: 'SoftwareEntity'
            attributes?: {
              __typename?: 'Software'
              name?: string | null
              slug: string
            } | null
          }>
        } | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
        pros_cons?: Array<{
          __typename?: 'ComponentProsConsContent'
          id: any
          display_text: string
          value_type: Enum_Componentprosconscontent_Value_Type
        } | null> | null
        exchanges?: {
          __typename?: 'ComponentExchangelistExchanges'
          bse?: boolean | null
          nse?: boolean | null
          mcx?: boolean | null
          ncdex?: boolean | null
        } | null
        trading_options?: {
          __typename?: 'ComponentTradingoptionsTradingOptions'
          equity?: boolean | null
          fno?: boolean | null
          currency?: boolean | null
          commodity?: boolean | null
        } | null
        account_opening_charges?: {
          __typename?: 'ComponentChargesAccountOpeningCharges'
          demat_opening_charges?: string | null
          trading_acc_opening_charges?: string | null
          trading_amc?: string | null
          demat_amc?: string | null
        } | null
        consolidated_charges?: Array<{
          __typename?: 'ComponentChargesConsolidatedCharges'
          plan_name?: string | null
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_futures?: string | null
          equity_options?: string | null
          currency_futures?: string | null
          currency_options?: string | null
          commodity?: string | null
          mutual_fund?: string | null
        } | null> | null
        equity_delivery_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        equity_intraday_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        currency_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_futures_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        commodity_options_brokerage?: {
          __typename?: 'ComponentChargesBrokerage'
          brokerage?: string | null
          stt?: string | null
          turnover?: string | null
          gst?: string | null
          sebi?: string | null
          stamp?: string | null
        } | null
        exposure?: {
          __typename?: 'ComponentMarginsExposure'
          equity_delivery?: string | null
          equity_intraday?: string | null
          equity_future?: string | null
          equity_options?: string | null
          currency_future?: string | null
          currency_options?: string | null
          commodity_future?: string | null
          commodity_options?: string | null
        } | null
      } | null
    }>
  } | null
  articles?: {
    __typename?: 'ArticleEntityResponseCollection'
    data: Array<{
      __typename?: 'ArticleEntity'
      attributes?: {
        __typename?: 'Article'
        slug: string
        title?: string | null
        short_description?: string | null
        description?: string | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Nav_With_SoftwareQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['Int']>
}>

export type Nav_With_SoftwareQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
  selected_city?: {
    __typename?: 'CityEntityResponseCollection'
    data: Array<{
      __typename?: 'CityEntity'
      attributes?: {
        __typename?: 'City'
        name: string
        state: string
        listings?: {
          __typename?: 'ListingRelationResponseCollection'
          data: Array<{
            __typename?: 'ListingEntity'
            attributes?: {
              __typename?: 'Listing'
              name: string
              address?: string | null
              associated_broker?: string | null
              broker?: {
                __typename?: 'BrokerEntityResponse'
                data?: {
                  __typename?: 'BrokerEntity'
                  attributes?: {
                    __typename?: 'Broker'
                    name?: string | null
                    slug?: string | null
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
  broker_articles?: {
    __typename?: 'ArticleEntityResponseCollection'
    data: Array<{
      __typename?: 'ArticleEntity'
      attributes?: {
        __typename?: 'Article'
        slug: string
        title?: string | null
        short_description?: string | null
        description?: string | null
      } | null
    }>
  } | null
}

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
}>

export type GetUserQuery = {
  __typename?: 'Query'
  usersPermissionsUser?: {
    __typename?: 'UsersPermissionsUserEntityResponse'
    data?: {
      __typename?: 'UsersPermissionsUserEntity'
      attributes?: {
        __typename?: 'UsersPermissionsUser'
        username: string
        website?: string | null
        twitter?: string | null
        short_description?: string | null
        full_name?: string | null
      } | null
    } | null
  } | null
}

export type HomeContentQueryVariables = Exact<{
  country?: InputMaybe<Scalars['String']>
}>

export type HomeContentQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Calculator'
        slug: string
        name: string
        description: string
        short_description: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  investment_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  finance_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  stock_market_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  ipo_basics_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  financial_terms_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  option_trading_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  stock_broker_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  mutual_funds_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  stock_trading_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  trading_software_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  latest_comments: Array<{
    __typename?: 'CommentSingle'
    content: string
    id?: any | null
    articleSlug?: string | null
    createdAt?: string | null
    author?: {
      __typename?: 'CommentAuthor'
      id?: any | null
      name: string
      email?: string | null
      avatar?: string | null
    } | null
  } | null>
  latest_ipos?: {
    __typename?: 'IpoEntityResponseCollection'
    data: Array<{
      __typename?: 'IpoEntity'
      attributes?: {
        __typename?: 'Ipo'
        name?: string | null
        slug?: string | null
        deal_id: string
        description?: string | null
      } | null
    }>
  } | null
}

export type HomeContentCanadaQueryVariables = Exact<{
  country?: InputMaybe<Scalars['String']>
}>

export type HomeContentCanadaQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Calculator'
        slug: string
        name: string
        description: string
        short_description: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  investment_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  finance_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  stock_market_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  trading_software_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  banking_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  tfsa_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  cpp_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  rrsp_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  resp_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  credit_card_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  new_immigrant_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  mortgage_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  real_estate_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  crypto_currency_faqs: Array<{
    __typename?: 'Article'
    title?: string | null
    slug: string
  } | null>
  latest_comments: Array<{
    __typename?: 'CommentSingle'
    content: string
    id?: any | null
    articleSlug?: string | null
    createdAt?: string | null
    author?: {
      __typename?: 'CommentAuthor'
      id?: any | null
      name: string
      email?: string | null
      avatar?: string | null
    } | null
  } | null>
}

export type IpoContentQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>
}>

export type IpoContentQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Calculator'
        slug: string
        name: string
        description: string
        short_description: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  ipo?: {
    __typename?: 'IpoEntityResponseCollection'
    data: Array<{
      __typename?: 'IpoEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Ipo'
        slug?: string | null
        name?: string | null
        symbol?: string | null
        offer_amount?: string | null
        ipo_start_date?: any | null
        ipo_end_date?: any | null
        ipo_price?: string | null
        ceo?: string | null
        share_price?: string | null
        shares_offered?: string | null
        address?: string | null
        website?: string | null
        phone?: string | null
        state_inc?: string | null
        lockup_days?: string | null
        lockup_expiration?: string | null
        quiet_period_expiration?: string | null
        cik?: string | null
        offering_expense?: string | null
        shares_outstanding?: string | null
        description?: string | null
        deal_id: string
        fiscal_year_end?: string | null
        shares_over_alloted?: string | null
        status?: Enum_Ipo_Status | null
        employees?: string | null
        exchange?: Enum_Ipo_Exchange | null
        filed_date?: any | null
        priced_date?: any | null
        withdrawn_date?: any | null
        revenue?: string | null
        net_income?: string | null
        total_assets?: string | null
        total_liabilities?: string | null
        stock_holders_equity?: string | null
        form_type?: string | null
        date_received?: string | null
        filing_link?: string | null
        plugin_comments: Array<{
          __typename?: 'CommentNested'
          id?: any | null
          content: string
          createdAt?: string | null
          updatedAt?: string | null
          approvalStatus?: string | null
          children?: Array<{
            __typename?: 'CommentNested'
            id?: any | null
            content: string
            createdAt?: string | null
            updatedAt?: string | null
            approvalStatus?: string | null
            children?: Array<{
              __typename?: 'CommentNested'
              id?: any | null
              content: string
              approvalStatus?: string | null
              author?: {
                __typename?: 'CommentAuthor'
                name: string
                avatar?: string | null
              } | null
              children?: Array<{
                __typename?: 'CommentNested'
                id?: any | null
                content: string
                approvalStatus?: string | null
                author?: {
                  __typename?: 'CommentAuthor'
                  name: string
                  avatar?: string | null
                } | null
                children?: Array<{
                  __typename?: 'CommentNested'
                  id?: any | null
                  content: string
                  approvalStatus?: string | null
                  author?: {
                    __typename?: 'CommentAuthor'
                    name: string
                    avatar?: string | null
                  } | null
                } | null> | null
              } | null> | null
            } | null> | null
            author?: {
              __typename?: 'CommentAuthor'
              id?: any | null
              name: string
              avatar?: string | null
            } | null
          } | null> | null
          author?: {
            __typename?: 'CommentAuthor'
            id?: any | null
            name: string
            avatar?: string | null
          } | null
        } | null>
      } | null
    }>
  } | null
}

export type IpoCommentsQueryVariables = Exact<{
  relation: Scalars['String']
  sort?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
}>

export type IpoCommentsQuery = {
  __typename?: 'Query'
  plugin_comments: Array<{
    __typename?: 'CommentNested'
    id?: any | null
    content: string
    createdAt?: string | null
    updatedAt?: string | null
    approvalStatus?: string | null
    children?: Array<{
      __typename?: 'CommentNested'
      id?: any | null
      content: string
      createdAt?: string | null
      updatedAt?: string | null
      approvalStatus?: string | null
      children?: Array<{
        __typename?: 'CommentNested'
        id?: any | null
        content: string
        approvalStatus?: string | null
        author?: {
          __typename?: 'CommentAuthor'
          name: string
          avatar?: string | null
        } | null
        children?: Array<{
          __typename?: 'CommentNested'
          id?: any | null
          content: string
          approvalStatus?: string | null
          author?: {
            __typename?: 'CommentAuthor'
            name: string
            avatar?: string | null
          } | null
          children?: Array<{
            __typename?: 'CommentNested'
            id?: any | null
            content: string
            approvalStatus?: string | null
            author?: {
              __typename?: 'CommentAuthor'
              name: string
              avatar?: string | null
            } | null
          } | null> | null
        } | null> | null
      } | null> | null
      author?: {
        __typename?: 'CommentAuthor'
        id?: any | null
        name: string
        avatar?: string | null
      } | null
    } | null> | null
    author?: {
      __typename?: 'CommentAuthor'
      id?: any | null
      name: string
      avatar?: string | null
    } | null
  } | null>
}

export type IposQueryVariables = Exact<{ [key: string]: never }>

export type IposQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Calculator'
        slug: string
        name: string
        description: string
        short_description: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  filed_ipos?: {
    __typename?: 'IpoEntityResponseCollection'
    meta: {
      __typename?: 'ResponseCollectionMeta'
      pagination: { __typename?: 'Pagination'; total: number }
    }
  } | null
}

export type GetFiledIposQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']>
  pageSizeCount?: InputMaybe<Scalars['Int']>
}>

export type GetFiledIposQuery = {
  __typename?: 'Query'
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Calculator'
        slug: string
        name: string
        description: string
        short_description: string
      } | null
    }>
  } | null
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  filed_ipos?: {
    __typename?: 'IpoEntityResponseCollection'
    meta: {
      __typename?: 'ResponseCollectionMeta'
      pagination: { __typename?: 'Pagination'; total: number }
    }
    data: Array<{
      __typename?: 'IpoEntity'
      id?: any | null
      attributes?: {
        __typename?: 'Ipo'
        deal_id: string
        symbol?: string | null
        name?: string | null
        slug?: string | null
        offer_amount?: string | null
        filed_date?: any | null
      } | null
    }>
  } | null
}

export type Software_With_NavQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type Software_With_NavQuery = {
  __typename?: 'Query'
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  selected_software?: {
    __typename?: 'SoftwareEntityResponseCollection'
    data: Array<{
      __typename?: 'SoftwareEntity'
      attributes?: {
        __typename?: 'Software'
        name?: string | null
        slug: string
        details: string
        tags?: any | null
        seo_meta?: {
          __typename?: 'ComponentSeoSeo'
          title?: string | null
          description?: string | null
        } | null
        articles?: {
          __typename?: 'ArticleRelationResponseCollection'
          data: Array<{
            __typename?: 'ArticleEntity'
            attributes?: {
              __typename?: 'Article'
              slug: string
              title?: string | null
              short_description?: string | null
              description?: string | null
            } | null
          }>
        } | null
        softwareRelatedArticles: Array<{
          __typename?: 'Article'
          title?: string | null
          slug: string
          short_description?: string | null
        } | null>
        broker?: {
          __typename?: 'BrokerEntityResponse'
          data?: {
            __typename?: 'BrokerEntity'
            attributes?: {
              __typename?: 'Broker'
              name?: string | null
              slug?: string | null
              currency_trading?: string | null
              commodity_trading?: string | null
            } | null
          } | null
        } | null
        image?: {
          __typename?: 'ComponentUploadImageUploadImage'
          alt?: string | null
          description?: string | null
        } | null
        logo: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        }
        relatedSoftwares: Array<{
          __typename?: 'Software'
          name?: string | null
          slug: string
        } | null>
        countries?: {
          __typename?: 'CountryRelationResponseCollection'
          data: Array<{
            __typename?: 'CountryEntity'
            attributes?: {
              __typename?: 'Country'
              slug?: string | null
              name?: string | null
            } | null
          }>
        } | null
        exchanges?: {
          __typename?: 'ExchangeRelationResponseCollection'
          data: Array<{
            __typename?: 'ExchangeEntity'
            attributes?: {
              __typename?: 'Exchange'
              slug?: string | null
              name?: string | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export type Get_Software_ListQueryVariables = Exact<{ [key: string]: never }>

export type Get_Software_ListQuery = {
  __typename?: 'Query'
  all_pages?: {
    __typename?: 'PageEntityResponseCollection'
    data: Array<{
      __typename?: 'PageEntity'
      attributes?: {
        __typename?: 'Page'
        title?: string | null
        slug?: string | null
      } | null
    }>
  } | null
  all_brokers?: {
    __typename?: 'BrokerEntityResponseCollection'
    data: Array<{
      __typename?: 'BrokerEntity'
      attributes?: {
        __typename?: 'Broker'
        name?: string | null
        slug?: string | null
        broker_type?: Enum_Broker_Broker_Type | null
        country: {
          __typename?: 'ComponentCountryListCountries'
          name?: Enum_Componentcountrylistcountries_Name | null
        }
      } | null
    }>
  } | null
  all_softwares?: {
    __typename?: 'SoftwareEntityResponseCollection'
    data: Array<{
      __typename?: 'SoftwareEntity'
      attributes?: {
        __typename?: 'Software'
        name?: string | null
        slug: string
        details: string
      } | null
    }>
  } | null
  nav_calculators?: {
    __typename?: 'CalculatorEntityResponseCollection'
    data: Array<{
      __typename?: 'CalculatorEntity'
      attributes?: {
        __typename?: 'Calculator'
        name: string
        slug: string
      } | null
    }>
  } | null
}

export const ArticleCommentsFragmentDoc = gql`
  fragment ArticleComments on CommentNested {
    id
    content
    createdAt
    updatedAt
    approvalStatus
    children {
      id
      content
      createdAt
      updatedAt
      approvalStatus
      children {
        id
        content
        approvalStatus
        author {
          name
          avatar
        }
        children {
          id
          content
          approvalStatus
          author {
            name
            avatar
          }
          children {
            id
            content
            approvalStatus
            author {
              name
              avatar
            }
          }
        }
      }
      author {
        id
        name
        avatar
      }
    }
    author {
      id
      name
      avatar
    }
  }
`
export const AllBrokersFragmentDoc = gql`
  fragment AllBrokers on BrokerEntityResponseCollection {
    data {
      attributes {
        name
        slug
        broker_type
        country {
          name
        }
      }
    }
  }
`
export const BaseBrokerInfoFragmentDoc = gql`
  fragment BaseBrokerInfo on BrokerEntityResponseCollection {
    data {
      attributes {
        name
        slug
        broker_type
      }
    }
  }
`
export const BrokerInfoFragmentDoc = gql`
  fragment BrokerInfo on Broker {
    name
    slug
    details
    broker_type
    account_opening_process
    demat
    trading_account
    commodity_trading
    currency_trading
    exposure_details
    exchanges {
      bse
      nse
      mcx
      ncdex
    }
    trading_options {
      equity
      fno
      currency
      commodity
    }
    account_opening_charges {
      demat_opening_charges
      trading_acc_opening_charges
      trading_amc
      demat_amc
    }
    consolidated_charges {
      plan_name
      equity_delivery
      equity_intraday
      equity_futures
      equity_options
      currency_futures
      currency_options
      commodity
      mutual_fund
    }
    equity_delivery_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    equity_intraday_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    futures_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    options_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    currency_futures_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    currency_options_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    commodity_futures_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    commodity_options_brokerage {
      brokerage
      stt
      turnover
      gst
      sebi
      stamp
    }
    exposure {
      equity_delivery
      equity_intraday
      equity_future
      equity_options
      currency_future
      currency_options
      commodity_future
      commodity_options
    }
    country {
      name
    }
  }
`
export const NavCalculatorsFragmentDoc = gql`
  fragment NavCalculators on CalculatorEntityResponseCollection {
    data {
      attributes {
        name
        slug
      }
    }
  }
`
export const FinancialTermBasicFragmentDoc = gql`
  fragment FinancialTermBasic on FinancialTermEntityResponseCollection {
    data {
      id
      attributes {
        name
        slug
        description
      }
    }
  }
`
export const FullArticleInfoFragmentDoc = gql`
  fragment FullArticleInfo on Article {
    title
    slug
    short_description
    description
    tags
    article_type
    short_query
    seo_meta {
      title
      description
    }
    image {
      alt
      description
      file {
        data {
          attributes {
            formats
            url
          }
        }
      }
    }
    categories {
      data {
        attributes {
          name
          slug
        }
      }
    }
    software {
      data {
        id
      }
    }
    broker {
      data {
        id
        attributes {
          name
          slug
          details
          broker_type
          account_opening_process
          demat
          trading_account
          commodity_trading
          currency_trading
        }
      }
    }
  }
`
export const FullCalInfoFragmentDoc = gql`
  fragment FullCalInfo on CalculatorEntityResponseCollection {
    data {
      id
      attributes {
        slug
        name
        description
        short_description
      }
    }
  }
`
export const FullSoftwareInfoFragmentDoc = gql`
  fragment FullSoftwareInfo on Software {
    name
    slug
    details
    tags
    logo {
      data {
        attributes {
          url
        }
      }
    }
    countries {
      data {
        attributes {
          slug
          name
        }
      }
    }
    exchanges {
      data {
        attributes {
          slug
          name
        }
      }
    }
    broker {
      data {
        attributes {
          slug
          name
        }
      }
    }
  }
`
export const AllPageSlugsFragmentDoc = gql`
  fragment AllPageSlugs on PageEntityResponseCollection {
    data {
      attributes {
        title
        slug
      }
    }
  }
`
export const BaseCatInfoFragmentDoc = gql`
  fragment BaseCatInfo on CategoryRelationResponseCollection {
    data {
      attributes {
        name
        slug
      }
    }
  }
`
export const ShortArticleInfoFragmentDoc = gql`
  fragment ShortArticleInfo on ArticleRelationResponseCollection {
    data {
      attributes {
        slug
        title
        short_description
        description
      }
    }
  }
`
export const ShortSoftwareInfoFragmentDoc = gql`
  fragment ShortSoftwareInfo on SoftwareEntityResponseCollection {
    data {
      attributes {
        name
        slug
        details
      }
    }
  }
`
export const BaseSoftwareInfoFragmentDoc = gql`
  fragment BaseSoftwareInfo on SoftwareEntityResponseCollection {
    data {
      attributes {
        name
        slug
      }
    }
  }
`
export const Create_CommentDocument = gql`
  mutation create_comment($data: ArticleCommentInput!) {
    createArticleComment(data: $data) {
      data {
        attributes {
          article {
            data {
              attributes {
                article_comments(
                  sort: "createdAt:desc"
                  pagination: { limit: 100 }
                ) {
                  data {
                    attributes {
                      content
                      createdAt
                      user {
                        data {
                          attributes {
                            username
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export type Create_CommentMutationFn = Apollo.MutationFunction<
  Create_CommentMutation,
  Create_CommentMutationVariables
>

/**
 * __useCreate_CommentMutation__
 *
 * To run a mutation, you first call `useCreate_CommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_CommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreate_CommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreate_CommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_CommentMutation,
    Create_CommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Create_CommentMutation,
    Create_CommentMutationVariables
  >(Create_CommentDocument, options)
}
export type Create_CommentMutationHookResult = ReturnType<
  typeof useCreate_CommentMutation
>
export type Create_CommentMutationResult = Apollo.MutationResult<
  Create_CommentMutation
>
export type Create_CommentMutationOptions = Apollo.BaseMutationOptions<
  Create_CommentMutation,
  Create_CommentMutationVariables
>
export const CreateCommentDocument = gql`
  mutation createComment($input: CreateComment!) {
    createComment(input: $input) {
      id
      content
      createdAt
      approvalStatus
      author {
        id
        name
        avatar
      }
    }
  }
`
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, options)
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>
export type CreateCommentMutationResult = Apollo.MutationResult<
  CreateCommentMutation
>
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>
export const CreateBlogDocument = gql`
  mutation createBlog($data: ArticleInput!) {
    createArticle(data: $data) {
      data {
        attributes {
          slug
          user {
            data {
              attributes {
                username
              }
            }
          }
        }
      }
    }
  }
`
export type CreateBlogMutationFn = Apollo.MutationFunction<
  CreateBlogMutation,
  CreateBlogMutationVariables
>

/**
 * __useCreateBlogMutation__
 *
 * To run a mutation, you first call `useCreateBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogMutation, { data, loading, error }] = useCreateBlogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBlogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBlogMutation,
    CreateBlogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBlogMutation, CreateBlogMutationVariables>(
    CreateBlogDocument,
    options
  )
}
export type CreateBlogMutationHookResult = ReturnType<
  typeof useCreateBlogMutation
>
export type CreateBlogMutationResult = Apollo.MutationResult<CreateBlogMutation>
export type CreateBlogMutationOptions = Apollo.BaseMutationOptions<
  CreateBlogMutation,
  CreateBlogMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        attributes {
          username
        }
      }
    }
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const Signup_MutationDocument = gql`
  mutation SIGNUP_MUTATION($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`
export type Signup_MutationMutationFn = Apollo.MutationFunction<
  Signup_MutationMutation,
  Signup_MutationMutationVariables
>

/**
 * __useSignup_MutationMutation__
 *
 * To run a mutation, you first call `useSignup_MutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignup_MutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutationMutation, { data, loading, error }] = useSignup_MutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignup_MutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Signup_MutationMutation,
    Signup_MutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Signup_MutationMutation,
    Signup_MutationMutationVariables
  >(Signup_MutationDocument, options)
}
export type Signup_MutationMutationHookResult = ReturnType<
  typeof useSignup_MutationMutation
>
export type Signup_MutationMutationResult = Apollo.MutationResult<
  Signup_MutationMutation
>
export type Signup_MutationMutationOptions = Apollo.BaseMutationOptions<
  Signup_MutationMutation,
  Signup_MutationMutationVariables
>
export const Update_UserDocument = gql`
  mutation UPDATE_USER($country: String) {
    updateUser(country: $country)
  }
`
export type Update_UserMutationFn = Apollo.MutationFunction<
  Update_UserMutation,
  Update_UserMutationVariables
>

/**
 * __useUpdate_UserMutation__
 *
 * To run a mutation, you first call `useUpdate_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdate_UserMutation({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useUpdate_UserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Update_UserMutation,
    Update_UserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Update_UserMutation, Update_UserMutationVariables>(
    Update_UserDocument,
    options
  )
}
export type Update_UserMutationHookResult = ReturnType<
  typeof useUpdate_UserMutation
>
export type Update_UserMutationResult = Apollo.MutationResult<
  Update_UserMutation
>
export type Update_UserMutationOptions = Apollo.BaseMutationOptions<
  Update_UserMutation,
  Update_UserMutationVariables
>
export const Login_MutationDocument = gql`
  mutation LOGIN_MUTATION($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`
export type Login_MutationMutationFn = Apollo.MutationFunction<
  Login_MutationMutation,
  Login_MutationMutationVariables
>

/**
 * __useLogin_MutationMutation__
 *
 * To run a mutation, you first call `useLogin_MutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogin_MutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutationMutation, { data, loading, error }] = useLogin_MutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogin_MutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Login_MutationMutation,
    Login_MutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Login_MutationMutation,
    Login_MutationMutationVariables
  >(Login_MutationDocument, options)
}
export type Login_MutationMutationHookResult = ReturnType<
  typeof useLogin_MutationMutation
>
export type Login_MutationMutationResult = Apollo.MutationResult<
  Login_MutationMutation
>
export type Login_MutationMutationOptions = Apollo.BaseMutationOptions<
  Login_MutationMutation,
  Login_MutationMutationVariables
>
export const All_Cities_With_Broker_And_NavDocument = gql`
  query all_cities_with_broker_and_nav {
    all_brokers: brokers(sort: "createdAt:asc") {
      ...AllBrokers
    }
    all_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    all_cities: cities(pagination: { limit: 3000 }) {
      data {
        attributes {
          name
          state
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${NavCalculatorsFragmentDoc}
  ${AllPageSlugsFragmentDoc}
`

/**
 * __useAll_Cities_With_Broker_And_NavQuery__
 *
 * To run a query within a React component, call `useAll_Cities_With_Broker_And_NavQuery` and pass it any options that fit your needs.
 * When your component renders, `useAll_Cities_With_Broker_And_NavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAll_Cities_With_Broker_And_NavQuery({
 *   variables: {
 *   },
 * });
 */
export function useAll_Cities_With_Broker_And_NavQuery(
  baseOptions?: Apollo.QueryHookOptions<
    All_Cities_With_Broker_And_NavQuery,
    All_Cities_With_Broker_And_NavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    All_Cities_With_Broker_And_NavQuery,
    All_Cities_With_Broker_And_NavQueryVariables
  >(All_Cities_With_Broker_And_NavDocument, options)
}
export function useAll_Cities_With_Broker_And_NavLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    All_Cities_With_Broker_And_NavQuery,
    All_Cities_With_Broker_And_NavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    All_Cities_With_Broker_And_NavQuery,
    All_Cities_With_Broker_And_NavQueryVariables
  >(All_Cities_With_Broker_And_NavDocument, options)
}
export type All_Cities_With_Broker_And_NavQueryHookResult = ReturnType<
  typeof useAll_Cities_With_Broker_And_NavQuery
>
export type All_Cities_With_Broker_And_NavLazyQueryHookResult = ReturnType<
  typeof useAll_Cities_With_Broker_And_NavLazyQuery
>
export type All_Cities_With_Broker_And_NavQueryResult = Apollo.QueryResult<
  All_Cities_With_Broker_And_NavQuery,
  All_Cities_With_Broker_And_NavQueryVariables
>
export const Article_With_BrokersDocument = gql`
  query article_with_brokers($slug: String) {
    all_brokers: brokers(sort: "createdAt:asc") {
      ...AllBrokers
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    article: articles(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          createdAt
          updatedAt
          ...FullArticleInfo
          user {
            data {
              attributes {
                username
                avatar
              }
            }
          }
          plugin_comments(sort: "createdAt:desc") {
            ...ArticleComments
          }
          relatedArticles(limit: 10) {
            title
            tags
            slug
            description
            short_description
          }
          country {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${NavCalculatorsFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${FullArticleInfoFragmentDoc}
  ${ArticleCommentsFragmentDoc}
`

/**
 * __useArticle_With_BrokersQuery__
 *
 * To run a query within a React component, call `useArticle_With_BrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticle_With_BrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticle_With_BrokersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticle_With_BrokersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Article_With_BrokersQuery,
    Article_With_BrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Article_With_BrokersQuery,
    Article_With_BrokersQueryVariables
  >(Article_With_BrokersDocument, options)
}
export function useArticle_With_BrokersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Article_With_BrokersQuery,
    Article_With_BrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Article_With_BrokersQuery,
    Article_With_BrokersQueryVariables
  >(Article_With_BrokersDocument, options)
}
export type Article_With_BrokersQueryHookResult = ReturnType<
  typeof useArticle_With_BrokersQuery
>
export type Article_With_BrokersLazyQueryHookResult = ReturnType<
  typeof useArticle_With_BrokersLazyQuery
>
export type Article_With_BrokersQueryResult = Apollo.QueryResult<
  Article_With_BrokersQuery,
  Article_With_BrokersQueryVariables
>
export const ArticleSearchByKeywordDocument = gql`
  query articleSearchByKeyword($where: JSON, $limit: Int) {
    articleSearchByKeyword(where: $where, limit: $limit) {
      slug
      title
    }
  }
`

/**
 * __useArticleSearchByKeywordQuery__
 *
 * To run a query within a React component, call `useArticleSearchByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleSearchByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleSearchByKeywordQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useArticleSearchByKeywordQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArticleSearchByKeywordQuery,
    ArticleSearchByKeywordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArticleSearchByKeywordQuery,
    ArticleSearchByKeywordQueryVariables
  >(ArticleSearchByKeywordDocument, options)
}
export function useArticleSearchByKeywordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleSearchByKeywordQuery,
    ArticleSearchByKeywordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArticleSearchByKeywordQuery,
    ArticleSearchByKeywordQueryVariables
  >(ArticleSearchByKeywordDocument, options)
}
export type ArticleSearchByKeywordQueryHookResult = ReturnType<
  typeof useArticleSearchByKeywordQuery
>
export type ArticleSearchByKeywordLazyQueryHookResult = ReturnType<
  typeof useArticleSearchByKeywordLazyQuery
>
export type ArticleSearchByKeywordQueryResult = Apollo.QueryResult<
  ArticleSearchByKeywordQuery,
  ArticleSearchByKeywordQueryVariables
>
export const ArticleCommentsDocument = gql`
  query articleComments($relation: String!, $sort: [String] = []) {
    plugin_comments: findAllInHierarchy(relation: $relation, sort: $sort) {
      ...ArticleComments
    }
  }
  ${ArticleCommentsFragmentDoc}
`

/**
 * __useArticleCommentsQuery__
 *
 * To run a query within a React component, call `useArticleCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCommentsQuery({
 *   variables: {
 *      relation: // value for 'relation'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useArticleCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleCommentsQuery,
    ArticleCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArticleCommentsQuery, ArticleCommentsQueryVariables>(
    ArticleCommentsDocument,
    options
  )
}
export function useArticleCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleCommentsQuery,
    ArticleCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArticleCommentsQuery,
    ArticleCommentsQueryVariables
  >(ArticleCommentsDocument, options)
}
export type ArticleCommentsQueryHookResult = ReturnType<
  typeof useArticleCommentsQuery
>
export type ArticleCommentsLazyQueryHookResult = ReturnType<
  typeof useArticleCommentsLazyQuery
>
export type ArticleCommentsQueryResult = Apollo.QueryResult<
  ArticleCommentsQuery,
  ArticleCommentsQueryVariables
>
export const Base_QueryDocument = gql`
  query base_query {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useBase_QueryQuery__
 *
 * To run a query within a React component, call `useBase_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBase_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBase_QueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBase_QueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Base_QueryQuery,
    Base_QueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Base_QueryQuery, Base_QueryQueryVariables>(
    Base_QueryDocument,
    options
  )
}
export function useBase_QueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Base_QueryQuery,
    Base_QueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Base_QueryQuery, Base_QueryQueryVariables>(
    Base_QueryDocument,
    options
  )
}
export type Base_QueryQueryHookResult = ReturnType<typeof useBase_QueryQuery>
export type Base_QueryLazyQueryHookResult = ReturnType<
  typeof useBase_QueryLazyQuery
>
export type Base_QueryQueryResult = Apollo.QueryResult<
  Base_QueryQuery,
  Base_QueryQueryVariables
>
export const BrokersDocument = gql`
  query brokers($slug: String!, $article_type: String!, $page_url: String) {
    all_brokers: brokers(pagination: { limit: 100 }, sort: "updatedAt:desc") {
      ...AllBrokers
      data {
        attributes {
          broker_type
          country {
            name
          }
        }
      }
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    brokers(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          ...BrokerInfo
          articles(
            filters: {
              article_type: { eq: $article_type }
              status: { eq: "published" }
              page_url: { eq: $page_url }
            }
            sort: "updatedAt:desc"
            pagination: { limit: 30 }
          ) {
            data {
              attributes {
                slug
                title
                short_description
                description
              }
            }
          }
          image {
            file {
              data {
                attributes {
                  formats
                  url
                }
              }
            }
            alt
            description
          }
          softwares {
            data {
              attributes {
                details
                name
                slug
              }
            }
          }
          country {
            name
          }
          pros_cons {
            id
            display_text
            value_type
          }
        }
      }
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${BrokerInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useBrokersQuery__
 *
 * To run a query within a React component, call `useBrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      article_type: // value for 'article_type'
 *      page_url: // value for 'page_url'
 *   },
 * });
 */
export function useBrokersQuery(
  baseOptions: Apollo.QueryHookOptions<BrokersQuery, BrokersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BrokersQuery, BrokersQueryVariables>(
    BrokersDocument,
    options
  )
}
export function useBrokersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrokersQuery, BrokersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BrokersQuery, BrokersQueryVariables>(
    BrokersDocument,
    options
  )
}
export type BrokersQueryHookResult = ReturnType<typeof useBrokersQuery>
export type BrokersLazyQueryHookResult = ReturnType<typeof useBrokersLazyQuery>
export type BrokersQueryResult = Apollo.QueryResult<
  BrokersQuery,
  BrokersQueryVariables
>
export const Broker_FaqDocument = gql`
  query broker_faq($slug: String!, $start: Int, $limit: Int) {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    brokers(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          ...BrokerInfo
          articles(
            filters: {
              article_type: { eq: "faq" }
              status: { eq: "published" }
            }
            sort: "updatedAt:desc"
            pagination: { limit: $limit, start: $start }
          ) {
            data {
              attributes {
                title
                description
                short_description
                slug
                categories {
                  data {
                    attributes {
                      name
                      slug
                    }
                  }
                }
                broker {
                  data {
                    attributes {
                      name
                      slug
                    }
                  }
                }
                software {
                  data {
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
          brokerArticlesCount
          softwares {
            data {
              attributes {
                name
                slug
              }
            }
          }
          country {
            name
          }
        }
      }
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${BrokerInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useBroker_FaqQuery__
 *
 * To run a query within a React component, call `useBroker_FaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useBroker_FaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBroker_FaqQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBroker_FaqQuery(
  baseOptions: Apollo.QueryHookOptions<
    Broker_FaqQuery,
    Broker_FaqQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Broker_FaqQuery, Broker_FaqQueryVariables>(
    Broker_FaqDocument,
    options
  )
}
export function useBroker_FaqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Broker_FaqQuery,
    Broker_FaqQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Broker_FaqQuery, Broker_FaqQueryVariables>(
    Broker_FaqDocument,
    options
  )
}
export type Broker_FaqQueryHookResult = ReturnType<typeof useBroker_FaqQuery>
export type Broker_FaqLazyQueryHookResult = ReturnType<
  typeof useBroker_FaqLazyQuery
>
export type Broker_FaqQueryResult = Apollo.QueryResult<
  Broker_FaqQuery,
  Broker_FaqQueryVariables
>
export const Brokers_Linked_PageDocument = gql`
  query brokers_linked_page(
    $slug: String!
    $article_type: String!
    $page_url: String
  ) {
    all_brokers: brokers(pagination: { limit: 50 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    brokers(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          ...BrokerInfo
          articles(
            filters: {
              article_type: { eq: $article_type }
              status: { eq: "published" }
              page_url: { eq: $page_url }
            }
          ) {
            data {
              attributes {
                slug
                title
                short_description
                description
              }
            }
          }
        }
      }
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${BrokerInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useBrokers_Linked_PageQuery__
 *
 * To run a query within a React component, call `useBrokers_Linked_PageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokers_Linked_PageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokers_Linked_PageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      article_type: // value for 'article_type'
 *      page_url: // value for 'page_url'
 *   },
 * });
 */
export function useBrokers_Linked_PageQuery(
  baseOptions: Apollo.QueryHookOptions<
    Brokers_Linked_PageQuery,
    Brokers_Linked_PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Brokers_Linked_PageQuery,
    Brokers_Linked_PageQueryVariables
  >(Brokers_Linked_PageDocument, options)
}
export function useBrokers_Linked_PageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Brokers_Linked_PageQuery,
    Brokers_Linked_PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Brokers_Linked_PageQuery,
    Brokers_Linked_PageQueryVariables
  >(Brokers_Linked_PageDocument, options)
}
export type Brokers_Linked_PageQueryHookResult = ReturnType<
  typeof useBrokers_Linked_PageQuery
>
export type Brokers_Linked_PageLazyQueryHookResult = ReturnType<
  typeof useBrokers_Linked_PageLazyQuery
>
export type Brokers_Linked_PageQueryResult = Apollo.QueryResult<
  Brokers_Linked_PageQuery,
  Brokers_Linked_PageQueryVariables
>
export const Brokers_QueryDocument = gql`
  query brokers_query {
    all_brokers: brokers(pagination: { limit: 100 }, sort: "updatedAt:desc") {
      ...AllBrokers
      data {
        attributes {
          details
        }
      }
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useBrokers_QueryQuery__
 *
 * To run a query within a React component, call `useBrokers_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokers_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokers_QueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrokers_QueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Brokers_QueryQuery,
    Brokers_QueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Brokers_QueryQuery, Brokers_QueryQueryVariables>(
    Brokers_QueryDocument,
    options
  )
}
export function useBrokers_QueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Brokers_QueryQuery,
    Brokers_QueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Brokers_QueryQuery, Brokers_QueryQueryVariables>(
    Brokers_QueryDocument,
    options
  )
}
export type Brokers_QueryQueryHookResult = ReturnType<
  typeof useBrokers_QueryQuery
>
export type Brokers_QueryLazyQueryHookResult = ReturnType<
  typeof useBrokers_QueryLazyQuery
>
export type Brokers_QueryQueryResult = Apollo.QueryResult<
  Brokers_QueryQuery,
  Brokers_QueryQueryVariables
>
export const Calculators_With_BrokersDocument = gql`
  query calculators_with_brokers {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    all_calculators: calculators(sort: "createdAt:asc") {
      ...FullCalInfo
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${FullCalInfoFragmentDoc}
`

/**
 * __useCalculators_With_BrokersQuery__
 *
 * To run a query within a React component, call `useCalculators_With_BrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculators_With_BrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculators_With_BrokersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalculators_With_BrokersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Calculators_With_BrokersQuery,
    Calculators_With_BrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Calculators_With_BrokersQuery,
    Calculators_With_BrokersQueryVariables
  >(Calculators_With_BrokersDocument, options)
}
export function useCalculators_With_BrokersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Calculators_With_BrokersQuery,
    Calculators_With_BrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Calculators_With_BrokersQuery,
    Calculators_With_BrokersQueryVariables
  >(Calculators_With_BrokersDocument, options)
}
export type Calculators_With_BrokersQueryHookResult = ReturnType<
  typeof useCalculators_With_BrokersQuery
>
export type Calculators_With_BrokersLazyQueryHookResult = ReturnType<
  typeof useCalculators_With_BrokersLazyQuery
>
export type Calculators_With_BrokersQueryResult = Apollo.QueryResult<
  Calculators_With_BrokersQuery,
  Calculators_With_BrokersQueryVariables
>
export const Calculator_With_NavDocument = gql`
  query calculator_with_nav($slug: String!) {
    all_brokers: brokers(pagination: { limit: 30 }) {
      ...AllBrokers
      data {
        attributes {
          country {
            name
          }
        }
      }
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    slugCalculator: calculators(filters: { slug: { eq: $slug } }) {
      ...FullCalInfo
      data {
        attributes {
          image {
            alt
            description
          }
          articles: articles(
            filters: {
              article_type: { eq: "faq" }
              status: { eq: "published" }
            }
          ) {
            data {
              attributes {
                ...FullArticleInfo
              }
            }
          }
        }
      }
    }
    nav_calculators: calculators(
      sort: ["createdAt:desc"]
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${FullCalInfoFragmentDoc}
  ${FullArticleInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useCalculator_With_NavQuery__
 *
 * To run a query within a React component, call `useCalculator_With_NavQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculator_With_NavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculator_With_NavQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCalculator_With_NavQuery(
  baseOptions: Apollo.QueryHookOptions<
    Calculator_With_NavQuery,
    Calculator_With_NavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Calculator_With_NavQuery,
    Calculator_With_NavQueryVariables
  >(Calculator_With_NavDocument, options)
}
export function useCalculator_With_NavLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Calculator_With_NavQuery,
    Calculator_With_NavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Calculator_With_NavQuery,
    Calculator_With_NavQueryVariables
  >(Calculator_With_NavDocument, options)
}
export type Calculator_With_NavQueryHookResult = ReturnType<
  typeof useCalculator_With_NavQuery
>
export type Calculator_With_NavLazyQueryHookResult = ReturnType<
  typeof useCalculator_With_NavLazyQuery
>
export type Calculator_With_NavQueryResult = Apollo.QueryResult<
  Calculator_With_NavQuery,
  Calculator_With_NavQueryVariables
>
export const Create_Faq_PageDocument = gql`
  query create_faq_page {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    all_categories: categories(sort: "createdAt:asc") {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${NavCalculatorsFragmentDoc}
  ${AllPageSlugsFragmentDoc}
`

/**
 * __useCreate_Faq_PageQuery__
 *
 * To run a query within a React component, call `useCreate_Faq_PageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreate_Faq_PageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreate_Faq_PageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreate_Faq_PageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Create_Faq_PageQuery,
    Create_Faq_PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Create_Faq_PageQuery, Create_Faq_PageQueryVariables>(
    Create_Faq_PageDocument,
    options
  )
}
export function useCreate_Faq_PageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Create_Faq_PageQuery,
    Create_Faq_PageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Create_Faq_PageQuery,
    Create_Faq_PageQueryVariables
  >(Create_Faq_PageDocument, options)
}
export type Create_Faq_PageQueryHookResult = ReturnType<
  typeof useCreate_Faq_PageQuery
>
export type Create_Faq_PageLazyQueryHookResult = ReturnType<
  typeof useCreate_Faq_PageLazyQuery
>
export type Create_Faq_PageQueryResult = Apollo.QueryResult<
  Create_Faq_PageQuery,
  Create_Faq_PageQueryVariables
>
export const Financial_TermDocument = gql`
  query financial_term($slug: String!) {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    selected_term: financialTerms(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          name
          slug
          description
          articles: articles(
            filters: {
              article_type: { eq: "faq" }
              status: { eq: "published" }
            }
            sort: "updatedAt:desc"
            pagination: { limit: 10 }
          ) {
            ...ShortArticleInfo
          }
          seo_meta {
            title
            description
          }
          image {
            file {
              data {
                attributes {
                  formats
                  url
                }
              }
            }
            alt
            description
          }
        }
      }
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${ShortArticleInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useFinancial_TermQuery__
 *
 * To run a query within a React component, call `useFinancial_TermQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancial_TermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancial_TermQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFinancial_TermQuery(
  baseOptions: Apollo.QueryHookOptions<
    Financial_TermQuery,
    Financial_TermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Financial_TermQuery, Financial_TermQueryVariables>(
    Financial_TermDocument,
    options
  )
}
export function useFinancial_TermLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Financial_TermQuery,
    Financial_TermQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Financial_TermQuery, Financial_TermQueryVariables>(
    Financial_TermDocument,
    options
  )
}
export type Financial_TermQueryHookResult = ReturnType<
  typeof useFinancial_TermQuery
>
export type Financial_TermLazyQueryHookResult = ReturnType<
  typeof useFinancial_TermLazyQuery
>
export type Financial_TermQueryResult = Apollo.QueryResult<
  Financial_TermQuery,
  Financial_TermQueryVariables
>
export const Get_Financial_TermsDocument = gql`
  query get_financial_terms {
    all_brokers: brokers(sort: "createdAt:asc", pagination: { limit: 30 }) {
      ...AllBrokers
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    all_terms: financialTerms(
      sort: "createdAt:asc"
      pagination: { limit: 30 }
    ) {
      ...FinancialTermBasic
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${FinancialTermBasicFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useGet_Financial_TermsQuery__
 *
 * To run a query within a React component, call `useGet_Financial_TermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Financial_TermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Financial_TermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Financial_TermsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Financial_TermsQuery,
    Get_Financial_TermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Get_Financial_TermsQuery,
    Get_Financial_TermsQueryVariables
  >(Get_Financial_TermsDocument, options)
}
export function useGet_Financial_TermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Financial_TermsQuery,
    Get_Financial_TermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Get_Financial_TermsQuery,
    Get_Financial_TermsQueryVariables
  >(Get_Financial_TermsDocument, options)
}
export type Get_Financial_TermsQueryHookResult = ReturnType<
  typeof useGet_Financial_TermsQuery
>
export type Get_Financial_TermsLazyQueryHookResult = ReturnType<
  typeof useGet_Financial_TermsLazyQuery
>
export type Get_Financial_TermsQueryResult = Apollo.QueryResult<
  Get_Financial_TermsQuery,
  Get_Financial_TermsQueryVariables
>
export const FindPageBySlugDocument = gql`
  query findPageBySlug($slug: String!) {
    all_pages: pages {
      ...AllPageSlugs
    }
    all_brokers: brokers(sort: "createdAt:asc") {
      ...AllBrokers
      data {
        attributes {
          country {
            name
          }
        }
      }
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
    pages(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          slug
          title
          description
        }
      }
    }
  }
  ${AllPageSlugsFragmentDoc}
  ${AllBrokersFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useFindPageBySlugQuery__
 *
 * To run a query within a React component, call `useFindPageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFindPageBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindPageBySlugQuery,
    FindPageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindPageBySlugQuery, FindPageBySlugQueryVariables>(
    FindPageBySlugDocument,
    options
  )
}
export function useFindPageBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPageBySlugQuery,
    FindPageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindPageBySlugQuery, FindPageBySlugQueryVariables>(
    FindPageBySlugDocument,
    options
  )
}
export type FindPageBySlugQueryHookResult = ReturnType<
  typeof useFindPageBySlugQuery
>
export type FindPageBySlugLazyQueryHookResult = ReturnType<
  typeof useFindPageBySlugLazyQuery
>
export type FindPageBySlugQueryResult = Apollo.QueryResult<
  FindPageBySlugQuery,
  FindPageBySlugQueryVariables
>
export const FindAllPagesDocument = gql`
  query findAllPages {
    pages {
      data {
        attributes {
          slug
        }
      }
    }
  }
`

/**
 * __useFindAllPagesQuery__
 *
 * To run a query within a React component, call `useFindAllPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllPagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindAllPagesQuery,
    FindAllPagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindAllPagesQuery, FindAllPagesQueryVariables>(
    FindAllPagesDocument,
    options
  )
}
export function useFindAllPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllPagesQuery,
    FindAllPagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindAllPagesQuery, FindAllPagesQueryVariables>(
    FindAllPagesDocument,
    options
  )
}
export type FindAllPagesQueryHookResult = ReturnType<
  typeof useFindAllPagesQuery
>
export type FindAllPagesLazyQueryHookResult = ReturnType<
  typeof useFindAllPagesLazyQuery
>
export type FindAllPagesQueryResult = Apollo.QueryResult<
  FindAllPagesQuery,
  FindAllPagesQueryVariables
>
export const Get_All_BrokersDocument = gql`
  query get_all_brokers($slug: [String], $page_url: String, $status: String) {
    all_brokers: brokers(pagination: { limit: 100 }) {
      ...AllBrokers
      data {
        attributes {
          broker_type
          country {
            name
          }
        }
      }
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    brokers(filters: { slug: { in: $slug } }) {
      data {
        id
        attributes {
          ...BrokerInfo
          softwares {
            data {
              attributes {
                name
                slug
              }
            }
          }
          country {
            name
          }
          pros_cons {
            id
            display_text
            value_type
          }
        }
      }
    }
    articles(
      filters: { page_url: { eq: $page_url }, status: { eq: $status } }
    ) {
      data {
        attributes {
          slug
          title
          short_description
          description
        }
      }
    }
    nav_calculators: calculators(
      sort: "createdAt:desc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${BrokerInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useGet_All_BrokersQuery__
 *
 * To run a query within a React component, call `useGet_All_BrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_All_BrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_All_BrokersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      page_url: // value for 'page_url'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGet_All_BrokersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_All_BrokersQuery,
    Get_All_BrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Get_All_BrokersQuery, Get_All_BrokersQueryVariables>(
    Get_All_BrokersDocument,
    options
  )
}
export function useGet_All_BrokersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_All_BrokersQuery,
    Get_All_BrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Get_All_BrokersQuery,
    Get_All_BrokersQueryVariables
  >(Get_All_BrokersDocument, options)
}
export type Get_All_BrokersQueryHookResult = ReturnType<
  typeof useGet_All_BrokersQuery
>
export type Get_All_BrokersLazyQueryHookResult = ReturnType<
  typeof useGet_All_BrokersLazyQuery
>
export type Get_All_BrokersQueryResult = Apollo.QueryResult<
  Get_All_BrokersQuery,
  Get_All_BrokersQueryVariables
>
export const Nav_With_SoftwareDocument = gql`
  query nav_with_software($name: String, $state: String, $start: Int) {
    all_brokers: brokers(sort: "createdAt:asc") {
      ...AllBrokers
      data {
        attributes {
          country {
            name
          }
        }
      }
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
    selected_city: cities(
      filters: { name: { eq: $name }, state: { eq: $state } }
    ) {
      data {
        attributes {
          name
          state
          listings {
            data {
              attributes {
                name
                address
                broker {
                  data {
                    attributes {
                      name
                      slug
                    }
                  }
                }
                associated_broker
              }
            }
          }
        }
      }
    }
    broker_articles: articles(
      filters: { article_type: { eq: "faq" }, status: { eq: "published" } }
      pagination: { limit: 10, start: $start }
      sort: ["updatedAt:desc"]
    ) {
      data {
        attributes {
          slug
          title
          short_description
          description
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useNav_With_SoftwareQuery__
 *
 * To run a query within a React component, call `useNav_With_SoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useNav_With_SoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNav_With_SoftwareQuery({
 *   variables: {
 *      name: // value for 'name'
 *      state: // value for 'state'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useNav_With_SoftwareQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Nav_With_SoftwareQuery,
    Nav_With_SoftwareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Nav_With_SoftwareQuery,
    Nav_With_SoftwareQueryVariables
  >(Nav_With_SoftwareDocument, options)
}
export function useNav_With_SoftwareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Nav_With_SoftwareQuery,
    Nav_With_SoftwareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Nav_With_SoftwareQuery,
    Nav_With_SoftwareQueryVariables
  >(Nav_With_SoftwareDocument, options)
}
export type Nav_With_SoftwareQueryHookResult = ReturnType<
  typeof useNav_With_SoftwareQuery
>
export type Nav_With_SoftwareLazyQueryHookResult = ReturnType<
  typeof useNav_With_SoftwareLazyQuery
>
export type Nav_With_SoftwareQueryResult = Apollo.QueryResult<
  Nav_With_SoftwareQuery,
  Nav_With_SoftwareQueryVariables
>
export const GetUserDocument = gql`
  query getUser($id: ID) {
    usersPermissionsUser(id: $id) {
      data {
        attributes {
          username
          website
          twitter
          short_description
          full_name
        }
      }
    }
  }
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>
export const HomeContentDocument = gql`
  query homeContent($country: String) {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_calculators: calculators(sort: "createdAt:asc") {
      ...FullCalInfo
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    investment_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "investment", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    finance_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "finance", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    stock_market_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "stock-market"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    ipo_basics_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "ipo-basics", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    financial_terms_faqs: popularQuestions(
      where: { resourceType: "Financial Term", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    option_trading_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "option-trading"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    stock_broker_faqs: popularQuestions(
      where: { resourceType: "Broker", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    mutual_funds_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "mutual-funds"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    stock_trading_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "stock-trading"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    trading_software_faqs: popularQuestions(
      where: { resourceType: "Software", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    latest_comments: pluginComments(limit: 4, where: { country: $country }) {
      content
      id
      articleSlug
      createdAt
      author {
        id
        name
        email
        avatar
      }
    }
    latest_ipos: ipos(sort: ["updatedAt:desc"], pagination: { limit: 10 }) {
      data {
        attributes {
          name
          slug
          deal_id
          description
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${FullCalInfoFragmentDoc}
  ${AllPageSlugsFragmentDoc}
`

/**
 * __useHomeContentQuery__
 *
 * To run a query within a React component, call `useHomeContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeContentQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useHomeContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeContentQuery,
    HomeContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HomeContentQuery, HomeContentQueryVariables>(
    HomeContentDocument,
    options
  )
}
export function useHomeContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeContentQuery,
    HomeContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HomeContentQuery, HomeContentQueryVariables>(
    HomeContentDocument,
    options
  )
}
export type HomeContentQueryHookResult = ReturnType<typeof useHomeContentQuery>
export type HomeContentLazyQueryHookResult = ReturnType<
  typeof useHomeContentLazyQuery
>
export type HomeContentQueryResult = Apollo.QueryResult<
  HomeContentQuery,
  HomeContentQueryVariables
>
export const HomeContentCanadaDocument = gql`
  query homeContentCanada($country: String) {
    all_brokers: brokers(
      filters: { country: { name: { eq: "Canada" } } }
      pagination: { limit: 10 }
      sort: "updatedAt:desc"
    ) {
      ...AllBrokers
    }
    all_calculators: calculators(sort: "createdAt:asc") {
      ...FullCalInfo
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    investment_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "investment", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    finance_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "finance", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    stock_market_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "stock-market"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    trading_software_faqs: popularQuestions(
      where: { resourceType: "Software", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    banking_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "banking", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    tfsa_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "tfsa-canada"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    cpp_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "cpp-canada", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    rrsp_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "rrsp-canada"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    resp_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "resp-canada"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    credit_card_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "credit-cards"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    new_immigrant_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "new-immigrants-canada"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    mortgage_faqs: popularQuestions(
      where: { resourceType: "Category", slug: "mortgage", country: $country }
      limit: 5
    ) {
      title
      slug
    }
    real_estate_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "real-estate-investment"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    crypto_currency_faqs: popularQuestions(
      where: {
        resourceType: "Category"
        slug: "cryptocurrency"
        country: $country
      }
      limit: 5
    ) {
      title
      slug
    }
    latest_comments: pluginComments(limit: 4, where: { country: $country }) {
      content
      id
      articleSlug
      createdAt
      author {
        id
        name
        email
        avatar
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${FullCalInfoFragmentDoc}
  ${AllPageSlugsFragmentDoc}
`

/**
 * __useHomeContentCanadaQuery__
 *
 * To run a query within a React component, call `useHomeContentCanadaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeContentCanadaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeContentCanadaQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useHomeContentCanadaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeContentCanadaQuery,
    HomeContentCanadaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HomeContentCanadaQuery,
    HomeContentCanadaQueryVariables
  >(HomeContentCanadaDocument, options)
}
export function useHomeContentCanadaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeContentCanadaQuery,
    HomeContentCanadaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HomeContentCanadaQuery,
    HomeContentCanadaQueryVariables
  >(HomeContentCanadaDocument, options)
}
export type HomeContentCanadaQueryHookResult = ReturnType<
  typeof useHomeContentCanadaQuery
>
export type HomeContentCanadaLazyQueryHookResult = ReturnType<
  typeof useHomeContentCanadaLazyQuery
>
export type HomeContentCanadaQueryResult = Apollo.QueryResult<
  HomeContentCanadaQuery,
  HomeContentCanadaQueryVariables
>
export const IpoContentDocument = gql`
  query ipoContent($slug: String) {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_calculators: calculators(sort: "createdAt:asc") {
      ...FullCalInfo
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    ipo: ipos(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          slug
          name
          symbol
          offer_amount
          ipo_start_date
          ipo_end_date
          ipo_price
          ceo
          share_price
          shares_offered
          address
          website
          phone
          state_inc
          lockup_days
          lockup_expiration
          quiet_period_expiration
          cik
          offering_expense
          shares_outstanding
          description
          deal_id
          fiscal_year_end
          shares_over_alloted
          status
          employees
          exchange
          filed_date
          priced_date
          withdrawn_date
          plugin_comments(sort: "createdAt:desc") {
            ...ArticleComments
          }
          revenue
          net_income
          total_assets
          total_liabilities
          stock_holders_equity
          form_type
          date_received
          filing_link
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${FullCalInfoFragmentDoc}
  ${AllPageSlugsFragmentDoc}
  ${ArticleCommentsFragmentDoc}
`

/**
 * __useIpoContentQuery__
 *
 * To run a query within a React component, call `useIpoContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIpoContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIpoContentQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useIpoContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IpoContentQuery,
    IpoContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IpoContentQuery, IpoContentQueryVariables>(
    IpoContentDocument,
    options
  )
}
export function useIpoContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IpoContentQuery,
    IpoContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IpoContentQuery, IpoContentQueryVariables>(
    IpoContentDocument,
    options
  )
}
export type IpoContentQueryHookResult = ReturnType<typeof useIpoContentQuery>
export type IpoContentLazyQueryHookResult = ReturnType<
  typeof useIpoContentLazyQuery
>
export type IpoContentQueryResult = Apollo.QueryResult<
  IpoContentQuery,
  IpoContentQueryVariables
>
export const IpoCommentsDocument = gql`
  query ipoComments($relation: String!, $sort: [String] = []) {
    plugin_comments: findAllInHierarchy(relation: $relation, sort: $sort) {
      ...ArticleComments
    }
  }
  ${ArticleCommentsFragmentDoc}
`

/**
 * __useIpoCommentsQuery__
 *
 * To run a query within a React component, call `useIpoCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIpoCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIpoCommentsQuery({
 *   variables: {
 *      relation: // value for 'relation'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useIpoCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IpoCommentsQuery,
    IpoCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IpoCommentsQuery, IpoCommentsQueryVariables>(
    IpoCommentsDocument,
    options
  )
}
export function useIpoCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IpoCommentsQuery,
    IpoCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IpoCommentsQuery, IpoCommentsQueryVariables>(
    IpoCommentsDocument,
    options
  )
}
export type IpoCommentsQueryHookResult = ReturnType<typeof useIpoCommentsQuery>
export type IpoCommentsLazyQueryHookResult = ReturnType<
  typeof useIpoCommentsLazyQuery
>
export type IpoCommentsQueryResult = Apollo.QueryResult<
  IpoCommentsQuery,
  IpoCommentsQueryVariables
>
export const IposDocument = gql`
  query ipos {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_calculators: calculators(sort: "createdAt:asc") {
      ...FullCalInfo
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    filed_ipos: ipos(filters: { status: { eq: "filed" } }) {
      meta {
        pagination {
          total
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${FullCalInfoFragmentDoc}
  ${AllPageSlugsFragmentDoc}
`

/**
 * __useIposQuery__
 *
 * To run a query within a React component, call `useIposQuery` and pass it any options that fit your needs.
 * When your component renders, `useIposQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIposQuery({
 *   variables: {
 *   },
 * });
 */
export function useIposQuery(
  baseOptions?: Apollo.QueryHookOptions<IposQuery, IposQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IposQuery, IposQueryVariables>(IposDocument, options)
}
export function useIposLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IposQuery, IposQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IposQuery, IposQueryVariables>(
    IposDocument,
    options
  )
}
export type IposQueryHookResult = ReturnType<typeof useIposQuery>
export type IposLazyQueryHookResult = ReturnType<typeof useIposLazyQuery>
export type IposQueryResult = Apollo.QueryResult<IposQuery, IposQueryVariables>
export const GetFiledIposDocument = gql`
  query GetFiledIpos($currentPage: Int, $pageSizeCount: Int) {
    all_brokers: brokers(pagination: { limit: 10 }, sort: "updatedAt:desc") {
      ...AllBrokers
    }
    all_calculators: calculators(sort: "createdAt:asc") {
      ...FullCalInfo
    }
    all_pages: pages {
      ...AllPageSlugs
    }
    filed_ipos: ipos(
      filters: { status: { eq: "filed" } }
      pagination: { page: $currentPage, pageSize: $pageSizeCount }
      sort: "createdAt:desc"
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          deal_id
          symbol
          name
          slug
          offer_amount
          filed_date
        }
      }
    }
  }
  ${AllBrokersFragmentDoc}
  ${FullCalInfoFragmentDoc}
  ${AllPageSlugsFragmentDoc}
`

/**
 * __useGetFiledIposQuery__
 *
 * To run a query within a React component, call `useGetFiledIposQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiledIposQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiledIposQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      pageSizeCount: // value for 'pageSizeCount'
 *   },
 * });
 */
export function useGetFiledIposQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFiledIposQuery,
    GetFiledIposQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFiledIposQuery, GetFiledIposQueryVariables>(
    GetFiledIposDocument,
    options
  )
}
export function useGetFiledIposLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFiledIposQuery,
    GetFiledIposQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFiledIposQuery, GetFiledIposQueryVariables>(
    GetFiledIposDocument,
    options
  )
}
export type GetFiledIposQueryHookResult = ReturnType<
  typeof useGetFiledIposQuery
>
export type GetFiledIposLazyQueryHookResult = ReturnType<
  typeof useGetFiledIposLazyQuery
>
export type GetFiledIposQueryResult = Apollo.QueryResult<
  GetFiledIposQuery,
  GetFiledIposQueryVariables
>
export const Software_With_NavDocument = gql`
  query software_with_nav($slug: String!) {
    all_pages: pages {
      ...AllPageSlugs
    }
    all_brokers: brokers(pagination: { limit: 30 }) {
      ...AllBrokers
      data {
        attributes {
          country {
            name
          }
        }
      }
    }
    selected_software: softwares(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          ...FullSoftwareInfo
          seo_meta {
            title
            description
          }
          articles(
            filters: {
              article_type: { eq: "faq" }
              status: { eq: "published" }
            }
            sort: "updatedAt:desc"
            pagination: { limit: 10 }
          ) {
            ...ShortArticleInfo
          }
          softwareRelatedArticles(limit: 10) {
            title
            slug
            short_description
          }
          broker {
            data {
              attributes {
                name
                slug
                currency_trading
                commodity_trading
              }
            }
          }
          image {
            alt
            description
          }
          logo {
            data {
              attributes {
                url
              }
            }
          }
          relatedSoftwares(slug: $slug) {
            name
            slug
          }
        }
      }
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllPageSlugsFragmentDoc}
  ${AllBrokersFragmentDoc}
  ${FullSoftwareInfoFragmentDoc}
  ${ShortArticleInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useSoftware_With_NavQuery__
 *
 * To run a query within a React component, call `useSoftware_With_NavQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftware_With_NavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftware_With_NavQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSoftware_With_NavQuery(
  baseOptions: Apollo.QueryHookOptions<
    Software_With_NavQuery,
    Software_With_NavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Software_With_NavQuery,
    Software_With_NavQueryVariables
  >(Software_With_NavDocument, options)
}
export function useSoftware_With_NavLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Software_With_NavQuery,
    Software_With_NavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Software_With_NavQuery,
    Software_With_NavQueryVariables
  >(Software_With_NavDocument, options)
}
export type Software_With_NavQueryHookResult = ReturnType<
  typeof useSoftware_With_NavQuery
>
export type Software_With_NavLazyQueryHookResult = ReturnType<
  typeof useSoftware_With_NavLazyQuery
>
export type Software_With_NavQueryResult = Apollo.QueryResult<
  Software_With_NavQuery,
  Software_With_NavQueryVariables
>
export const Get_Software_ListDocument = gql`
  query get_software_list {
    all_pages: pages {
      ...AllPageSlugs
    }
    all_brokers: brokers(sort: "createdAt:asc", pagination: { limit: 30 }) {
      ...AllBrokers
    }
    all_softwares: softwares(pagination: { limit: 50 }) {
      ...ShortSoftwareInfo
    }
    nav_calculators: calculators(
      sort: "createdAt:asc"
      pagination: { limit: 5 }
    ) {
      ...NavCalculators
    }
  }
  ${AllPageSlugsFragmentDoc}
  ${AllBrokersFragmentDoc}
  ${ShortSoftwareInfoFragmentDoc}
  ${NavCalculatorsFragmentDoc}
`

/**
 * __useGet_Software_ListQuery__
 *
 * To run a query within a React component, call `useGet_Software_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Software_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Software_ListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Software_ListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Software_ListQuery,
    Get_Software_ListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Get_Software_ListQuery,
    Get_Software_ListQueryVariables
  >(Get_Software_ListDocument, options)
}
export function useGet_Software_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Software_ListQuery,
    Get_Software_ListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Get_Software_ListQuery,
    Get_Software_ListQueryVariables
  >(Get_Software_ListDocument, options)
}
export type Get_Software_ListQueryHookResult = ReturnType<
  typeof useGet_Software_ListQuery
>
export type Get_Software_ListLazyQueryHookResult = ReturnType<
  typeof useGet_Software_ListLazyQuery
>
export type Get_Software_ListQueryResult = Apollo.QueryResult<
  Get_Software_ListQuery,
  Get_Software_ListQueryVariables
>
